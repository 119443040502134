import {Pipe, PipeTransform} from '@angular/core';

export class TableColumn {
	displayName: string;
	name: string;
	pipe: any;
	width: number;

	constructor(displayName: string, name: string, width: number, pipe?: any) {
		this.displayName = displayName;
		this.name = name;
		this.width = width;
		this.pipe = pipe;
	}
}
