import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';


@Pipe({
	name: 'formatdatetime'
})
export class FormatDateTimePipe implements PipeTransform {
	constructor() {}

	transform(value: any): any {
		if (value === 0) {
			return '';
		}
		return moment(value).format('DD/MM/YYYY @ HH:mm');
	}
}
