import {Component, EventEmitter, OnInit} from '@angular/core';
import {FormSubmission} from '../../../../model/form-submission';
import {Studio} from '../../../../model/domain/studio';
import {User} from '../../../../model/domain/user';
import {StudioService} from '../../../../core/service/studio.service';
import {UserService} from '../../../../core/service/user.service';
import {UserViewService} from '../user.view.service';
import {combineLatest} from 'rxjs';

@Component({
	selector: 'app-user-edit',
	templateUrl: './user-edit.component.html',
	styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
	result: FormSubmission;
	formState = new EventEmitter<FormSubmission>();
	user: User;
	studios: Studio[];
	studioValue: boolean[];

	constructor(
		private userService: UserService,
		private userViewService: UserViewService,
		private studioService: StudioService
	) {}

	ngOnInit(): void {
		let userObs = this.userViewService.get();
		let studioObs = this.studioService.getAll();

		combineLatest(userObs, studioObs, (user, studios) => {
			this.user = JSON.parse(JSON.stringify(user));
        	this.user.access = this.capitalizeFirstLetter(this.user.access);
			this.user.password = '';

        	this.studios = studios;
			this.studioValue = new Array<boolean>(this.studios.length);

			for (let i = 0; i < studios.length; i++) {
				this.studioValue[i] = false;

				for (const studio of this.user.studios) {
					if (studio.id === studios[i].id) {
						this.studioValue[i] = true;
					}
				}
			}
		}).subscribe();
	}


	save(): void {
		const selected = new Array();

		for (let i = 0; i < this.studios.length; i++) {
			if (this.studioValue[i] === true) {
				selected.push(this.studios[i]);
			}
		}
		this.user.studios = selected;

		this.userService.updateUser(this.user).subscribe(
			form => {
				this.result = form;
				this.formState.next(form);

				if (form.success) {
					this.userViewService.update(this.result.object);
					this.user.access = this.capitalizeFirstLetter(this.user.access);
				}
			}
		);
	}

	capitalizeFirstLetter(text: string): string {
		return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
	}
}
