<div class="modal-header">
	<h4 class="modal-title"><span class="fa fa-plus-circle mr-2 text-center"></span> Add payment</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" tabindex="-1">
		&times;
	</button>
</div>

<div class="modal-body">
	<div class="row">
		<div class="col-6">
			<div class="input-group mb-2 input-group-lg">
				<div class="input-group-prepend">
					<div class="input-group-text">&euro;</div>
				</div>
				<input type="text" class="form-control" id="price" placeholder="Amount" name="amount" [(ngModel)]="amount">
			</div>
		</div>

		<div class="col-6" style="font-size: 120%">
			<div class="form-check">
				<input class="form-check-input" type="radio" name="method" id="cash" value="cash" [(ngModel)]="method">
				<label class="form-check-label" for="cash">
					<i class="fa fa-money"></i> Cash
				</label>
			</div>
			<div class="form-check">
				<input class="form-check-input" type="radio" name="method" id="card" value="card" [(ngModel)]="method">
				<label class="form-check-label" for="card">
					<i class="fa fa-credit-card"></i> PIN / Credit Card
				</label>
			</div>
		</div>
	</div>


	<div class="row mt-3">
		<div class="col-4">
			<button class="btn btn-danger " (click)="activeModal.close()">Cancel</button>
		</div>
		<div class="offset-4 col-4 text-right">
			<button class="btn btn-success" type="submit" [disabled]="!canSave()" (click)="save()">Add payment</button>
		</div>
	</div>
</div>
