import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Artist} from '../../../../model/domain/artist';
import {AccountTransactionService} from '../../../../core/service/account-transaction.service';
import {AccountTransaction} from '../../../../model/domain/account-transaction';

@Component({
	selector: 'app-artist-summary',
	templateUrl: './artist-summary.component.html',
	styleUrls: ['./artist-summary.component.scss']
})
export class ArtistSummaryComponent implements OnInit {
	artist: Artist;
	transactions: AccountTransaction[];

	constructor(
		private route: ActivatedRoute,
		private accountTransactionService: AccountTransactionService
	) {}

	ngOnInit(): void {
		this.route.parent.data.subscribe((data) => {
			this.artist = data.artist;
			this.accountTransactionService.getRecentTransactions(this.artist).subscribe(results => this.transactions = results);
		});
	}
}
