import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TattooService} from '../../../core/service/tattoo.service';
import {Tattoo} from '../../../model/domain/tattoo';


@Injectable()
export class TattooResolver implements Resolve<Observable<Tattoo>> {
	constructor(
		private tattooService: TattooService
	) {}

	resolve(route: ActivatedRouteSnapshot): Observable<Tattoo> {
		const id: number = +route.paramMap.get('id');
		return this.tattooService.getById(id);
	}
}
