import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Studio} from '../../../../model/domain/studio';
import {StudioService} from '../../../../core/service/studio.service';

@Component({
	selector: 'app-studio-filter',
	templateUrl: './table-studio-filter.component.html',
	styleUrls: ['./table-studio-filter.component.scss']
})
export class TableStudioFilterComponent implements OnInit {
	studios: Studio[];

	@Input() form: string;
	@Input() allowAll: boolean;
	@Input() disabled = false;
	@Output() formChange = new EventEmitter<any>();
	@Output() filterchange = new EventEmitter<any>();

	constructor(
		private studioService: StudioService
	) {
		this.studioService.getAll().subscribe(val => this.studios = val);
	}

	ngOnInit(): void {
		if (this.allowAll === undefined) {
			this.allowAll = true;
		}
	}

	selectValue(): void {
		this.formChange.emit(this.form);
		this.filterchange.emit();
	}
}
