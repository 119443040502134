import {Component, OnInit} from '@angular/core';
import {Product} from '../../../model/domain/product';
import {ProductGroup} from '../../../model/domain/product-group';
import {ModalService} from '../../../core/service/modalservice';
import {ProductGroupService} from '../../../core/service/productgroup.service';
import {ProductEditComponent} from './product-edit/product-edit.component';

@Component({
	selector: 'app-product-list',
	templateUrl: './product-list.component.html',
	styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {
	products: ProductGroup[];

	constructor(
		private productGroupService: ProductGroupService,
		private modalService: ModalService
	) {}

	ngOnInit(): void {
		this.getProducts();
	}

	getProducts(): void {
		this.productGroupService.getAll().subscribe(model => {
			this.products = model;
		});
	}

	onEdit(product: Product): void {
		const modelRef = this.modalService.open(ProductEditComponent);
		(modelRef.componentInstance as ProductEditComponent).setProduct(product);
		modelRef.result.then(val => this.getProducts());
	}
}
