import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Order} from '../../../../model/domain/order';
import {OrderItem} from '../../../../model/domain/order-item';
import {Transaction} from '../../../../model/domain/transaction';
import {OrderService} from '../../../../core/service/order.service';
import {TransactionRefund} from './transaction-refund';
import {RefundItem} from './refund-item';

@Component({
	selector: 'app-order-cancel',
	templateUrl: './order-cancel.component.html',
	styleUrls: ['./order-cancel.component.scss']
})
export class OrderCancelComponent implements OnInit {
	loading = false;

	order: Order;
	items: OrderItem[];
	refundItems: RefundItem[] = new Array();
	transactions: Transaction[];
	payments: TransactionRefund[] = new Array();

	constructor(
		private orderService: OrderService,
		public activeModal: NgbActiveModal
	) {}

	ngOnInit(): void {
	}

	setOrder(order: Order, items: OrderItem[], transactions: Transaction[]): void {
		this.order = order;
		this.items = items;
		this.transactions = transactions;

		this.items.forEach(item => {
			if (item.refunded) {
				return;
			}

			const refundItem = {
				item,
				refund: false,
				quantity: item.quantity,
				value: item.price * item.quantity
			} as RefundItem;
			this.refundItems.push(refundItem);
		});

		this.transactions.forEach(transaction => {
			if (transaction.method === 'REFUND') {
				return;
			}
			const payment = {
				transaction,
				refund: 0
			} as TransactionRefund;
			this.payments.push(payment);
		});
	}

	refundValue(): number {
		let total = 0;
		this.refundItems.forEach(refund => {
			if (!refund.refund) {
				return;
			} else {
				total += refund.value;
			}
		});

		return total;
	}

	paymentRefundTotal(): number {
		let total = 0;
		this.payments.forEach(payments => {
			if (!payments.refund) {
				return;
			} else {
				total += payments.refund;
			}
		});

		return total;
	}

	canSave(): boolean {
		if (this.loading) {
			return false;
		}

		if (this.refundValue() <= 0) {
			return false;
		}

		if (this.payments.length <= 1) {
			return this.isValidRefundValues();
		}

		if (this.paymentRefundTotal() === this.refundValue()) {
			return this.isValidRefundValues() && this.isValidPaymentValues();
		}

		return false;
	}

	isValidRefundValues(): boolean {
		let result = true;
		this.refundItems.forEach(refund => {
			if (!refund.refund) {
				return;
			}
			if (refund.value > refund.item.quantity * refund.item.price) {
				result = false;
			}
		});

		return result;
	}

	isValidPaymentValues(): boolean {
		let result = true;
		this.payments.forEach(payment => {
			if (payment.refund > payment.transaction.amount) {
				result = false;
			}
		});

		return result;
	}

	save(): void {
		this.loading = true;
		const model = this.createModel();
		console.debug(model);
		this.orderService.refundOrder(this.order, model).subscribe(value =>
			this.activeModal.close(value)
		);
	}

	createModel(): object {
		const model = {
			items: new Array(),
			transactions: new Array()
		};

		this.refundItems.forEach(refund => {
			if (!refund.refund) {
				return;
			}
			model.items.push(
				{
					id: refund.item.id,
					quantity: refund.quantity,
					value: refund.value
				}
			);
		});

		if (this.payments.length === 1) {
			const payment = this.payments[0];
			model.transactions.push(
				{
					id: payment.transaction.id,
					value: this.refundValue()
				}
			);
		} else {
			this.payments.forEach(payment => {
				if (payment.refund <= 0) {
					return;
				}
				model.transactions.push(
					{
						id: payment.transaction.id,
						value: payment.refund
					}
				);
			});
		}
		return model;
	}

}
