import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationDialogComponent} from '../../../../../component/confirmation-dialog/confirmation-dialog.component';
import {Document} from '../../../../../model/domain/document';
import {ModalService} from '../../../../../core/service/modalservice';

@Component({
	selector: 'app-image-view-modal',
	templateUrl: './image-view-modal.component.html',
	styleUrls: ['./image-view-modal.component.scss']
})
export class ImageViewModalComponent {
	document: Document;

	constructor(public activeModal: NgbActiveModal) {}

	setDocument(document: Document): void {
		this.document = document;
	}
}
