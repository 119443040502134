<div *ngIf="projection" xmlns="http://www.w3.org/1999/html">
	<div class="card rounded-top border-0 shadow">
		<div class="bg-gradient-primary m-0 text-light rounded-top">
			<div class="row p-2">
				<ng-content></ng-content>
			</div>
		</div>

		<table class="table table-borderless table-striped table-hover table-lg table-responsive-lg card-body rounded-bottom mb-0"
		       *ngIf="!isLoading && projection.total == 0">
			<div class="alert alert-warning text-center m-0 rounded-0" role="alert">
				<i class="fa fa-warning"></i> No records found
			</div>
		</table>

		<table class="table table-borderless table-striped table-hover table-lg table-responsive-lg card-body mb-0"
		       *ngIf="projection.total > 0">
			<thead class="table-responsive-lg">
			<tr style="border-top: hidden;">
				<th class="" *ngFor="let header of headers" (click)="setSort(header.name)">

				<span *ngIf="projection.sort === header.name">
					<span *ngIf="projection.order === 'ASC'" class="fa fa-sort-asc larger">
					</span>

					<span *ngIf="projection.order  === 'DESC'" class="fa fa-sort-desc larger">
					</span>
				</span>
					{{header.displayName}}
				</th>
			</tr>
			</thead>

			<tbody>
			<tr *ngFor="let object of items" (click)="click(object)">
				<td [ngStyle]="{'width': header.width + '%'}" class="" *ngFor="let header of headers"
				    [innerHTML]="object[header.name] | dynamicPipe:header.pipe:object"></td>
			</tr>
			<tr class="loading" *ngIf="isLoading && projection.total > 0">
				<td>
					<div class="tablespinner">
						<div class="spinner-border text-primary" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
				</td>
			</tr>
			</tbody>
		</table>
	</div>

	<ngb-pagination *ngIf="projection.total > 0" class="d-flex justify-content-center mt-3 pt-3 mb-0"
	                (pageChange)="changePage($event)"
	                [collectionSize]="projection.total"
	                [pageSize]="projection.size"
	                [maxSize]="10"
	                [page]="projection.page"
	                [rotate]="true"
	                [ellipses]="false"
	                [boundaryLinks]="true"
	                [disabled]='isLoading'
	>
	</ngb-pagination>

	<div class="text-muted text-center small mt-0 p-0" *ngIf="projection.total > 0">
		{{(projection.page - 1) * size + 1}} - {{math.min(projection.page * size, projection.total)}} /
		{{projection.total}}
	</div>
</div>
