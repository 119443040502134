import {Pipe, PipeTransform, Injector, Type} from '@angular/core';

@Pipe({
	name: 'EnumType'
})
export class EnumTypePipe implements PipeTransform {
	constructor() {}

	transform(value: any): any {
		return this.capitalizeFirstLetter(value.replace('_', ' '));
	}

	capitalizeFirstLetter(text: string): string {
		return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
	}
}
