import {Component, EventEmitter, OnInit} from '@angular/core';
import {InventoryService} from '../../../core/service/inventory.service';
import {Inventory} from '../../../model/inventory/inventory';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatest} from 'rxjs';

@Component({
	selector: 'app-inventory-dashboard',
	templateUrl: './inventory-dashboard.component.html',
	styleUrls: ['./inventory-dashboard.component.scss']
})
export class InventoryDashboardComponent implements OnInit {
	inventories: Inventory[];
	selectedId: number = null;
	selected: Inventory = null;
	transactionUpdater: EventEmitter<any>;

	constructor(
		private route: ActivatedRoute,
		private inventoryService: InventoryService
	) {
		this.transactionUpdater = new EventEmitter<any>();
	}

	ngOnInit(): void {
		combineLatest([this.route.paramMap, this.inventoryService.getAll()]).subscribe(
			([params, inventories]) => {
				this.inventories = inventories;
				if (this.selectedId === null) {
					const url = Number(params.get('id'));
					if (!isNaN(url) && url > 0) {
						this.selectedId = url;
						this.getInventory();
					} else if (inventories.length > 0) {
						this.selectedId = inventories[0].id;
						this.getInventory();
					}
				} else {
					const url = Number(params.get('id'));
					if (!isNaN(url) && url > 0) {
						this.selectedId = url;
						this.getInventory();
					}
				}
			}
		);

	}

	getInventory(): void {
		this.inventoryService.getById(this.selectedId).subscribe(result => {
			this.selected = result;
			this.transactionUpdater.emit();
		});
	}
}
