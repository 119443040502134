<div class="modal-header">
	<h3>View image {{document.name}}</h3>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" tabindex="-1">
		&times;
	</button>
</div>

<div class="modal-body text-center">
	<img [src]="'api/document/' + document.id | authImage | async" class="img-fluid">
</div>
