import {CurrencyPipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Tattoo} from '../../../model/domain/tattoo';
import {ArtistPipe} from '../../../core/pipe/artist.pipe';
import {BooleanLightPipe} from '../../../core/pipe/boolean-light.pipe';
import {EnumTypePipe} from '../../../core/pipe/enum-type.pipe';
import {FormatDateTimePipe} from '../../../core/pipe/formatdatetime.pipe';
import {IdPipe} from '../../../core/pipe/id.pipe';
import {StudioPipe} from '../../../core/pipe/studio.pipe';
import {UsernamePipe} from '../../../core/pipe/username.pipe';
import {TattooService} from '../../../core/service/tattoo.service';
import {AbstractTableViewComponent} from '../../../component/table-view/abstract-table-view-component';
import {TableColumn} from '../../../component/table-view/table-column-model';
import {TableProjection} from '../../../component/table-view/table-projection';

@Component({
	selector: 'app-tattoo-list',
	templateUrl: './tattoo-list.component.html',
	styleUrls: ['./tattoo-list.component.scss']
})
export class TattooListComponent extends AbstractTableViewComponent<Tattoo> implements OnInit {
	constructor(
		private route: Router,
		private tattooService: TattooService
	) {
		super();
		this.defaultFilters.status = 'closed';
		this.defaultFilters.studio = 'any';
		this.defaultFilters.touchup = false;
	}

	ngOnInit(): void {
		this.headers.push(new TableColumn('ID', 'id', 5, new IdPipe()));
		this.headers.push(new TableColumn('Date', 'date', 15, new FormatDateTimePipe()));
		this.headers.push(new TableColumn('Status', 'status', 10, new EnumTypePipe()));
		this.headers.push(new TableColumn('Touchup', 'touchup', 5, new BooleanLightPipe()));
		this.headers.push(new TableColumn('Artist', 'artist', 10, new ArtistPipe()));
		this.headers.push(new TableColumn('Studio', 'studio', 20, new StudioPipe()));
		this.headers.push(new TableColumn('Sold by', 'owner', 20, new UsernamePipe()));
		this.headers.push(new TableColumn('Price', 'price', 10, new CurrencyPipe('nl', 'EUR')));
	}

	getObjects(projection: TableProjection): void {
		this.filters = projection.filterObj;
		const request = projection.getAsListRequest();
		this.tattooService.getAllTattoos(request).subscribe(
			value => {
				this.objects.next(value);
			}
		);
	}

	onObjectSelect(tattoo: Tattoo): void {
		this.route.navigate([`/tattoo/${tattoo.id}`]);
	}
}
