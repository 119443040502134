import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-confirmation-dialog',
	templateUrl: './confirmation-dialog.component.html',
	styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
	@Input() message: string;

	constructor(public activeModal: NgbActiveModal) { }

	ngOnInit(): void {
	}

	cancel(): void {
		this.activeModal.close(false);
	}

	confirm(): void {
		this.activeModal.close(true);
	}
}
