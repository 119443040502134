<div class="card mt-3 shadow">
	<div class="card-header bg-gradient-primary text-white">
		<h2 class="card-title text-center">New user</h2>
	</div>

	<div class="col-8 offset-2">
		<br/>
		<app-form [fromSubmission]="formState">
			<h5>User details</h5>
			<div class="row">
				<div class="col-6 mb-3">
					<app-input-text name="name" label="Name" [(ngModel)]="user.name" ngDefaultControl></app-input-text>
				</div>
				<div class="col-6 mb-3">
					<app-input-text name="email" label="Email" [(ngModel)]="user.email" ngDefaultControl></app-input-text>
				</div>
			</div>
			<div>
				<div class="row">
					<div class="col-6 mb-3">
						<app-input-text name="password" label="Initial password" [(ngModel)]="user.password" ngDefaultControl></app-input-text>
					</div>
				</div>
			</div>
			<br/>
			<h5>Access Settings</h5>
			<div>
				<div class="row">
					<div class="col-6 mb-3">
						<app-input-checkbox name="active" label="Active" desc="Account status" [(ngModel)]="user.active" ngDefaultControl>
						</app-input-checkbox>
					</div>
					<div class="col-6 mb-3">
						<app-input-radio name="access" label="User acces level" [options]="['Agenda', 'Admin']" [(ngModel)]="user.access" ngDefaultControl>
						</app-input-radio>
					</div>
				</div>
				<div class="row">
					<div class="col-6 mb-3">
						<label>Agenda access for studio:</label>
						<div *ngFor="let studio of studios; index as i">
							<app-input-checkbox [name]="'studio' + i" [label]="studio.name" [(ngModel)]="studioValue[i]" ngDefaultControl>
							</app-input-checkbox>
						</div>
					</div>

				</div>
			</div>

			<hr class="mb-4">

			<div class="row">
				<div class="offset-8 col-4">
					<button class="btn btn-success btn-lg btn-block" type="submit" (click)="save()">Save</button>
				</div>
			</div>
		</app-form>
		<br/>
	</div>

</div>

