<app-page name="Daily performance">
	<div class="shadow-lg mb-4">
		<div class="row bg-gradient-primary m-0 p-3 text-light justify-content-center rounded-top" style="font-size: larger">
			<div class="col-4 align-self-end">
				<h4 class="mb-0" *ngIf="overview && overview.length > 0">
					{{overview[0].studio.name}} - Week {{startDate.format('w')}}<br/>
				</h4>
			</div>

			<div class="col-8 text-right">
				<form class="form-inline pull-right float-right">
					<div class="form-group">
						<div class="input-group">
							<ng-template #dtcustom let-date let-currentMonth="currentMonth" let-disabled="disabled">
								<div class="daytemplate"
								     [class.btn-light]="!disabled"
								     [class.text-muted]="disabled"
								     [class.bg-success]="inCurrentWeek(date)"
								     [class.weekstart]="isFirstDay(date)"
								     [class.weekend]="isLastDay(date)"
								>
									{{ date.day }}
								</div>
							</ng-template>

							<input class="form-control" name="dp"
							       ngbDatepicker
							       #d="ngbDatepicker"
							       placeholder="dd-mm-yyyy"
							       [(ngModel)]="inputDate"
							       [maxDate]="maxDate"
							       [dayTemplate]="dtcustom"
							       (dateSelect)="changeForm()"
							       readonly="true"
							>
							<div class="input-group-append mr-4">
								<button class="btn btn-light fa fa-calendar" (click)="d.toggle()" type="button"
								        [attr.disabled]="loading ? 'true' : null"></button>
							</div>
						</div>
					</div>
					<app-studio-filter [(form)]="studio" allowAll="false" (filterchange)="changeForm()" [disabled]="loading"></app-studio-filter>
				</form>
			</div>
		</div>

		<table class="table table-borderless table-striped table-hover table-lg table-responsive-lg card-body rounded-bottom" *ngIf="!loading && overview && overview.length === 0">
			<div class="alert alert-warning text-center mb-0 rounded-0" role="alert">
				<i class="fa fa-warning"></i> The studio was not opened this week
			</div>
		</table>

		<table class="table table-striped table-borderless card-body mb-0" style="vertical-align: bottom" *ngIf="!loading && overview &&  overview.length > 0">
			<thead>
			<tr class="text-right font-weight-bold">
				<td class="text-left">Date</td>
				<td>Customers</td>
				<td>Tattoos</td>
				<td>Piercings</td>
				<td>Products</td>
				<td>Giftcards</td>
				<td>Tattoo</td>
				<td>Piercing</td>
				<td>Products</td>
				<td>Paid cash</td>
				<td>Paid card</td>
				<td>Paid giftcard</td>
			</tr>
			</thead>
			<tbody *ngIf="overview">
			<tr *ngFor="let day of overview" class="text-right text-monospace">
				<td class="text-left ">
					{{day.day | formatdate:'DD-MM ddd'}}
				</td>
				<td>
					<a href="/orders?filters=mindate:{{day.day}}&filters=maxdate:{{day.day}}&filters=studio:{{studio}}">
						{{day.customers}}
					</a>
				</td>
				<td>
					<a href="/tattoos?filters=mindate:{{day.day}}&filters=maxdate:{{day.day}}&filters=studio:{{studio}}">
						{{day.tattoos}}
					</a>
				</td>
				<td>
					<a href="/piercings?filters=mindate:{{day.day}}&filters=maxdate:{{day.day}}&filters=studio:{{studio}}">
						{{day.piercings}}
					</a>
				</td>
				<td>{{day.products}}</td>
				<td>{{day.giftcards}}</td>
				<td>{{day.earningTattoos | currency}}</td>
				<td>{{day.earningPiercings | currency}}</td>
				<td>{{day.earningProducts | currency}}</td>
				<td>{{day.totalCash | currency}}</td>
				<td>{{day.totalCards | currency}}</td>
				<td>{{day.totalGiftcards | currency}}</td>
			</tr>
			</tbody>
		</table>
		<div class="row mt-0 pt-0" *ngIf="loading">
			<div class="col-12 text-center mt-0 pt-0 mb-3 mt-3">
				<div class="spinner-border text-primary" role="status">
					<span class="sr-only">Loading...</span>
				</div>
			</div>
		</div>
	</div>
</app-page>
