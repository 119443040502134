import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {ModalService} from '../../../core/service/modalservice';
import {Inventory} from '../../../model/inventory/inventory';
import {InventoryItemModel} from '../../../model/inventory/inventory-item-model';
import {InventoryItemCorrectionComponent} from './inventory-item-correction/inventory-item-correction.component';
import {InventoryItemLimitsComponent} from './inventory-item-limits/inventory-item-limits.component';

@Component({
	selector: 'app-inventory',
	templateUrl: './inventory.component.html',
	styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit {
	@Input() inventory: Inventory;
	@Input() onChange: EventEmitter<any>;

	constructor(private modalService: ModalService) { }

	ngOnInit(): void {
	}

	editItem(item: InventoryItemModel): void {
		const modelRef = this.modalService.open(InventoryItemLimitsComponent);
		modelRef.componentInstance.setItem(item);

		modelRef.result.then(
			value => {
				if (value) {
					this.inventory = value;
				}
			},
			value => {}
		).catch(value => value);
	}

	createCorrection(item: InventoryItemModel): void {
		const modelRef = this.modalService.open(InventoryItemCorrectionComponent);
		modelRef.componentInstance.setItem(item);

		modelRef.result.then(
			value => {
				if (value) {
					this.inventory = value;
					this.onChange.emit();
				}
			},
			value => {}
		).catch(value => value);
	}
}
