<div class="card mt-3 shadow">
	<div class="card-header bg-gradient-primary text-white">
		Recent transactions
	</div>
	<table class="table table-striped table-sm rounded card-body">
		<tr class="p-2">
			<th class="p-2">
				Date
			</th>
			<th class="p-2">
				Type
			</th>
			<th class="p-2">
				Account
			</th>
			<th class="p-2">
				Amount
			</th>
			<th class="p-2">
				Description
			</th>
		</tr>
		<tbody>
			<tr *ngFor="let transaction of transactions">
				<td class="p-2">{{transaction.created | date}}</td>
				<td class="p-2">{{transaction.type | titlecase}}</td>
				<td class="p-2">{{transaction.account.name }}</td>
				<td class="p-2">{{transaction.amount | currency:'EUR'}}</td>
				<td class="p-2">{{transaction.description}}</td>
			</tr>
		</tbody>
	</table>
	<div class="card-body text-center">
		<a class="card-link">See more</a>
	</div>
</div>
