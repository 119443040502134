import {Pipe, PipeTransform, Injector, Type} from '@angular/core';

@Pipe({
	name: 'usernamePipe'
})
export class UsernamePipe implements PipeTransform {

	constructor() {}

	transform(value: any): any {
		if (value === undefined || value === null) {
			return '<span class="text-muted">System</span>';
		}

		if (value.name === undefined) {
			return `<a href="user/${value.id}/">Unnamed user</a>`;
		}

		if (value.id === undefined) {
			return 'System';
		}

		return `<a href="user/${value.id}/">${value.name}</a>`;
	}

}
