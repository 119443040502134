import {HttpClient, HttpEvent, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Artist} from '../../model/domain/artist';
import {Document} from '../../model/domain/document';


@Injectable({
	providedIn: 'root'
})
export class DocumentService {
	private EXT_IMAGES = ['jpg', 'jpeg', 'gif', 'png', 'webp', 'svg', 'psd', 'bmp', 'tif', 'ps', 'ico', 'tiff', 'raw', 'orf', 'ai', 'eps', 'indd', 'cr2', 'cr3', 'nef', 'crw', 'pef'];
	private EXT_DOCS = ['doc', 'docx', 'txt', 'text', 'cvs', 'rtf', 'tex', 'wpf', 'odt'];
	private EXT_SPREADSHEET = ['xls', 'xlsx', 'ods', 'xlsm'];
	private EXT_ZIP = ['zip', 'rar', '7z', 'arj', 'pkg', 'tar.gz', 'z'];
	private EXT_PDF = ['pdf'];

	private iconExt: Map<string, string[]> = new Map([
		['pdf', this.EXT_PDF],
		['zip', this.EXT_ZIP],
		['excel', this.EXT_SPREADSHEET],
		['word', this.EXT_DOCS],
		['image', this.EXT_IMAGES],
	]);

	private url = 'api/artist/';

	constructor(
		private http: HttpClient
	) {}

	getDocuments(artist: Artist): Observable<Document[]> {
		return this.http.get<Document[]>(`${this.url}${artist.id}/documents`);
	}

	getRecentDocuments(artist: Artist): Observable<Document[]> {
		return this.http.get<Document[]>(`${this.url}${artist.id}/documents/recent`);
	}

	upload(artist: Artist, file: File, name: string, comments: string): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();

		formData.append('file', file);
		formData.append('comments', comments);
		formData.append('name', name);

		const req = new HttpRequest('POST', `${this.url}${artist.id}/upload`, formData, {
			reportProgress: true,
			responseType: 'json'
		});
		return this.http.request(req);
	}

	getFileType(extension: string): string {
		if (!extension) {
			return '';
		}

		for (const [key, value] of this.iconExt) {
			if (value.includes(extension.toLowerCase())) {
				return key;
			}
		}
	}

	/**
	 * Get a font-awesome named icon for this extenstion
	 * @param extension The extension of the file (pdf, jpg, etc) Case-insensitive
	 */
	getFAIconForFiletype(extension: string): string {
		if (!extension) {
			return 'fa-file-o';
		}

		for (const [key, value] of this.iconExt) {
			if (value.includes(extension.toLowerCase())) {
				return 'fa-file-' + key + '-o';
			}
		}

		return 'fa-file-o';
	}
}
