<div class="card-deck mt-3">
	<div class="card" *ngFor="let account of artist.accounts">
		<div class="card-header">
			{{account.name}} account
		</div>
		<div class="card-body">
			Balance: <strong>{{account.currentBalance | currency:"EUR"}}</strong> <br/>
			Last transaction: 22 October, 2020<br/>
			Last payout: <i>never</i>
		</div>
	</div>

</div>

<!--<app-account-transactions [transactions]="transactions"></app-account-transactions>-->
