import {TitleCasePipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {BooleanLightPipe} from '../../../core/pipe/boolean-light.pipe';
import {EnumTypePipe} from '../../../core/pipe/enum-type.pipe';
import {FormatDatePipe} from '../../../core/pipe/formatdate.pipe';
import {ArtistService} from '../../../core/service/artist.service';
import {AbstractTableViewComponent} from '../../../component/table-view/abstract-table-view-component';
import {TableColumn} from '../../../component/table-view/table-column-model';
import {TableProjection} from '../../../component/table-view/table-projection';
import {Artist} from '../../../model/domain/artist';

@Component({
	selector: 'app-artist-list',
	templateUrl: './artist-list.component.html',
	styleUrls: ['./artist-list.component.scss']
})
export class ArtistListComponent extends AbstractTableViewComponent<Artist> implements OnInit {
	constructor(
		private route: Router,
		private artistService: ArtistService
	) {
		super();
		this.defaultFilters.active = true;
		this.defaultFilters.type = 'any';
	}

	ngOnInit(): void {
		this.headers.push(new TableColumn('Active', 'active', 5, new BooleanLightPipe()));
		this.headers.push(new TableColumn('Added', 'created', 10, new FormatDatePipe()));
		this.headers.push(new TableColumn('Artist type', 'artistType', 10, new EnumTypePipe()));
		this.headers.push(new TableColumn('Nickname', 'nickname', 10));
		this.headers.push(new TableColumn('Name', 'fullname', 15));
		this.headers.push(new TableColumn('Company', 'commerceName', 20, new TitleCasePipe()));
	}

	getObjects(projection: TableProjection): void {
		this.filters = projection.filterObj;
		const request = projection.getAsListRequest();
		this.artistService.getAllArtists(request).subscribe(
			value => {
				this.objects.next(value);
			}
		);
	}

	onObjectSelect(artist: Artist): void {
		this.route.navigate([`/artist/${artist.id}`]);
	}
}
