import {NgbDate, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {NgbTime} from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time';
import * as moment from 'moment';
import {Moment} from 'moment';

export abstract class DateUtil {
	public static getMomentFromNgbDate(date: NgbDate): Moment {
		if (!date) {
			return undefined;
		}
		return moment().set('year', date.year).set('month', date.month - 1).set('date', date.day);
	}

	public static getNgbDateFromMoment(date: Moment): NgbDate {
		return new NgbDate(date.get('year'), date.get('month') + 1, date.get('date'));
	}

	public static getNgbDateFromString(date: string): NgbDate {
		const value = Number(date);
		if (value && !isNaN(value)) {
			return this.getNbgDateFromNumber(value);
		}
		return undefined;
	}

	public static getTimestampFromDate(date: NgbDate): number {
		return this.getMomentFromNgbDate(date).valueOf();
	}

	public static getTimestampFromDateTime(date: NgbDate, time: NgbTimeStruct): number {
		return this.getMomentFromNgbDate(date).startOf('day').add(moment.duration(time.hour + ':' + time.minute)).valueOf();
	}

	public static getNbgDateFromNumber(date: number): NgbDate {
		return this.getNgbDateFromMoment(moment(date));
	}

	public static getNbgTimeFromNumber(timestamp: number): NgbTimeStruct {
		const date = moment(timestamp);
		const time = {
			hour: date.get('hours'),
			minute: date.get('minute'),
			second: 0
		};

		return time;
	}
}
