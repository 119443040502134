import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormComponent} from '../form/form.component';

@Component({
	selector: 'app-input-checkbox',
	templateUrl: './input-checkbox.component.html',
	styleUrls: ['./input-checkbox.component.scss']
})
export class InputCheckboxComponent implements OnInit {
	@Input()
	name: string;
	@Input()
	desc: string;
	@Input()
	label: string;
	@Input()
	ngModel: boolean;
	value: boolean;
	@Output()
	ngModelChange = new EventEmitter<boolean>();

	constructor(public form: FormComponent) { }

	ngOnInit(): void {
		if (this.ngModel) {
			this.value = true;
		} else {
			this.value = false;
		}
	}

	change(): void {
		this.ngModelChange.emit(this.value);
	}
}
