import {Pipe, PipeTransform} from '@angular/core';
import {Artist} from '../../model/domain/artist';

@Pipe({
	name: 'artistname'
})
export class ArtistnamePipe implements PipeTransform {

	transform(artist: Artist): any {
		if (artist === undefined || artist === null) {
			return '';
		}

		return artist.nickname || artist.fullname;
	}

}
