import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AccountTransactionService} from '../../../../core/service/account-transaction.service';
import {ArtistViewService} from '../artist.view.service';
import {Artist} from '../../../../model/domain/artist';
import {ArtistAccount} from '../../../../model/domain/artist-account';

@Component({
	selector: 'app-artist-account-correction',
	templateUrl: './artist-account-correction.component.html',
	styleUrls: ['./artist-account-correction.component.scss']
})
export class ArtistAccountCorrectionComponent implements OnInit {
	artist: Artist;
	account: ArtistAccount;
	sending = false;

	form = {
		correction: undefined,
		description: ''
	};

	constructor(
		public activeModal: NgbActiveModal,
		private accountTransactionService: AccountTransactionService
	) {}

	ngOnInit(): void {
	}

	canSave(): boolean {
		if (!this.sending) {
			if (this.form.correction !== 0 && this.form.correction !== undefined && (this.form.correction > 0 || this.form.correction < 0)) {
				if (this.form.description.length > 3) {
					return true;
				}
			}

		}
		return false;
	}

	saveCorrection(account: string, amount: number, description: string): void {
		this.sending = true;
		this.accountTransactionService.addCorrection(this.artist, account, amount, description).subscribe(result => {
			this.activeModal.close(result);
		});
	}
}
