<app-page name="Products" *ngIf="products">
	<div class="row">
		<div class="col-6 mb-4" *ngFor="let group of products">
			<div class="shadow">
				<div class="card rounded table-responsive border-0">
					<div class="card-header bg-gradient-primary text-light">
						<h3 class="mb-0">{{group.name}}</h3>
					</div>
					<table class="table table-striped table-borderless table-hover table-sm rounded-bottom card-body mb-0">
						<thead class="p-2">
						<tr>
							<th style="width: 1%"></th>
							<th>
								Name
							</th>
							<th class="text-right">
								Price
							</th>
							<th style="width: 1%"></th>
						</tr>
						</thead>
						<tbody>
						<tr *ngFor="let product of group.products">
							<td class="text-center">
								<span *ngIf="product.available" class="fa fa-check-circle text-success ml-2 mr-2"></span>
								<span *ngIf="!product.available" class="fa fa-times-circle text-danger ml-2 mr-2"></span>
							</td>
							<td>{{product.description ?? product.name}}</td>
							<td class="text-right">{{product.price | currency:"EUR"}}</td>
							<td>
								<div class="pl-2 pr-2">
									<button class="btn btn-primary btn-sm" (click)="onEdit(product)"><i class="fa fa-wrench"></i></button>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</app-page>
