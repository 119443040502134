import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Artist} from '../../../../model/domain/artist';
import {ArtistService} from '../../../../core/service/artist.service';
import {ModalService} from '../../../../core/service/modalservice';
import {ArtistStudioNewComponent} from '../artist-studio-new/artist-studio-new.component';
import {ArtistViewService} from '../artist.view.service';
import {ArtistStudioModel} from '../model/artist-studio-model';

@Component({
	selector: 'app-artist-studios',
	templateUrl: './artist-studios.component.html',
	styleUrls: ['./artist-studios.component.scss']
})
export class ArtistStudiosComponent implements OnInit {
	artist: Artist;
	studios: ArtistStudioModel[];

	constructor(
		private route: ActivatedRoute,
		private artistService: ArtistService,
		private artistViewService: ArtistViewService,
		private modalService: ModalService
	) {}

	ngOnInit(): void {
		this.artistViewService.get().subscribe(artist => {
			this.artist = artist;
			if (this.artist.id !== undefined) {
				this.artistService.getStudios(this.artist.id).subscribe(results => this.studios = results);
			}
		});
	}

	removeStudio(studio: number): void {
		this.artistService.removeStudioFromArtist(this.artist, studio).subscribe(
			result => this.artistViewService.update(result)
		);
	}

	addStudio(): void {
		this.modalService.open(ArtistStudioNewComponent, {size: 'lg'});
	}
}
