<div class="modal-header">
	<h4 class="modal-title"><span class="fa fa-percent mr-2 text-center"></span> Modify share percentage</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" tabindex="-1">
		&times;
	</button>
</div>

<div class="modal-body text-lg-right">
	<div class="row">
		<div class="col-4 offset-2 mb-3">Piercing price</div>
		<div class="col-4 mb-3">
			{{piercing.price | currency}}
		</div>
	</div>

	<div class="row">
		<div class="col-4 offset-2 mb-3">Current percentage</div>
		<div class="col-4 mb-3">
			<div class="input-group">
				<input type="number" class="form-control font-weight-bold" id="inputCorrection" disabled="true" [value]="piercing.artistSharePerc" autofocus>
				<div class="input-group-append">
					<span class="input-group-text" id="basic-addon">%</span>
				</div>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-4 offset-2 mb-3">New percentage</div>
		<div class="col-4 mb-3">
			<div class="input-group">
				<input type="number" class="form-control" id="inputCorrection" [(ngModel)]="form.percentage" autofocus>
				<div class="input-group-append">
					<span class="input-group-text" id="basic-addon">%</span>
				</div>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-4 offset-2 mb-3">Current share for artist</div>
		<div class="col-4 mb-3">
			{{piercing.totalArtistTransaction | currency}}
		</div>
	</div>
	<div class="row">
		<div class="col-4 offset-2 mb-3">New share for artist</div>
		<div class="col-4 mb-3">
			{{piercing.price * (form.percentage / 100) | currency}}
		</div>
	</div>
</div>

<div class="modal-footer">
	<button type="button" class="btn btn-danger mr-auto" (click)="activeModal.close()">Cancel</button>
	<label class="mr-auto"></label>
	<button type="button" class="btn btn-success" [disabled]="!canSave()" (click)="save()">Save
	</button>
</div>
