import {Pipe, PipeTransform, Injector, Type} from '@angular/core';

@Pipe({
	name: 'accountPipe'
})
export class AccountPipe implements PipeTransform {

	constructor() {}

	transform(value: any): any {
		if (value.name === 'BTW' || value === 'BTW') {
			return '<b class="text-primary">BTW</b>';
		}

		if (value.name === 'Main' || value === 'Main') {
			return '<b class="text-success">Main</b>';
		}

		return value.name;
	}
}
