import {Component, OnInit} from '@angular/core';
import {SalesReportService} from '../../../core/service/sales-report.service';
import {SalesReport} from '../../../model/report/sales/sales-report';
import {StudioService} from '../../../core/service/studio.service';
import {Studio} from '../../../model/domain/studio';
import {SelectionListModel} from './selection-list-model';
import {ProductGroupService} from '../../../core/service/productgroup.service';
import {PiercingGroupService} from '../../../core/service/piercinggroup.service';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {DateUtil} from '../../../core/util/date.util';
import * as moment from 'moment';

@Component({
	selector: 'app-report-sales',
	templateUrl: './report-sales.component.html',
	styleUrls: ['./report-sales.component.scss']
})
export class ReportSalesComponent implements OnInit {
	salesReport: SalesReport;
	studios: SelectionListModel[] = [];
	groups: SelectionListModel[] = [];
	showQuantity = false;
	hideZero = false;

	startDate: NgbDate = null;
	endDate: NgbDate = null;

	today: NgbDate = DateUtil.getNgbDateFromMoment(moment());
	tdWidth: number;

	constructor(
		private salesReportService: SalesReportService,
		private studioService: StudioService,
		private productGroupService: ProductGroupService,
		private piercingGroupService: PiercingGroupService,
	) { }

	ngOnInit(): void {
		this.productGroupService.getAll().subscribe(results => {
			results.forEach(val => this.groups.push({id: '#pr' + val.id, name: 'Product: ' + val.name, checked: false}));
			this.groups.push({id: '#prf', name: 'Product: Misc', checked: false});
		});

		this.piercingGroupService.getAll().subscribe(results => {
			results.forEach(val => this.groups.push({id: '#pi' + val.id, name: 'Piercing: ' + val.name, checked: false}));
		});

		this.groups.push({id: 'tp', name: 'Tattoo & Piercing', checked: false});

		this.studioService.getAll().subscribe(results => {
				this.createStudioList(results);
				this.updateReport();
			}
		);
	}

	toggleQP(): void {
		this.showQuantity = !this.showQuantity;
	}

	toggleZero(): void {
		this.hideZero = !this.hideZero;
	}

	updateReport(): void {
		console.log('Fetching report');

		let startDate = 0;
		let endDate = 0;

		if (this.getStudioList().length === 0) {
			this.salesReport = undefined;
			return;
		}

		if (this.startDate !== null) {
			startDate = DateUtil.getMomentFromNgbDate(this.startDate).valueOf();
		}
		if (this.endDate !== null) {
			endDate = DateUtil.getMomentFromNgbDate(this.endDate).valueOf();
		}

		this.salesReportService
			.getReport({
				studios: this.getStudioList(),
				groups: this.getGroupList(),
				startDate,
				endDate
			})
			.subscribe(result => {
				this.salesReport = result;
				this.tdWidth = (80 / this.salesReport.studios.length);
			});
	}

	private createStudioList(list: Studio[]): void {
		const modelList = [];
		list.forEach(val => modelList.push({id: val.id, name: val.name.replace('Bodycult ', ''), checked: true}));
		this.studios = modelList;
	}

	getStudioList(): number[] {
		const list = [];
		this.studios.forEach(studio => studio.checked ? list.push(studio.id) : null);
		return list;
	}

	getGroupList(): string[] {
		const groups = [];
		this.groups.forEach(group => group.checked ? groups.push(group.id) : null);
		return groups;
	}

	getCellWidthStyling(): string {
		if (this.getStudioList().length === 0) {
			return '';
		}

		return 'width: ' + this.tdWidth + '%;';
	}
}
