import {Pipe, PipeTransform, Injector, Type} from '@angular/core';

@Pipe({
	name: 'IdPipe'
})
export class IdPipe implements PipeTransform {
	constructor() {}

	transform(value: any): any {
		if (!value) {
			return;
		} else {
			return '<strong>#' + value + '</strong>';
		}
	}

}
