import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {TableComponent} from './table/table.component';
import {PipeModule} from '../../core/pipe/pipe.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TableStudioFilterComponent} from './filters/table-studio-filter/table-studio-filter.component';
import {TableDateFilterComponent} from './filters/table-date-filter/table-date-filter.component';


@NgModule({
	declarations: [TableComponent, TableStudioFilterComponent, TableDateFilterComponent],
	imports: [
		FormsModule,
		CommonModule,
		PipeModule,
		NgbModule
	],
	exports: [
		TableComponent,
		TableStudioFilterComponent,
		TableDateFilterComponent
	]
})
export class TableViewModule {

}
