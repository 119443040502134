<div class="modal-header">
	<h4 class="modal-title"><span class="fa fa-plus-circle mr-2 text-center"></span> Select product</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" tabindex="-1">
		&times;
	</button>
</div>

<div class="modal-body">
	<div *ngFor="let group of products" class="mb-3">
		<div class="row">
			<div class="col-12">
				<h3>{{group.name}}</h3>
			</div>
		</div>
		<div class="row justify-content-around">
			<div class="col-2 mb-4 p-1" *ngFor="let product of group.products">
				<button class="btn btn-block btn-primary" (click)="select(product)">
					{{product.name}}
				</button>
			</div>
		</div>
	</div>
</div>
