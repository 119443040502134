import {CurrencyPipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {PiercingTransaction} from '../../../model/domain/piercing-transaction';
import {ArtistPipe} from '../../../core/pipe/artist.pipe';
import {FormatDateTimePipe} from '../../../core/pipe/formatdatetime.pipe';
import {IdPipe} from '../../../core/pipe/id.pipe';
import {PiercingNamePipe} from '../../../core/pipe/piercing-name.pipe';
import {StudioPipe} from '../../../core/pipe/studio.pipe';
import {UsernamePipe} from '../../../core/pipe/username.pipe';
import {PiercingTransactionService} from '../../../core/service/piercing-transaction.service';
import {AbstractTableViewComponent} from '../../../component/table-view/abstract-table-view-component';
import {TableColumn} from '../../../component/table-view/table-column-model';
import {TableProjection} from '../../../component/table-view/table-projection';

@Component({
	selector: 'app-piercing-list',
	templateUrl: './piercing-list.component.html',
	styleUrls: ['./piercing-list.component.scss']
})
export class PiercingListComponent extends AbstractTableViewComponent<PiercingTransaction> implements OnInit {

	constructor(
		private route: Router,
		private piercingService: PiercingTransactionService
	) {
		super();
		this.defaultFilters.studio = 'any';
	}

	ngOnInit(): void {
		this.headers.push(new TableColumn('ID', 'id', 5, new IdPipe()));
		this.headers.push(new TableColumn('Date', 'created', 15, new FormatDateTimePipe()));
		this.headers.push(new TableColumn('Piercer', 'artist', 15, new ArtistPipe()));
		this.headers.push(new TableColumn('Studio', 'studio', 15, new StudioPipe()));
		this.headers.push(new TableColumn('Sold by', 'owner', 15, new UsernamePipe()));
		this.headers.push(new TableColumn('Piercing', 'piercing', 12, new PiercingNamePipe()));
		this.headers.push(new TableColumn('Quantity', 'quantity', 8));
		this.headers.push(new TableColumn('Price', 'price', 15, new CurrencyPipe('nl', 'EUR')));
	}

	getObjects(projection: TableProjection): void {
		this.filters = projection.filterObj;
		const request = projection.getAsListRequest();
		this.piercingService.getAllPiercings(request).subscribe(
			value => {
				this.objects.next(value);
			}
		);
	}

	onObjectSelect(piercing: PiercingTransaction): void {
		this.route.navigate([`/piercing/${piercing.id}`]);
	}
}
