<label [for]="name">{{label}}</label>
<!--<pre>{{options}}</pre>-->

<div class="custom-control custom-radio" *ngFor="let option of options">
	<input type="radio"  class="custom-control-input" [ngClass]="{'is-invalid': form.hasErrors(name)}"
	       [id]="option"
	       [name]="name"
	       ([ngModel])="ngModel"
	       [value]="option"
	       [checked]="option === ngModel">
	<label class="custom-control-label" [for]="option">{{option}}</label>
</div>
<div class="invalid-feedback">
	<span *ngFor="let error of form.getFieldErrors(name)">{{error}}<br/></span>
</div>
