import {Component, OnInit} from '@angular/core';
import {MonthReportService} from '../../../core/service/month-report.service';
import {StudioMonthReport} from '../../../model/report/studio-month-report';
import * as moment from 'moment';
import set = Reflect.set;

@Component({
	selector: 'app-report-month',
	templateUrl: './report-month.component.html',
	styleUrls: ['./report-month.component.scss']
})
export class ReportMonthComponent implements OnInit {
	report: StudioMonthReport;
	studio = 1;
	month = 1;
	year = 2021;
	years = [];
	months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	form = {
		month: 1,
		year: 1
	};

	loading: boolean;

	constructor(private monthReportService: MonthReportService) {}

	ngOnInit(): void {
		// this.report = this.monthReportService.getDummyMonth();
		const today = moment();
		this.form.month = today.get('month');
		this.form.year = today.get('year');
		this.changeForm();

		for (let i = 2021; i <= today.get('year'); i++) {
			this.years.push(i);
		}
	}

	changeForm(): void {
		this.loading = true;
		const date = moment();
		date.set('month', Number(this.form.month));
		date.set('year', Number(this.form.year));

		this.monthReportService.getReport(this.studio, date.valueOf()).subscribe(
			result => {
				this.report = result;
				this.loading = false;
			}
		);
	}
}
