import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StudioDayOverview} from '../../model/studio-day-overview';

@Injectable({
	providedIn: 'root'
})
export class StudioOverviewService {
	private url = 'api/studio/';

	constructor(
		private http: HttpClient
	) { }

	getReport(studio: number, startDate: number): Observable<StudioDayOverview[]> {
		const params = new HttpParams()
			.set('date', String(startDate));
		return this.http.get<StudioDayOverview[]>(`${this.url}${studio}/weekoverview`, {params});
	}
}
