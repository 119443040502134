import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'piercingNamePipe'
})
export class PiercingNamePipe implements PipeTransform {
	constructor() {}

	transform(value: any): any {
		if (value === undefined || value === null) {
			return '';
		}

		return value.name;
	}

}
