import {Pipe, PipeTransform, Injector, Type} from '@angular/core';

@Pipe({
	name: 'usernamePipe'
})
export class ArtistPipe implements PipeTransform {
	constructor() {}

	transform(value: any, requiredPipe: any, pipeArgs: any): any {
		if (value === undefined || value === null) {
			return '';
		}

		if ((value.nickname === undefined || value.nickname === null) && (value.fullname === undefined && value.fullname === null)) {
			return '';
		}

		//TODO Fix with a custom <app-user> component
		if (value.nickname !== undefined && value.nickname !== null) {
			return `<a href="artist/${value.id}/">${value.nickname}</a>`;
		}
		return `<a href="artist/${value.id}/">${value.fullname}</a>`;
	}

}
