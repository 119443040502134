<div class="col-8 offset-2">
	<app-form [fromSubmission]="formState">
		<h5>Personal information</h5>
		<div class="row">
			<div class="col-6 mb-3">
				<app-input-text name="firstname" label="First Name" [(ngModel)]="artist.firstname" ngDefaultControl></app-input-text>
			</div>
			<div class="col-6 mb-3">
				<app-input-text name="lastname" label="Last Name" [(ngModel)]="artist.lastname" ngDefaultControl></app-input-text>
			</div>
		</div>

		<div class="row">
			<div class="mb-3 col-6">
				<app-input-text name="nickname" label="Nickname" [(ngModel)]="artist.nickname" ngDefaultControl></app-input-text>
			</div>
			<div class="col-4 mb-3">
				<app-input-dropdown name="artistType" label="Artist type" [options]="['Tattoo', 'Piercer', 'Both']" [(ngModel)]="artist.artistType"
				                    ngDefaultControl>
				</app-input-dropdown>
			</div>
		</div>

		<div class="row">
			<div class="col-6 mb-3">
				<app-input-text name="bsn" label="BSN" [(ngModel)]="artist.bsn" ngDefaultControl></app-input-text>
			</div>
			<div class="col-6 mb-3">
				<app-input-dropdown name="nationality" label="Country of Birth" defaultOption="Select a country" [options]="countries"
				                    [(ngModel)]="artist.nationality"
				                    ngDefaultControl></app-input-dropdown>
			</div>
		</div>

		<div class="row">
			<div class="col-6 mb-3">
				<app-input-text name="email" label="Email" [(ngModel)]="artist.email" ngDefaultControl></app-input-text>
			</div>
			<div class="col-6 mb-3">
				<app-input-text name="phone" label="Telephone number" [(ngModel)]="artist.phone" ngDefaultControl></app-input-text>
			</div>
		</div>

		<br/>
		<h5>Kamer van Koophandel</h5>
		<div class="row">
			<div class="col-8 mb-3">
				<app-input-text name="commerceName" label="Company Name" [(ngModel)]="artist.commerceName" ngDefaultControl></app-input-text>
			</div>
			<div class="col-4 mb-3">
				<app-input-text name="commerceId" label="KVK Number" [(ngModel)]="artist.commerceId" ngDefaultControl></app-input-text>
			</div>
		</div>
		<div class="row">
			<div class="col-8 mb-3">
				<app-input-text name="iban" label="IBAN Number" [(ngModel)]="artist.iban" ngDefaultControl></app-input-text>
			</div>
		</div>

		<br/>
		<h5>Address</h5>
		<div class="row">
			<div class="col-8 mb-3">
				<app-input-text name="address.street" label="Street" [(ngModel)]="artist.address.street" ngDefaultControl></app-input-text>
			</div>
			<div class="col-4 mb-3">
				<app-input-text name="address.number" label="Number" [(ngModel)]="artist.address.number" ngDefaultControl></app-input-text>
			</div>
		</div>

		<div class="row">
			<div class="col-4 mb-3">
				<app-input-text name="address.city" label="City" [(ngModel)]="artist.address.city" ngDefaultControl></app-input-text>
			</div>
			<div class="col-4 mb-3">
				<app-input-dropdown name="address.country" label="Country" defaultOption="Select a country" [options]="countries"
				                    [(ngModel)]="artist.address.country" ngDefaultControl>
				</app-input-dropdown>
			</div>
			<div class="col-4 mb-3">
				<app-input-text name="address.zip" label="Zipcode" [(ngModel)]="artist.address.zip" ngDefaultControl></app-input-text>
			</div>
		</div>

		<hr class="mb-4">

		<div class="row">
			<div class="col-4">
				<button class="btn btn-danger btn-lg btn-block" type="submit">Cancel</button>
			</div>
			<div class="offset-4 col-4">
				<button class="btn btn-success btn-lg btn-block" type="submit" (click)="save()">Save</button>
			</div>
		</div>
	</app-form>
</div>
