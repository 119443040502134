<div class="row" *ngIf="inventory">
	<div class="col-12 text-center text-warning" *ngIf="inventory.groups.length == 0">
		<div class="alert alert-warning" role="alert">
			<i class="fa fa-warning"></i> There are no items added to this inventory
		</div>
	</div>
	<div class="col-4 pl-3 pr-3" *ngFor="let group of inventory.groups">
		<div class="card rounded table-responsive mb-4 shadow ">
			<div class="card-header bg-gradient-primary text-light">
				<h3 class="mb-0">{{group.name}}</h3>
			</div>
			<table class="table table-striped table-borderless table-hover table-sm rounded card-body mb-0 shadow-sm">
				<tbody>
				<tr *ngFor="let item of group.items"
				    [class.text-danger]="item.quantity < item.quantityMinimum"
				    [class.text-warning]="item.quantity < item.quantityWarning && item.quantity >= item.quantityMinimum">
					<td class="pl-3">
						<span *ngIf="item.quantity < item.quantityMinimum">
							<i class="fa fa-warning"></i>&nbsp;
						</span>
						{{item.name}}
					</td>
					<td class="text-right">
						<span *ngIf="item.hasProduct">
							{{item.quantityPresale}}
						</span>
					</td>
					<td class="text-right" style="width: 40%">
						{{item.quantity}}
						<span *ngIf="item.quantityDesired > 0"> / {{item.quantityDesired}}</span>

						<div class="btn-group btn-group-sm ml-2">
							<button type="button" class="btn btn-primary btn-sm" (click)="createCorrection(item)">
								<i class="fa fa-wrench"></i>
							</button>
							<div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown" container="body">
								<button type="button" class="btn btn-outline-primary btn-light btn-sm dropdown-toggle-split" ngbDropdownToggle></button>
								<div class="dropdown-menu btn-sm" ngbDropdownMenu>
									<button ngbDropdownItem (click)="editItem(item)">
										<i class="fa fa-edit fa-fw"></i> Edit item limits
									</button>
									<button ngbDropdownItem (click)="createCorrection(item)">
										<i class="fa fa-wrench fa-fw"></i> Apply correction
									</button>
								</div>
							</div>
						</div>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
