import {Component, Input, OnInit} from '@angular/core';
import {AccountTransaction} from '../../../model/domain/account-transaction';

@Component({
	selector: 'app-account-transactions',
	templateUrl: './account-transaction-list-view.component.html',
	styleUrls: ['./account-transaction-list-view.component.scss']
})
export class AccountTransactionListViewComponent implements OnInit {
	@Input() transactions: AccountTransaction[];

	constructor() { }

	ngOnInit(): void {
	}

}
