import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {DomainObject} from '../../../model/domain/domain-object';
import {FilterModel} from '../../../model/filter-model';
import {Paginationmodel} from '../../../model/paginationmodel';
import {TableColumn} from '../table-column-model';
import {TableProjection} from '../table-projection';

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
	@Input() headers: TableColumn[];
	@Input() objects: Observable<Paginationmodel>;

	@Input() size: number;
	@Input() sortBy: string;
	@Input() order: 'ASC' | 'DESC';
	@Input() filterUpdate: Observable<object>;
	@Input() reloadObjects: Observable<any>;
	@Input() defaultFilters: FilterModel;

	@Output() request = new EventEmitter<TableProjection>();
	@Output() objectclick = new EventEmitter<any>();
	@Output() loading = new EventEmitter<boolean>();

	projection: TableProjection;
	items: DomainObject[];
	isLoading = true;

	math = Math;

	constructor(
		private route: Router,
		private activatedRoute: ActivatedRoute) {
	}

	ngOnInit(): void {
		//Read url parameters first
		this.activatedRoute.queryParams.subscribe(params => {
			let page = params.page;
			let sort = params.sort;
			let order = params.order;
			let filters = params.filters;

			if (this.defaultFilters !== undefined) {
				filters = this.mergeFilterArrays(this.getStringArrayFromModel(this.defaultFilters), filters);
			}

			if (page === undefined || page < 1) {
				page = 1;
			}

			if (sort === undefined) {
				if (this.sortBy) {
					sort = this.sortBy;
				} else {
					sort = 'created';
				}
			}

			if (order === undefined) {
				if (this.order) {
					order = this.order;
				} else {
					order = 'DESC';
				}
			}

			this.projection = new TableProjection(page, this.size, sort, order, filters);

			this.objects.subscribe(model => {
				this.isLoading = false;
				this.loading.emit(false);
				this.items = model.results;
				this.projection.total = model.totalItems;
			});

			//Watch for changes in the filters, apply them to the url when changed
			this.filterUpdate.subscribe(model => {
				if (model === null) {
					return;
				}
				this.projection.filters = this.getStringArrayFromModelIgnoreDefaults(model);

				this.route.navigate(
					[],
					{
						relativeTo: this.activatedRoute,
						queryParams: {filters: this.projection.filters},
						queryParamsHandling: 'merge'
					});
			});

			// Prevent angular ExpressionChangedAfterItHasBeenCheckedError
			setTimeout(() => {
				this.isLoading = true;
				this.loading.emit(true);
				this.emitProjection();
			});
		});

		if (this.reloadObjects) {
			this.reloadObjects.subscribe(
				reload => setTimeout(() => {
					this.emitProjection();
				})
			);
		}
	}

	/**
	 * Merge two string arrays containing filters. If a filter is not present in the 'overwrite'
	 * array the default will be added (where available)
	 */
	private mergeFilterArrays(defaults: string[], overwrite: string[]): string[] {
		if (overwrite === undefined) {
			return defaults;
		}
		let result = new Array();

		if (!Array.isArray(overwrite)) {
			const arr = new Array();
			arr.push(overwrite);
			overwrite = arr;
		}

		result = result.concat(overwrite);

		for (const defaultVal of defaults) {
			if (defaultVal !== undefined && defaultVal.length > 0) {
				const split = defaultVal.split(':');
				if (split.length === 2) {
					const key = split[0];
					let inOverwrite = false;
					for (const overwriteVal of overwrite) {
						if (overwriteVal.startsWith(key)) {
							inOverwrite = true;
							break;
						}
					}

					if (!inOverwrite) {
						result.push(defaultVal);
					}
				}
			}
		}


		return result;
	}

	/**
	 * Transform a FilterModel into an array
	 */
	private getStringArrayFromModel(model: FilterModel): string[] {
		const filtersArr = new Array<string>();
		for (const key in model) {
			if (model[key] !== undefined) {
				filtersArr.push(key + ':' + model[key]);
			}
		}
		return filtersArr;
	}

	/**
	 * Transform a FilterModel into an array, removing any filters that are made redundant by the defaults.
	 */
	private getStringArrayFromModelIgnoreDefaults(model: FilterModel): string[] {
		const filtersArr = new Array<string>();
		for (const key in model) {
			if (this.defaultFilters[key] !== undefined && '' + this.defaultFilters[key] === '' + model[key]) {
				continue;
			}
			if (model[key])
				filtersArr.push(key + ':' + model[key]);
		}
		return filtersArr;
	}

	changePage(page: any): void {
		if (this.projection.page === page) {
			return;
		}
		this.projection.page = page;

		this.route.navigate(
			[],
			{
				relativeTo: this.activatedRoute,
				queryParams: {page},
				queryParamsHandling: 'merge'
			});

	}

	setSort(property: string, order?: 'ASC' | 'DESC'): void {
		if (order === undefined) {
			order = 'ASC';
		}

		if (this.projection.sort === property) {
			this.flipDirection();
		} else {
			this.projection.sort = property;
			this.projection.order = order;
		}
		this.projection.page = 1;

		this.route.navigate(
			[],
			{
				relativeTo: this.activatedRoute,
				queryParams: {
					page: this.projection.page,
					sort: this.projection.sort,
					order: this.projection.order
				},
				queryParamsHandling: 'merge'
			});
	}


	/**
	 * Emit the current projection to the parent component. This will fetch a new list of objects
	 */
	private emitProjection(): void {
		const filterModel = new FilterModel();
		this.projection.filters = this.mergeFilterArrays(this.getStringArrayFromModel(this.defaultFilters), this.projection.filters);

		if (this.projection.filters !== undefined && this.projection.filters.length > 0) {
			for (const fil of this.projection.filters) {
				if (fil === undefined) {
					continue;
				}
				const split = fil.split(':');
				filterModel[split[0]] = split[1];
			}
		}
		this.projection.filterObj = filterModel;
		this.request.emit(this.projection);
	}

	private flipDirection(): void {
		if (this.projection.order === 'ASC') {
			this.projection.order = 'DESC';
		} else {
			this.projection.order = 'ASC';
		}
	}

	private click(object: DomainObject): void {
		this.objectclick.emit(object);
	}
}
