import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormComponent} from './form/form.component';
import {InputRadioComponent} from './input-radio/input-radio.component';
import {InputTextComponent} from './input-text/input-text.component';
import {FormsModule} from '@angular/forms';
import {NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import {InputDropdownComponent} from './input-dropdown/input-dropdown.component';
import { InputCheckboxComponent } from './input-checkbox/input-checkbox.component';


@NgModule({
	declarations: [FormComponent, InputTextComponent, InputDropdownComponent, InputRadioComponent, InputCheckboxComponent],
	exports: [
		FormComponent,
		InputTextComponent,
		InputDropdownComponent,
		InputRadioComponent,
		InputCheckboxComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		NgbAlertModule
	]
})
export class FormControllerModule {
}
