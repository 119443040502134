export class Listrequest {
	startDate: number;
	endDate: number;

	constructor(
		public page: number,
		public itemsPerPage: number,
		public sort: string,
		public sortDirection: string,
		public filters: string[]
	) {
		if (this.filters === undefined) {
			this.filters = new Array();
		}
	}

	addFilter(field: string, value: any): void {
		// Remove current filter if already exists
		let i = this.filters.length;
		while (i--) {
			if (this.filters[i].startsWith(field)) {
				this.filters.splice(i, 1);
			}
		}
		if (value !== '') {
			this.filters.push(field + ':' + value);
		}
	}
}
