import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgbDate, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';

import {ModalService} from '../../../core/service/modalservice';
import {OrderService} from '../../../core/service/order.service';
import {StudioService} from '../../../core/service/studio.service';
import {DateUtil} from '../../../core/util/date.util';
import {Studio} from '../../../model/domain/studio';
import {OrderAddDiscountComponent} from './order-add-discount/order-add-discount.component';
import {OrderAddPaymentComponent} from './order-add-payment/order-add-payment.component';
import {OrderAddPiercingComponent} from './order-add-piercing/order-add-piercing.component';
import {OrderAddProductComponent} from './order-add-product/order-add-product.component';
import {OrderAddTattooComponent} from './order-add-tattoo/order-add-tattoo.component';

@Component({
	selector: 'app-order-new',
	templateUrl: './order-new.component.html',
	styleUrls: ['./order-new.component.scss']
})
export class OrderNewComponent implements OnInit {
	studios: Studio[];
	date: NgbDate;
	time: NgbTimeStruct;
	studio: Studio;
	items = new Array();
	transactions = new Array();
	loading = false;

	constructor(
		private modalService: ModalService,
		private studioService: StudioService,
		private orderService: OrderService,
		private router: Router,
	) {
		this.time = {
			hour: 12,
			minute: 0,
			second: 0
		};
	}

	ngOnInit(): void {
		this.studioService.getAll().subscribe(studios => this.studios = studios);
	}

	save(): void {
		this.loading = true;
		const transactions = new Array();
		const items = new Array();

		this.transactions.forEach(transaction => transactions.push({method: transaction.method, amount: transaction.amount}));

		this.items.forEach(item => {
			if (item.type === 'piercing') {
				items.push({
					type: 'PIERCING',
					id: item.piercing.piercing.id,
					quantity: item.quantity,
					artist: item.piercing.artist.id
				});
			} else if (item.type === 'tattoo') {
				items.push({
					type: 'TATTOO',
					price: item.price,
					artist: item.tattoo.artist.id
				});
			} else if (item.type === 'product') {
				items.push({
					type: 'PRODUCT',
					id: item.product.id,
					quantity: item.quantity,
				});
			}
		});

		const order = {
			date: DateUtil.getTimestampFromDateTime(this.date, this.time),
			studio: this.studio,
			transactions,
			items
		};

		this.orderService.newOrder(order).subscribe(value => {
				if (value === undefined || value === null || isNaN(value.id)) {
					this.loading = false;
					return;
				}
				this.router.navigateByUrl(`/order/${value.id}`);
			}
		);
	}

	canSave(): boolean {
		if (this.loading) {
			return false;
		}

		if (this.items.length === 0 || this.transactions.length === 0) {
			return false;
		}
		if (this.getOrderTotal() !== this.getTransactionTotal()) {
			return false;
		}
		if (this.studio === undefined) {
			return false;
		}
		if (this.date === undefined || this.time === undefined) {
			return false;
		}
		return true;
	}

	addProduct(): void {
		const modelRef = this.modalService.open(OrderAddProductComponent, {size: 'lg'});
		modelRef.result.then(val => {
			if (val !== undefined) {
				this.items.push({
					name: val.name,
					quantity: 1,
					price: val.price,
					type: 'product',
					product: val
				});
			}
		}).catch((res) => {});
	}

	addTattoo(): void {
		const modelRef = this.modalService.open(OrderAddTattooComponent, {size: 'lg'});
		modelRef.result.then(val => {
			this.items.push({
				name: 'Tattoo by ' + val.artist.nickname,
				quantity: 1,
				price: val.price,
				tattoo: val,
				type: 'tattoo'
			});
		}).catch((res) => {});
	}

	addPiercing(): void {
		const modelRef = this.modalService.open(OrderAddPiercingComponent, {size: 'lg'});

		modelRef.result.then(val => {
			this.items.push({
				name: val.piercing.name + ' by ' + val.artist.nickname,
				quantity: val.quantity,
				price: val.piercing.price,
				piercing: val,
				type: 'piercing'
			});
		}).catch((res) => {});
	}

	addPayment(): void {
		const modelRef = this.modalService.open(OrderAddPaymentComponent);

		if (this.getOrderTotal() > 0 && this.getTransactionTotal() < this.getOrderTotal()) {
			modelRef.componentInstance.amount = (this.getOrderTotal() - this.getTransactionTotal()) as number;
		}
		modelRef.result.then(val => {
			if (val !== undefined) {
				this.transactions.push(val);
			}
		}).catch((res) => {});
	}

	addDiscount(): void {
		const modelRef = this.modalService.open(OrderAddDiscountComponent);

		if (this.getOrderTotal() > 0 && this.getTransactionTotal() > 0 && this.getTransactionTotal() < this.getOrderTotal()) {
			modelRef.componentInstance.amount = (this.getOrderTotal() - this.getTransactionTotal()) as number;
		}
		modelRef.result.then(val => {
			if (val !== undefined) {
				this.transactions.push(val);
			}
		}).catch((res) => {});
	}

	removeItem(item: object): boolean {
		const index: number = this.items.indexOf(item);
		if (index !== -1) {
			this.items.splice(index, 1);
		}

		return false;
	}

	removeTransaction(transaction: any): boolean {
		const index: number = this.transactions.indexOf(transaction);
		if (index !== -1) {
			this.transactions.splice(index, 1);
		}

		return false;
	}

	increaseQuantity(item): boolean {
		item.quantity++;
		return false;
	}

	decreaseQuantity(item): boolean {
		if (item.quantity === 1) {
			this.removeItem(item);
		} else {
			item.quantity--;
		}
		return false;
	}

	getOrderTotal(): number {
		let total = 0;
		this.items.forEach(item => total += item.quantity * item.price);
		return total;
	}

	getTransactionTotal(): number {
		let total = 0;
		if (this.transactions.length === 0) {
			return 0;
		}

		this.transactions.forEach(trans => total += Number(trans.amount));
		return total;
	}

}
