import {Component, OnInit} from '@angular/core';
import {ConfirmDialogService} from '../../../component/confirmation-dialog/confirm-dialog.service';
import {Order} from '../../../model/domain/order';
import {ActivatedRoute} from '@angular/router';
import {ModalService} from '../../../core/service/modalservice';
import {OrderService} from '../../../core/service/order.service';
import {OrderItem} from '../../../model/domain/order-item';
import {Transaction} from '../../../model/domain/transaction';
import {OrderCancelComponent} from './order-cancel/order-cancel.component';

@Component({
	selector: 'app-order-view',
	templateUrl: './order-view.component.html',
	styleUrls: ['./order-view.component.scss']
})
export class OrderViewComponent implements OnInit {
	order: Order;
	items: OrderItem[];
	transactions: Transaction[];

	constructor(
		private route: ActivatedRoute,
		private orderService: OrderService,
		private confirmDialogService: ConfirmDialogService,
		private modalService: ModalService
	) {}

	ngOnInit(): void {
		this.order = this.route.snapshot.data.order as Order;
		this.getOrderInfo();
	}

	getOrderInfo(): void {
		this.orderService.getItems(this.order.id).subscribe(
			results => this.items = results
		);

		this.orderService.getTransactions(this.order.id).subscribe(
			results => {
				this.transactions = results;
			}
		);
	}

	cancelOrder(): void {
		this.confirmDialogService
			.confirm('Are you sure you want to cancel this order? This will undo all payments & artist payments')
			.then(value => {
					if (value === true) {
						this.orderService.cancelOrder(this.order).subscribe(order => {
							if (order) {
								this.order = order;
								this.getOrderInfo();
							}
						});
					}
				}
			);
	}

	refundOrder(): void {
		const modelRef = this.modalService.open(OrderCancelComponent, {size: 'lg'});
		modelRef.componentInstance.setOrder(this.order, this.items, this.transactions);

		modelRef.result.then(value => {
			if (value) {
				this.order = value;
				this.getOrderInfo();
			}
		}, reason => {});
	}
}
