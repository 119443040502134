import {Component, OnInit} from '@angular/core';
import {InventoryService} from '../../../core/service/inventory.service';
import {Inventory} from '../../../model/inventory/inventory';
import {InventoryGroup} from '../../../model/inventory/inventory-group';
import {InventoryTransferGroupModel, InventoryTransferItemModel, InventoryTransferRequest, InventoryTransferRequestItem} from './inventory-transfer-item-model';
import {Router} from '@angular/router';

@Component({
	selector: 'app-inventory-transfer',
	templateUrl: './inventory-transfer.component.html',
	styleUrls: ['./inventory-transfer.component.scss']
})
export class InventoryTransferComponent implements OnInit {
	loading = false;

	inventories: Inventory[];
	sourceInventory: Inventory;
	targetInventory: Inventory;

	modelGroups: InventoryTransferGroupModel[];
	description: string;

	constructor(
		private router: Router,
		private inventoryService: InventoryService
	) {}

	ngOnInit(): void {
		this.inventoryService.getAll().subscribe(results => this.inventories = results);
		this.changeSource();
	}

	changeSource(): void {
		// Save current request to fill in when new source is selected so user doesn't lose all their entered data when changing sources
		const request = this.createTransferRequest();

		if (this.sourceInventory !== undefined) {
			this.inventoryService.getById(this.sourceInventory.id).subscribe(
				result => this.modelGroups = this.convertInventoryToModel(result.groups, request)
			);
		} else {
			this.inventoryService.getAllObjects().subscribe(
				result => this.modelGroups = this.convertInventoryToModel(result, request)
			);
		}
	}

	convertInventoryToModel(groups: InventoryGroup[], request: InventoryTransferRequest): InventoryTransferGroupModel[] {
		const results: InventoryTransferGroupModel[] = new Array();

		for (const group of groups) {
			const groupModel: InventoryTransferGroupModel = {
				name: group.name,
				items: new Array()
			};

			for (const item of group.items) {
				let reqValue = this.getRequestValue(request, item.objectId);
				if (this.sourceInventory) {
					reqValue = Math.min(reqValue, item.quantity);
				}
				groupModel.items.push({
					id: item.objectId,
					name: item.name,
					quantity: item.quantity,
					transfer: reqValue,
					selected: reqValue ? true : false
				});
			}

			results.push(groupModel);
		}

		return results;
	}

	getRequestValue(request: InventoryTransferRequest, id: number): number {
		if (!request) {
			return undefined;
		}

		for (const item of request.items) {
			if (id === item.objectId) {
				return item.quantity;
			}
		}
		return undefined;
	}

	inputChanged(item: InventoryTransferItemModel): void {
		if (item.quantity === undefined || item.transfer <= 0) {
			item.selected = false;
			item.transfer = undefined;
		} else if (item.transfer !== undefined && item.transfer > 0) {
			item.selected = true;
			if (this.sourceInventory) {
				item.transfer = Math.min(item.transfer, item.quantity);
			}
		}
	}

	focusin(item: InventoryTransferItemModel): void {
		item.selected = true;
	}

	focusout(item: InventoryTransferItemModel): void {
		if (!item.transfer || item.transfer <= 0) {
			item.selected = false;
			item.transfer = undefined;
		} else {
			if (this.sourceInventory) {
				item.transfer = Math.min(item.transfer, item.quantity);
			}
		}
	}

	selectCheckbox(item: InventoryTransferItemModel): void {
		item.selected = !item.selected;
		if (!item.selected) {
			item.transfer = undefined;
		}
	}

	canSubmit(): boolean {
		if (this.loading) {
			return false;
		}
		if (this.sourceInventory === this.targetInventory) {
			return false;
		}
		let selected = false;
		for (const group of this.modelGroups) {
			for (const item of group.items) {
				if (item.selected && item.transfer > 0) {
					selected = true;
					break;
				}
			}
			if (selected) {
				break;
			}
		}

		if (!selected) {
			return false;
		}

		return true;
	}

	submitRequest(): void {
		this.loading = true;
		const request = this.createTransferRequest();
		this.inventoryService.transferItems(request).subscribe(result => {
			let id = 0;
			if (this.targetInventory) {
				id = this.targetInventory.id;
			} else if (this.sourceInventory) {
				id = this.sourceInventory.id;
			}
			if (id !== 0) {
				this.router.navigateByUrl(`/inventory/view/${id}`);
			} else {
				this.router.navigateByUrl(`/inventory/view`);
			}
		});
	}

	createTransferRequest(): InventoryTransferRequest {
		const request: InventoryTransferRequest = {
			sourceId: this.sourceInventory ? this.sourceInventory.id : undefined,
			targetId: this.targetInventory ? this.targetInventory.id : undefined,
			description: this.description,
			items: new Array()
		};

		if (!this.modelGroups) {
			return request;
		}

		for (const group of this.modelGroups) {
			for (const item of group.items) {
				if (!item.selected || item.transfer === undefined || item.transfer <= 0) {
					continue;
				}

				request.items.push({
					objectId: item.id,
					quantity: item.transfer
				} as InventoryTransferRequestItem);
			}
		}

		return request;
	}
}
