<div class="modal-header">
	<h4 class="modal-title"><span class="fa fa-wrench mr-2"></span> Apply correction</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" tabindex="-1">
		&times;
	</button>
</div>

<div class="modal-body d-flex justify-content-center text-lg-left">
	<div class="row text-lg" style="font-size: 110%">
		<div class="offset-2 col-6 mb-2">
			Current quantity
		</div>
		<div class="col-2 mb-2 text-right"
		     [class.text-danger]="item.quantity < item.quantityMinimum"
		     [class.text-warning]="item.quantity < item.quantityWarning && item.quantity >= item.quantityMinimum">
			<strong>{{item.quantity}}</strong> <span *ngIf="item.quantityDesired > 0"> / {{item.quantityDesired}}</span>
		</div>
		<div class="offset-2 col-6 mb-3">
			New quantity
		</div>
		<div class="col-2 mb-3">
			<input type="text" class="form-control form-control" [(ngModel)]="form.newValue">
		</div>
		<div class="offset-2 col-8 mb-2">
			<input type="text" class="form-control form-control" [(ngModel)]="form.description" placeholder="Please enter a reason for this correction">
		</div>
	</div>
</div>


<div class="modal-footer">
	<button type="button" class="btn btn-danger mr-auto" (click)="activeModal.dismiss('Close click')">Cancel</button>
	<label class="mr-auto"></label>
	<button type="button" class="btn btn-success" [disabled]="!canSave()" (click)="save()">Save
	</button>
</div>
