import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ArtistService} from '../../../../core/service/artist.service';
import {ArtistViewService} from '../artist.view.service';
import {Artist} from '../../../../model/domain/artist';

@Component({
    selector: 'app-artist-status',
    templateUrl: './artist-status.component.html',
    styleUrls: ['./artist-status.component.scss']
})
export class ArtistStatusComponent implements OnInit {
    artist: Artist;
    validation: string[];

    constructor(
        private route: ActivatedRoute,
        private artistService: ArtistService,
        private artistViewService: ArtistViewService,
    ) {}

    ngOnInit(): void {
        this.artistViewService.get().subscribe(artist => {
            this.artist = artist;

            if (!this.artist.active) {
                this.artistService.isValid(this.artist).subscribe(
                    validations => {this.validation = validations}
                );
            }
        });
    }

    disable(): void {
        this.artistService.disableArtist(this.artist).subscribe(
            artist => {
                this.artistViewService.update(artist);
                this.artist = artist;

                if (!this.artist.active) {
                    this.artistService.isValid(this.artist).subscribe(
                        validations => {
                            this.validation = validations;
                        }
                    );
                }
            }
        );
    }

    enable(): void {
        this.artistService.enableArtist(this.artist).subscribe(
            artist => {
                this.artist = artist;
                this.artistViewService.update(artist);

                if (!this.artist.active) {
                    this.artistService.isValid(this.artist).subscribe(
                        validations => {
                            this.validation = validations;
                        }
                    );
                }
            }
        );
    }
}
