import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-order-add-discount',
	templateUrl: './order-add-discount.component.html',
	styleUrls: ['./order-add-discount.component.scss']
})
export class OrderAddDiscountComponent implements OnInit {
	amount: number;

	constructor(
		public activeModal: NgbActiveModal
	) {}

	ngOnInit(): void {
	}

	save(): void {
		this.activeModal.close({
			amount: Number(this.amount),
			method: 'discount',
			title: 'Discount'
		});
	}

	canSave(): boolean {
		return !isNaN(this.amount) && this.amount > 0;
	}
}
