import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Tattoo} from '../../../model/domain/tattoo';
import {ModalService} from '../../../core/service/modalservice';
import {ModifyArtistTattooComponent} from './modify-artist-tattoo/modify-artist-tattoo.component';
import {ModifyShareTattooComponent} from './modify-share-tattoo/modify-share-tattoo.component';
import {ModifyStudioTattooComponent} from "./modify-studio-tattoo/modify-studio-tattoo.component";

@Component({
	selector: 'app-tattoo-view',
	templateUrl: './tattoo-view.component.html',
	styleUrls: ['./tattoo-view.component.scss']
})
export class TattooViewComponent implements OnInit {
	tattoo: Tattoo;

	constructor(
		private route: ActivatedRoute,
		private modalService: ModalService
	) {
	}

	ngOnInit(): void {
		this.tattoo = this.route.snapshot.data.tattoo as Tattoo;
	}

	getRate(appointment: Tattoo): string {
		if (appointment.transactions.length > 2) {
			return 'SPLIT';
		} else if (appointment.transactions.length > 0) {
			return appointment.transactions[0].rate;
		}
		return 'unknown';
	}

	changeArtist(): void {
		const modelRef = this.modalService.open(ModifyArtistTattooComponent);
		modelRef.componentInstance.setTattoo(this.tattoo);

		modelRef.result.then(value => {
			if (value !== undefined) {
				this.tattoo = value;
			}
		}, reason => {
		});
	}

	changeStudio(): void {
		const modelRef = this.modalService.open(ModifyStudioTattooComponent);
		modelRef.componentInstance.setTattoo(this.tattoo);

		modelRef.result.then(value => {
			if (value !== undefined) {
				this.tattoo = value;
			}
		}, reason => {
		});
	}


	changePercentage(): void {
		const modelRef = this.modalService.open(ModifyShareTattooComponent);
		modelRef.componentInstance.setTattoo(this.tattoo, this.getRate(this.tattoo));


		modelRef.result.then(value => {
			if (value !== undefined) {
				this.tattoo = value;
			}
		}, reason => {
		});
	}
}
