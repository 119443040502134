<div *ngIf="report">
	<div class="row">
		<div class="col-4 text-center offset-4">
			<h4>Earning report</h4>
		</div>
		<div class="col-4">
			<div class="row justify-content-end">
				<div class="col-auto">
					<form [formGroup]="form" class="form-inline">
						<div class="form-group">
							<!--					<label for="monthselect" class="form-control-lg">Month</label>-->
							<select id="monthselect" class="form-control" formControlName="month">
								<option *ngFor="let month of months" [value]="month">{{month}}</option>
							</select>
						</div>
						<div class="form-group ml-3">
							<!--					<label for="monthselect" class="form-control-lg">Year</label>-->
							<select id="yearselect" class="form-control" formControlName="year">
								<option *ngFor="let year of years" [value]="year">{{year}}</option>
							</select>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	<hr/>

	<div class="text-center text-danger" *ngIf="report.workedDays.length === 0">
		No transactions found for this month.
	</div>
	<div *ngIf="report.workedDays.length > 0">
		<h4 class="text-center">{{report.startDate | formatdate}} - {{report.endDate | formatdate}}</h4>
		<h5 class="m-1 mb-3 text-muted">Daily specification</h5>

		<table class="table table-borderless table-sm text-right">
			<tr>
				<th class="text-left" style="width: 15%">
					Date
				</th>
				<th class="text-left" style="width: 20%">
					Studio
				</th>
				<th class="" style="width: 15%">
					Customers
				</th>
				<th style="width: 15%">
					Income
				</th>
				<th style="width: 10%">
					Tax
				</th>
				<th style="width: 15%">
					Compensation
				</th>
				<th style="width: 10%">
					Total
				</th>
			</tr>
			<tr *ngFor="let day of report.workedDays" class="">
				<td class="text-left text-monospace">{{day.date | formatdate}}</td>
				<td class="text-left"><span *ngIf="day.studio">{{day.studio.name}}</span></td>
				<td class="text-monospace">{{day.customers}}</td>
				<td class="text-monospace">{{day.mainEarning | currency:"EUR"}}</td>
				<td class="text-monospace">{{day.btwEarning | currency:"EUR"}}</td>
				<td class="text-monospace">{{day.compEarning | currency:"EUR"}}</td>
				<td class="text-monospace">{{day.mainEarning + day.btwEarning + day.compEarning | currency:"EUR"}}</td>
			</tr>
		</table>

		<!--- Corrections -->
		<div *ngIf="report.corrections.length > 0">
			<h5 class="m-1 mb-3 text-muted">Corrections</h5>
			<table class="table table-borderless table-sm  text-right">
				<tr>
					<th class="text-left" style="width: 15%">
						Date
					</th>
					<th class="text-left" style="width: 20%">
						Description
					</th>
					<th class="text-center" style="width: 15%">
						&nbsp;
					</th>
					<th style="width: 15%">
						Income
					</th>
					<th style="width: 10%">
						Tax
					</th>
					<th style="width: 15%">
						Compensation
					</th>
					<th style="width: 10%">
						&nbsp;
					</th>
				</tr>
				<tr *ngFor="let correction of report.corrections">
					<td class="text-left text-monospace">{{correction.date | formatdate}}</td>
					<td class="text-left">{{correction.description}}</td>
					<td class="text-center">&nbsp;</td>
					<td class="text-monospace"><span *ngIf="correction.account==='Main'">{{correction.amount | currency:"EUR"}}</span></td>
					<td class="text-monospace"><span *ngIf="correction.account==='BTW'">{{correction.amount | currency:"EUR"}}</span></td>
					<td class="text-monospace"><span *ngIf="correction.account==='Comp'">{{correction.amount | currency:"EUR"}}</span></td>
					<td>&nbsp;</td>
				</tr>
			</table>
		</div>
		<!--- Totals -->
		<table class="table table-borderless table-sm  text-right">
			<tr>
				<th class="text-left" style="width: 15%">
					&nbsp;
				</th>
				<th class="text-left" style="width: 20%">
					&nbsp;
				</th>
				<th class="text-center" style="width: 15%">
					&nbsp;
				</th>
				<th style="width: 15%">
					Income
				</th>
				<th style="width: 10%">
					Tax
				</th>
				<th style="width: 15%">
					Compensation
				</th>
				<th style="width: 10%">
					Total
				</th>
			</tr>
			<tr>
				<td>&nbsp;</td>
				<td>&nbsp;</td>
				<td class=""><strong>Total</strong></td>
				<td class="text-monospace">{{report.earnedMain | currency:"EUR"}}</td>
				<td class="text-monospace">{{report.earnedBtw | currency:"EUR"}}</td>
				<td class="text-monospace">{{report.earnedComp | currency:"EUR"}}</td>
				<td class="text-monospace">{{report.earnedComp + report.earnedMain + report.earnedBtw | currency:"EUR"}}</td>
			</tr>
		</table>
	</div>
</div>
