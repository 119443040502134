import {Component, Input} from '@angular/core';
import {HttpEventType} from '@angular/common/http';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DocumentService} from '../../../../core/service/document.service';
import {FileHandle} from '../../../../component/filedrop.directive';
import {Artist} from '../../../../model/domain/artist';

@Component({
	selector: 'app-artist-document-add',
	templateUrl: './artist-document-add.component.html',
	styleUrls: ['./artist-document-add.component.scss']
})
export class ArtistDocumentAddComponent {
	@Input() artist: Artist;
	complete = false;
	status: string;
	file: File;
	name = '';
	comments = '';

	constructor(
		private documentService: DocumentService,
		public activeModal: NgbActiveModal
	) {}

	addFile($event: FileHandle): void {
		this.status = 'Ready to upload';
		this.file = $event.file;
		this.name = $event.file.name;
		this.name = this.name.replace(this.name.substr(this.name.lastIndexOf('.')), '');
	}

	startUpload(): void {
		if (this.name.length < 2) {
			status = 'Invalid name: needs to be at least 3 characters';
			return;
		}

		this.documentService.upload(this.artist, this.file, this.name, this.comments).subscribe(event => {
			this.status = 'Uploading';
			if (event.type === HttpEventType.DownloadProgress) {
				this.status = 'Uploading ' + String(100 * event.loaded / event.total) + '%';
			}
			if (event.type === HttpEventType.Response) {
				this.status = 'Complete';
				this.complete = true;
				this.activeModal.close(event.body);
			}
		});
	}

}
