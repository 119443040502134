import {CurrencyPipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Order} from '../../../model/domain/order';
import {EnumTypePipe} from '../../../core/pipe/enum-type.pipe';
import {FormatDateTimePipe} from '../../../core/pipe/formatdatetime.pipe';
import {IdPipe} from '../../../core/pipe/id.pipe';
import {StudioPipe} from '../../../core/pipe/studio.pipe';
import {UsernamePipe} from '../../../core/pipe/username.pipe';
import {OrderService} from '../../../core/service/order.service';
import {AbstractTableViewComponent} from '../../../component/table-view/abstract-table-view-component';
import {TableColumn} from '../../../component/table-view/table-column-model';
import {TableProjection} from '../../../component/table-view/table-projection';

@Component({
	selector: 'app-order-list',
	templateUrl: './order-list.component.html',
	styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent extends AbstractTableViewComponent<Order> implements OnInit {
	constructor(
		private route: Router,
		private orderService: OrderService,
	) {
		super();
		this.defaultFilters.studio = 'any';
		this.defaultFilters.status = 'closed';
	}

	ngOnInit(): void {
		this.headers.push(new TableColumn('ID', 'id', 5, new IdPipe()));
		this.headers.push(new TableColumn('Date', 'created', 15, new FormatDateTimePipe()));
		this.headers.push(new TableColumn('Status', 'status', 10, new EnumTypePipe()));
		this.headers.push(new TableColumn('Studio', 'studio', 25, new StudioPipe()));
		this.headers.push(new TableColumn('Manager', 'owner', 25, new UsernamePipe()));
		this.headers.push(new TableColumn('Total price', 'totalPrice', 15, new CurrencyPipe('nl', 'EUR')));
	}

	getObjects(projection: TableProjection): void {
		this.filters = projection.filterObj;
		const request = projection.getAsListRequest();
		this.orderService.getAllOrders(request).subscribe(
			value => {
				this.objects.next(value);
			}
		);
	}

	onObjectSelect(order: Order): void {
		this.route.navigate([`/order/${order.id}`]);
	}
}
