import {CurrencyPipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Studio} from '../../../model/domain/studio';
import {Transaction} from '../../../model/domain/transaction';
import {EnumTypePipe} from '../../../core/pipe/enum-type.pipe';
import {FormatDateTimePipe} from '../../../core/pipe/formatdatetime.pipe';
import {OrderValuePipe} from '../../../core/pipe/order-value.pipe';
import {OrderPipe} from '../../../core/pipe/order.pipe';
import {StudioPipe} from '../../../core/pipe/studio.pipe';
import {TransactionDetailsPipe} from '../../../core/pipe/transaction-details.pipe';
import {UsernamePipe} from '../../../core/pipe/username.pipe';
import {TransactionService} from '../../../core/service/transaction.service';
import {AbstractTableViewComponent} from '../../../component/table-view/abstract-table-view-component';
import {TableColumn} from '../../../component/table-view/table-column-model';
import {TableProjection} from '../../../component/table-view/table-projection';

@Component({
	selector: 'app-order-transaction-list',
	templateUrl: './order-transaction-list.component.html',
	styleUrls: ['./order-transaction-list.component.scss']
})
export class OrderTransactionListComponent extends AbstractTableViewComponent<Transaction> implements OnInit {
	studios: Studio[];

	constructor(
		private route: Router,
		private transactionService: TransactionService,
	) {
		super();
		this.defaultFilters.studio = 'any';
		this.defaultFilters.method = 'any';
	}

	ngOnInit(): void {
		this.headers.push(new TableColumn('Date', 'created', 15, new FormatDateTimePipe()));
		this.headers.push(new TableColumn('Order', 'order', 5, new OrderPipe()));
		this.headers.push(new TableColumn('Type', 'method', 10, new EnumTypePipe()));
		this.headers.push(new TableColumn('Studio', 'studio', 15, new StudioPipe()));
		this.headers.push(new TableColumn('User', 'user', 15, new UsernamePipe()));
		this.headers.push(new TableColumn('Description', 'comments', 20, new TransactionDetailsPipe()));
		this.headers.push(new TableColumn('Value', 'amount', 10, new CurrencyPipe('nl', 'EUR')));
		this.headers.push(new TableColumn('Order total', 'order', 15, new OrderValuePipe()));
	}

	getObjects(projection: TableProjection): void {
		this.filters = projection.filterObj;
		const request = projection.getAsListRequest();
		this.transactionService.getAllTransactions(request).subscribe(
			value => {
				this.objects.next(value);
			}
		);
	}

	onObjectSelect(order: Transaction): void {
	}
}
