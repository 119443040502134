import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ProductGroup} from '../../model/domain/product-group';

@Injectable({
	providedIn: 'root'
})
export class ProductGroupService {

	private url = 'api/product/groups/';
	private urlAll = this.url + 'all';

	constructor(
		private http: HttpClient
	) {}

	getAll(): Observable<ProductGroup[]> {
		return this.http.get<ProductGroup[]>(this.urlAll);
	}

	getById(id: number): Observable<ProductGroup> {
		return this.http.get<ProductGroup>(this.url + id + '/');
	}
}
