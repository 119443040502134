<app-page name="Inventory management">
	<div class="card-header" *ngIf="inventories !== undefined && inventories.length > 0">
		<ul class="nav nav-tabs card-header-tabs">
			<li class="nav-item" *ngFor="let inventory of inventories">
				<a class="nav-link" [routerLink]="'/inventory/view/' + inventory.id" [class.active]="selectedId == inventory.id">{{inventory.name}}</a>
			</li>
		</ul>
	</div>

	<div class="card-body pl-0 pr-0" *ngIf="selected">
		<app-inventory [inventory]="selected" [onChange]="transactionUpdater"></app-inventory>
	</div>
</app-page>

<app-page name="Recent transactions" *ngIf="selected">
	<app-inventory-view-transactions [inventory]="selected" [update]="transactionUpdater"></app-inventory-view-transactions>
</app-page>
