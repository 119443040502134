import {Pipe, PipeTransform, Injector, Type} from '@angular/core';

@Pipe({
	name: 'studioPipe'
})
export class StudioPipe implements PipeTransform {
	constructor() {}

	transform(value: any): any {
		if (value === undefined || value === null) {
			return '';
		}

		return value.name;
	}

}
