import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ArtistnamePipe} from './artistname.pipe';
import {AuthImagePipe} from './auth-image.pipe';
import {DynamicPipe} from './dynamic.pipe';
import {FormatDatePipe} from './formatdate.pipe';
import {FormatDateTimePipe} from './formatdatetime.pipe';
import {FormatTimePipe} from './formattime.pipe';
import {IdPipe} from './id.pipe';
import {OrderItemNamePipe} from './order-item-name.pipe';
import {OrderValuePipe} from './order-value.pipe';
import {OrderPipe} from './order.pipe';
import {StudioPipe} from './studio.pipe';
import {TransactionDescPipe} from './transaction-desc.pipe';
import {UsernamePipe} from './username.pipe';


@NgModule({
	declarations: [
		DynamicPipe,
		FormatDateTimePipe,
		FormatDatePipe,
		FormatTimePipe,
		ArtistnamePipe,
		TransactionDescPipe,
		IdPipe,
		OrderPipe,
		UsernamePipe,
		TransactionDescPipe,
		StudioPipe,
		OrderValuePipe,
		OrderItemNamePipe,
		AuthImagePipe
	],
	imports: [
		CommonModule
	],
	exports: [
		DynamicPipe,
		FormatDateTimePipe,
		ArtistnamePipe,
		FormatDatePipe,
		TransactionDescPipe,
		IdPipe,
		OrderPipe,
		UsernamePipe,
		TransactionDescPipe,
		StudioPipe,
		OrderValuePipe,
		OrderItemNamePipe,
		AuthImagePipe,
		FormatTimePipe
	]
})
export class PipeModule {

}
