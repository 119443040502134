import {CurrencyPipe} from '@angular/common';
import {Pipe, PipeTransform, Injector, Type} from '@angular/core';

@Pipe({
	name: 'orderValuePipe'
})
export class OrderValuePipe implements PipeTransform {

	constructor() {}

	transform(value: any): any {
		if (!value) {
			return '';
		}

		let price = value;
		if (value.totalPrice) {
			price = value.totalPrice;
		}


		return new CurrencyPipe('NL-nl', 'EUR').transform(price);
	}

}
