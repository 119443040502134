import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Piercing} from '../../../../model/domain/piercing';
import {PiercingService} from '../../../../core/service/piercing.service';

@Component({
	selector: 'app-piercing-edit',
	templateUrl: './piercing-edit.component.html',
	styleUrls: ['./piercing-edit.component.scss']
})
export class PiercingEditComponent implements OnInit {

	loading: boolean;
	piercing: Piercing;
	form: Piercing;

	constructor(
		public activeModal: NgbActiveModal,
		private piercingService: PiercingService
	) {}

	ngOnInit(): void {
	}

	canSave(): boolean {
		if (this.loading) {
			return false;
		}

		return true;
	}

	save(): void {
		this.loading = true;
		this.piercingService.editPiercing(this.form).subscribe(
			result => {
				this.activeModal.close(result);
			}
		);
	}

	setPiercing(piercing: Piercing): void {
		this.piercing = piercing;
		this.form = JSON.parse(JSON.stringify(piercing));
	}

}
