<div *ngIf="(!artist.active && validation) || artist.active">
	<div *ngIf="artist.accounts.length > 0">
		<app-artist-accounts></app-artist-accounts>
		<hr class="mt-4" *ngIf="artist.accounts.length > 0"/>
	</div>

	<div *ngIf="artist.active" class="text-success text-center" style="font-size: larger">
		<button class="btn btn-danger" (click)="disable()"><span class="fa fa-times-circle mr-2"></span> Disable artist</button>
	</div>
	<div *ngIf="!artist.active">
		<div *ngIf="validation.length > 0">
			<div>
				<b>It's currently not possible to enable the artist, please fix these issues:</b><br/>
				<ul>
					<li *ngFor="let reason of validation" class="text-danger">{{reason}}<br/></li>
				</ul>
			</div>
		</div>
		<div *ngIf="validation.length == 0" class="text-center">
			<button class="btn btn-success" (click)="enable()"><span class="fa fa-check-circle mr-2"></span>Activate artist</button>
		</div>
	</div>
</div>
