import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {User} from '../../../model/domain/user';
import {UserService} from '../../../core/service/user.service';


@Injectable()
export class UserResolver implements Resolve<Observable<User>> {
	constructor(
		private userService: UserService
	) {}

	resolve(route: ActivatedRouteSnapshot): Observable<User> {
		const id: number = +route.paramMap.get('id');
		return this.userService.getById(id);
	}
}
