<div class="card mt-3 shadow"  *ngIf="user" >
	<div class="rounded card">
		<div class="card-header bg-gradient-secondary text-white">
			<h2 class="card-title text-center">User {{user.name}}</h2>
		</div>
		<div class="card-body">
			<br/>

			<div class="row">
				<div class="col-7 offset-1 text-left">
					<b>{{user.name}} </b><br/>
					<a href="mailto:{{user.email}}">{{user.email}}</a> <br/>
				</div>
				<div class="col-3 text-right">
					Accesslevel {{user.access | titlecase}}
					<br/>

					<span *ngIf="user.active" class="text-success">
						<span class="fa fa-check-circle"></span> Active
					</span>
					<span *ngIf="!user.active" class="text-danger">
						<span class="fa fa-times-circle"></span> Inactive
					</span>

				</div>
			</div>
		</div>
	</div>
	<div class="card-header">
		<ul class="nav nav-tabs card-header-tabs">
			<li class="nav-item">
				<a class="nav-link" routerLink="overview" routerLinkActive="active">Overview</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" routerLink="edit" routerLinkActive="active">Edit user</a>
			</li>
		</ul>
	</div>

	<div class="card-body">
		<router-outlet></router-outlet>
	</div>
</div>
