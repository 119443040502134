import {Pipe, PipeTransform, Injector, Type} from '@angular/core';

@Pipe({
	name: 'booleanLightPipe'
})
export class BooleanLightPipe implements PipeTransform {
	constructor() {}

	transform(value: any): any {
		let val = false;
		if (value === true) {
			val = true;
		}

		if (val) {
			return '<span class="fa fa-check-circle text-success"></span>';
		} else {
			return '<span class="fa fa-times-circle text-danger"></span>';
		}

	}

}
