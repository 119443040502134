import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ArtistService} from '../../../../core/service/artist.service';
import {PiercingService} from '../../../../core/service/piercing.service';
import {Artist} from '../../../../model/domain/artist';
import {Piercing} from '../../../../model/domain/piercing';

@Component({
	selector: 'app-order-add-piercing',
	templateUrl: './order-add-piercing.component.html',
	styleUrls: ['./order-add-piercing.component.scss']
})
export class OrderAddPiercingComponent implements OnInit {
	artists: Artist[];
	selectedArtist: number;
	piercings: Piercing[];
	selectedPiercing: number;
	quantity: number;

	constructor(
		public activeModal: NgbActiveModal,
		private artistService: ArtistService,
		private piercingService: PiercingService
	) { }

	ngOnInit(): void {
		this.quantity = 1;
		this.artistService.getAllPiercingArtists().subscribe(results => this.artists = results);
		this.piercingService.getAll().subscribe(results => this.piercings = results);
	}

	canSave(): boolean {
		if (this.selectedPiercing === undefined) {
			return false;
		} else if (this.quantity === undefined || isNaN(this.quantity) || this.quantity <= 0) {
			return false;
		} else if (this.selectedArtist === undefined) {
			return false;
		}

		return true;
	}

	save(): void {
		this.activeModal.close({
			artist: this.getSelectedArtist(),
			piercing: this.getSelectedPiercing(),
			quantity: this.quantity
		});
	}

	getSelectedArtist(): Artist {
		for (let i = 0; i < this.artists.length; i++) {
			if (this.artists[i].id == this.selectedArtist) {
				return this.artists[i];
			}
		}
		return undefined;
	}


	getSelectedPiercing(): Piercing {
		for (let i = 0; i < this.piercings.length; i++) {
			if (this.piercings[i].id == this.selectedPiercing) {
				return this.piercings[i];
			}
		}
		return undefined;
	}
}
