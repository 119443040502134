import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {OrderService} from '../../../core/service/order.service';
import {Order} from '../../../model/domain/order';


@Injectable()
export class OrderResolver implements Resolve<Observable<Order>> {
	constructor(
		private orderService: OrderService
	) {}

	resolve(route: ActivatedRouteSnapshot): Observable<Order> {
		const id: number = +route.paramMap.get('id');
		return this.orderService.getById(id);
	}
}
