<div class="row">
	<div class="col-12 text-center mb-3">
		<button class="btn btn-primary rounded btn-lg" (click)="openAddModal()"><span class="fa fa-upload"></span> Upload new</button>
	</div>
</div>
<div class="row row-cols-2 row-cols-md-2">
	<div class="col mb-4" *ngFor="let document of documents">
		<div class="card">
			<div class="card-horizontal align-items-center">
				<div class="card-body p-3">
					<div class="row">
						<div class="col-3">
							<div class="embed-responsive embed-responsive-1by1" (click)="viewDocument(document)" style="vertical-align: middle; ">
								<div class="embed-responsive-item text-center rounded-lg"
								     style="vertical-align: middle; ">

									<img [src]="'api/document/' + document.id | authImage | async"
									     *ngIf="getFileType(document.extension) === 'image'"
									     class="img-fluid border rounded-lg">

									<i style="font-size: 6em"
									   [ngClass]="['fa', getIcon(document.extension)]"
									   *ngIf="getFileType(document.extension) !=='image'"></i>

									<div class="overlay text-white mr-2" *ngIf="getFileType(document.extension) === 'image'">
										<i class="fa fa-search-plus"></i>
									</div>
								</div>
							</div>
						</div>

						<div class="col-5">
							<strong *ngIf="!document.name">{{document.filename}}</strong>
							<strong *ngIf="document.name">{{document.name}}</strong>
							<br/>

							<span class="text-muted small" *ngIf="document.name">{{document.filename + '.' + document.extension}}</span>
							<br/>
							<span *ngIf="document.comments">{{document.comments}} <br/></span>
						</div>
						<div class="col-4 text-right">
							<button class="btn btn-primary rounded" (click)="downloadDocument(document)"><span class="fa fa-fw fa-download"></span></button>
						</div>
					</div>
				</div>
				<div class="card-footer text-muted">
					<div class="row small">
						<div class="col-6">File uploaded on {{document.created | formatdatetime}}</div>
						<div class="col-6 text-right">{{document.size | filesize}}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
