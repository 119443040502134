import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ArtistService} from '../../../core/service/artist.service';
import {ArtistViewService} from './artist.view.service';
import {Artist} from '../../../model/domain/artist';

@Component({
	selector: 'app-artist-view',
	templateUrl: './artist-view.component.html',
	styleUrls: ['./artist-view.component.scss']
})
export class ArtistViewComponent implements OnInit {
	artist: Artist;

	constructor(
		private route: ActivatedRoute,
		private artistService: ArtistService,
		private artistViewService: ArtistViewService
	) {}

	ngOnInit(): void {
		this.artistViewService.get().subscribe(
			artist => this.artist = artist
		);
		this.route.paramMap.subscribe(
			value => this.artistService.getById(Number(value.get('id'))).subscribe(
				artist => this.artistViewService.update(artist)
			)
		);
	}
}
