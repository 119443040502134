import {Pipe, PipeTransform, Injector, Type} from '@angular/core';
import {Transaction} from '../../model/domain/transaction';
import {FullObjectPipe} from './full-object-pipe';

@Pipe({
	name: 'transactionDetailsPipe'
})
export class TransactionDetailsPipe implements PipeTransform, FullObjectPipe {
	fullObjectPipe = true;

	constructor() {}

	transform(transaction: Transaction): any {
		let value = transaction.comments;
		if (transaction.comments && transaction.userComments) {
			value += ': <i>';
		}

		if (transaction.userComments) {
			value += transaction.userComments + '</i>';
		}

		return value;
	}


}
