import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Listrequest} from '../../model/listrequest';
import {Paginationmodel} from '../../model/paginationmodel';

@Injectable({
	providedIn: 'root'
})
export class GiftcardService {
	private url = 'api/giftcard/';

	constructor(
		private http: HttpClient
	) {}

	getAllGiftcards(request: Listrequest): Observable<Paginationmodel> {
		return this.http.post<Paginationmodel>(`${this.url}list`, request);
	}
}
