import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {FormSubmission} from '../../model/form-submission';
import {Listrequest} from '../../model/listrequest';
import {Paginationmodel} from '../../model/paginationmodel';
import {User} from '../../model/domain/user';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	private url = 'api/user/';
	private urlAll = this.url + 'all';

	constructor(
		private http: HttpClient
	) {}

	getAll(): Observable<User[]> {
		return this.http.get<User[]>(this.urlAll);
	}

	getAllUsers(request: Listrequest): Observable<Paginationmodel> {
		return this.http.post<Paginationmodel>(`${this.url}list`, request);
	}

	getById(id: number): Observable<User> {
		return this.http.get<User>(this.url + id + '/');
	}

	updateUser(user: User): Observable<FormSubmission> {
		return this.http.post<FormSubmission>(`${this.url}update`, user);
	}

	newUser(user: User): Observable<FormSubmission> {
		return this.http.post<FormSubmission>(`${this.url}new`, user);
	}

}
