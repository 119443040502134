<div class="row m-0 p-0">
	<div class="col-2 m-0 p-0 navpanel shadow bg-light navbar-light text-truncate">
		<!--		<h2 class="title pt-3 text-center">Administration</h2>-->

		<nav class="navbar mt-0 pl-2 pr-2">
			<a class="navbar-brand mr-0" href="#">
				<img src="assets/logo/main.png" class="img-fluid pt-0 pl-3 pr-3 pb-4">
			</a>
			<ul class="navbar-nav mt-0">
				<ul class="navbar-nav mr-auto mt-0">
					<li class="nav-item mb-2" routerLinkActive="active">
						<a class="nav-link" routerLink="/dashboard">
							<i class="fa fa-fw fa-home"></i>
							Dashboard
						</a>
					</li>

					<li class="nav-item navheader">
						<i class="fa fa-fw fa-clipboard"></i> Reports
					</li>
					<ul class="mb-2">
						<li class="nav-item" routerLinkActive="active">
							<a class="nav-link" routerLink="/report/dayreview">Daily review</a>
						</li>
						<li class="nav-item" routerLinkActive="active">
							<a class="nav-link" routerLink="/report/dayperformance">Daily performance</a>
						</li>
						<li class="nav-item" routerLinkActive="active">
							<a class="nav-link" routerLink="/report/month">Monthly report</a>
						</li>
						<li class="nav-item" routerLinkActive="active">
							<a class="nav-link" routerLink="/report/productsales">Sales report</a>
						</li>
					</ul>

					<li class="nav-item navheader">
						<i class="fa fa-fw fa-users"></i> Administration
					</li>
					<ul class="mb-2">
						<li class="nav-item" routerLinkActive="active">
							<a class="nav-link" routerLink="/user">Users</a>
						</li>

						<li class="nav-item" routerLinkActive="active">
							<a class="nav-link" routerLink="/artist/list">Artists</a>
						</li>

						<li class="nav-item" routerLinkActive="active">
							<a class="nav-link" routerLink="/multipayout">Artists payout</a>
						</li>

					</ul>

					<li class="nav-item navheader">
						<i class="fa fa-fw fa-archive"></i> Inventory
					</li>
					<ul class="mb-2">
						<li class="nav-item" routerLinkActive="active">
							<a class="nav-link" routerLink="/inventory/view">Overview</a>
						</li>
						<li class="nav-item" routerLinkActive="active">
							<a class="nav-link" routerLink="/inventory/transactions">Transactions</a>
						</li>
						<li class="nav-item" routerLinkActive="active">
							<a class="nav-link" routerLink="/inventory/transfer">Transfer items</a>
						</li>
					</ul>

					<li class="nav-item navheader">
						<i class="fa fa-fw fa-euro"></i> Point of Sale
					</li>
					<ul class="mb-2">
						<li class="nav-item" routerLinkActive="active">
							<a class="nav-link" routerLink="/orders">Orders</a>
						</li>
						<li class="nav-item" routerLinkActive="active">
							<a class="nav-link" routerLink="/tattoos">Tattoos</a>
						</li>

						<li class="nav-item" routerLinkActive="active">
							<a class="nav-link" routerLink="/piercings">Piercings</a>
						</li>

						<li class="nav-item" routerLinkActive="active">
							<a class="nav-link" routerLink="/transactions">Transactions</a>
						</li>

						<li class="nav-item" routerLinkActive="active">
							<a class="nav-link" routerLink="/giftcards">Giftcards</a>
						</li>
					</ul>


					<li class="nav-item navheader">
						<i class="fa fa-fw fa-cogs"></i> Cashier settings
					</li>
					<ul class="mb-2">
						<!--						<li class="nav-item" routerLinkActive="active">-->
						<!--							<a class="nav-link" routerLink="/studios">Studios</a>-->
						<!--						</li>-->

						<li class="nav-item" routerLinkActive="active">
							<a class="nav-link" routerLink="/products">Products</a>
						</li>

						<li class="nav-item" routerLinkActive="active">
							<a class="nav-link" routerLink="/pospiercings">Piercings</a>
						</li>
					</ul>
				</ul>
			</ul>
		</nav>
	</div>

	<div class="col-10 offset-2 mt-3">
		<main role="main" class="container mt-4">
			<router-outlet></router-outlet>
		</main>
	</div>
</div>


<!--<nav class="navbar navbar-expand-md bg-primary navbar-dark navbar-static-top shadow">-->
<!--	<a class="navbar-brand" href="#">United Artists</a>-->
<!--	<button class="navbar-toggler" type="button" data-toggle="collapse">-->
<!--		<span class="navbar-toggler-icon"></span>-->
<!--	</button>-->

<!--	<div class="collapse navbar-collapse">-->
<!--		<ul class="navbar-nav mr-auto">-->
<!--			<li class="nav-item" routerLinkActive="active">-->
<!--				<a class="nav-link" routerLink="/dashboard">Dashboard</a>-->
<!--			</li>-->

<!--			<li class="nav-item" routerLinkActive="active">-->
<!--				<a class="nav-link" routerLink="/studios">Studios</a>-->
<!--			</li>-->

<!--			<li class="nav-item" routerLinkActive="active">-->
<!--				<a class="nav-link" routerLink="/reports">Reports</a>-->
<!--			</li>-->

<!--			<li class="nav-item" routerLinkActive="active">-->
<!--				<a class="nav-link" routerLink="/productgroups">Product Groups</a>-->
<!--			</li>-->

<!--			<li class="nav-item" routerLinkActive="active">-->
<!--				<a class="nav-link" routerLink="/products">Products</a>-->
<!--			</li>-->

<!--			<li class="nav-item" routerLinkActive="active">-->
<!--				<a class="nav-link" routerLink="/piercinggroups">Piercing Groups</a>-->
<!--			</li>-->

<!--			<li class="nav-item" routerLinkActive="active">-->
<!--				<a class="nav-link" routerLink="/piercings">Piercings</a>-->
<!--			</li>-->

<!--			<li class="nav-item" routerLinkActive="active">-->
<!--				<a class="nav-link" routerLink="/user">Users</a>-->
<!--			</li>-->

<!--			<li class="nav-item" routerLinkActive="active">-->
<!--				<a class="nav-link" routerLink="/artist/list">Artists</a>-->
<!--			</li>-->

<!--			<li class="nav-item" routerLinkActive="active">-->
<!--				<a class="nav-link" routerLink="/orders">Orders</a>-->
<!--			</li>-->

<!--			<li class="nav-item" routerLinkActive="active">-->
<!--				<a class="nav-link" routerLink="/tattoos">Tattoos</a>-->
<!--			</li>-->
<!--		</ul>-->
<!--	</div>-->
<!--</nav>-->


