import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Listrequest} from '../../model/listrequest';
import {Order} from '../../model/domain/order';
import {OrderItem} from '../../model/domain/order-item';
import {Transaction} from '../../model/domain/transaction';
import {Paginationmodel} from '../../model/paginationmodel';

@Injectable({
	providedIn: 'root'
})
export class OrderService {
	private url = 'api/order/';
	private urlAll = this.url + 'all';

	constructor(
		private http: HttpClient
	) {}

	getAllOrders(request: Listrequest): Observable<Paginationmodel> {
		return this.http.post<Paginationmodel>(`${this.url}list`, request);
	}

	getById(id: number): Observable<Order> {
		return this.http.get<Order>(this.url + id + '/');
	}

	getItems(id: number): Observable<OrderItem[]> {
		return this.http.get<OrderItem[]>(this.url + id + '/items');
	}

	getTransactions(id: number): Observable<Transaction[]> {
		return this.http.get<Transaction[]>(this.url + id + '/transactions');
	}

	newOrder(orderObj: object): Observable<Order> {
		return this.http.post<Order>(`${this.url}new`, orderObj);
	}

	cancelOrder(order: Order): Observable<Order> {
		return this.http.post<Order>(`${this.url}${order.id}/cancel`, {});
	}

	refundOrder(order: Order, model: object): Observable<Order> {
		return this.http.post<Order>(`${this.url}${order.id}/refund`, model);
	}
}
