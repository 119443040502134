import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Listrequest} from '../../model/listrequest';
import {Paginationmodel} from '../../model/paginationmodel';
import {PiercingModel} from '../../model/domain/piercing-model';

@Injectable({
	providedIn: 'root'
})
export class PiercingTransactionService {
	private url = 'api/piercing/';

	constructor(
		private http: HttpClient
	) {}

	getAllPiercings(request: Listrequest): Observable<Paginationmodel> {
		return this.http.post<Paginationmodel>(`${this.url}list`, request);
	}

	getById(id: number): Observable<PiercingModel> {
		return this.http.get<PiercingModel>(`${this.url}${id}/`);
	}

	changeArtist(piercing: PiercingModel, selected: number): Observable<PiercingModel> {
		const model = {
			artist: selected
		};

		return this.http.post<PiercingModel>(`${this.url}${piercing.id}/changeartist`, model);
	}

	changeSharePercentage(piercing: PiercingModel, newPercentage: number): Observable<PiercingModel> {
		const model = {
			newPercentage
		};

		return this.http.post<PiercingModel>(`${this.url}${piercing.id}/changepayout`, model);
	}
}
