import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PiercingModel} from '../../../model/domain/piercing-model';
import {PiercingTransactionService} from '../../../core/service/piercing-transaction.service';


@Injectable()
export class PiercingResolver implements Resolve<Observable<PiercingModel>> {
	constructor(
		private piercingService: PiercingTransactionService
	) {}

	resolve(route: ActivatedRouteSnapshot): Observable<PiercingModel> {
		const id: number = +route.paramMap.get('id');
		return this.piercingService.getById(id);
	}
}
