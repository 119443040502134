import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, share} from 'rxjs/operators';

import {Studio} from '../../model/domain/studio';

@Injectable({
	providedIn: 'root'
})
export class StudioService {
	private getAllObs: Observable<Studio[]>;
	private studios: Studio[];
	private url = 'api/studio/';
	private urlAll = this.url + 'all';

	constructor(
		private http: HttpClient
	) {}

	/**
	 * Get all studios, cached
	 */
	getAll(): Observable<Studio[]> {
		if (this.studios) {
			return of(this.studios);
		} else if (this.getAllObs) {
			return this.getAllObs;
		}
		this.getAllObs = this.http.get<Studio[]>(this.urlAll).pipe(
			map(val => {
					this.getAllObs = null;
					this.studios = val;
					return val;
				}
			))
			.pipe(
				share()
			);
		return this.getAllObs;
	}

	getById(id: number): Observable<Studio> {
		return this.http.get<Studio>(this.url + id + '/');
	}
}
