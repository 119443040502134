import {Pipe, PipeTransform} from '@angular/core';
import {OrderItem} from '../../model/domain/order-item';
import {ArtistnamePipe} from './artistname.pipe';
import {FullObjectPipe} from './full-object-pipe';


@Pipe({
	name: 'orderItemName'
})
export class OrderItemNamePipe implements PipeTransform, FullObjectPipe {
	fullObjectPipe = true;

	constructor() {}

	transform(item: OrderItem): any {
		let refunded = '';
		if (item.refunded) {
			refunded = `<b>Refunded</b> `;
		}

		switch (item.type) {
			case 'PRODUCT':
				return refunded + item.product;
			case 'GIFTCARD':
				return refunded + `Giftcard <i>${item.giftcard.code}</i>`;
			case 'PIERCING':
				return refunded + `Piercing: ${item.piercing}`;
			case 'TATTOO':
				return refunded + `<b>Tattoo</b> by ${new ArtistnamePipe().transform(item.tattoo.artist)}`;
			case 'TATTOO_DOWNPAYMENT':
				return refunded + `<b>Down payment for Tattoo</b> by ${new ArtistnamePipe().transform(item.tattoo.artist)}`;
		}
		return 'Item #' + item.id;
	}
}
