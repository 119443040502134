<div class="modal-header" [ngClass]="{'bg-primary': account.name === 'BTW', 'bg-success': account.name=='Main', 'text-light':
		 account.name === 'BTW' ||  account.name === 'Main'}">
	<h4 class="modal-title"><span class="fa fa-wrench mr-2"></span> Correction to <strong>{{account.name}}</strong> account</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" tabindex="-1">
		&times;
	</button>
</div>

<div class="modal-body d-flex justify-content-center text-lg-right">
	<div class="row">
		<div class="col-6 mb-3">Current Balance</div>
		<div class="col-6 mb-3 font-weight-bold">{{account.currentBalance | currency}}</div>

		<div class="col-6 mb-3">Correction</div>
		<div class="col-6 mb-3">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text" id="basic-addon1">&euro;</span>
				</div>
				<input type="number" class="form-control" id="inputCorrection" name="correction" [(ngModel)]="form.correction" autofocus>
			</div>
		</div>

		<div class="col-6">Description</div>
		<div class="col-6">
			<div class="form-group">
				<input type="text" class="form-control form-control-sm" id="inputDescription" name="description" [(ngModel)]="form.description" autofocus>
			</div>
		</div>

		<div class="col-6 font-weight-bold">Balance after correction</div>
		<div class="col-6 font-weight-bold">{{account.currentBalance + (form.correction ? form.correction : 0) | currency}}</div>
	</div>
</div>


<div class="modal-footer">
	<button type="button" class="btn btn-danger mr-auto" (click)="activeModal.close('Close click')">Cancel</button>
	<label class="mr-auto"></label>
	<button type="button" class="btn btn-success" [disabled]="!canSave()" (click)="saveCorrection(account.name, form.correction, form.description)">Save
	</button>
</div>
