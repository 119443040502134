import {Component, OnInit} from '@angular/core';
import {PiercingGroup} from '../../../model/domain/piercing-group';
import {Product} from '../../../model/domain/product';
import {ModalService} from '../../../core/service/modalservice';
import {PiercingGroupService} from '../../../core/service/piercinggroup.service';
import {PiercingEditComponent} from './piercing-edit/piercing-edit.component';

@Component({
	selector: 'app-piercing-list',
	templateUrl: './pos-piercing-list.component.html',
	styleUrls: ['./pos-piercing-list.component.scss']
})
export class PosPiercingListComponent implements OnInit {
	piercings: PiercingGroup[];

	constructor(
		private piercingGroupService: PiercingGroupService,
		private modalService: ModalService
	) { }

	ngOnInit(): void {
		this.getPiercings();
	}

	getPiercings(): void {
		this.piercingGroupService.getAll().subscribe(piercings => this.piercings = piercings);
	}

	onEdit(product: Product): void {
		const modelRef = this.modalService.open(PiercingEditComponent);
		(modelRef.componentInstance as PiercingEditComponent).setPiercing(product);
		modelRef.result.then(val => this.getPiercings());
	}

}
