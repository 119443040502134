import {Component, EventEmitter, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ArtistService} from '../../../../core/service/artist.service';
import {FormSubmission} from '../../../../model/form-submission';
import * as countries from '../../../../conf/countries';
import {ArtistViewService} from '../artist.view.service';
import {Artist} from '../../../../model/domain/artist';

@Component({
	selector: 'app-artist-edit',
	templateUrl: './artist-edit.component.html',
	styleUrls: ['./artist-edit.component.scss']
})
export class ArtistEditComponent implements OnInit {
	countries = countries.countries;
	artist: Artist = ({} as any) as Artist;

	result: FormSubmission;
	formState = new EventEmitter<FormSubmission>();

	constructor(
		private route: ActivatedRoute,
		private artistService: ArtistService,
		private artistViewService: ArtistViewService
	) {
	}

	ngOnInit(): void {
		this.artistViewService.get().subscribe(
			artist => {
				this.artist = JSON.parse(JSON.stringify(artist));
				this.artist.artistType = this.capitalizeFirstLetter(this.artist.artistType);
			}
		);
	}

	save(): void {
		this.artistService.updateArtist(this.artist).subscribe(
			form => {
				this.formState.next(form);
				this.result = form;
				if (form.success) {
					this.artistViewService.update(this.result.object);
					this.result.object.artistType = this.capitalizeFirstLetter(form.object.artistType);
				}
			}
		);
	}

	capitalizeFirstLetter(text: string): string {
		return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
	}
}
