<app-page name="Tattoos">
		<app-table [headers]="headers" [objects]="objects"
		           (request)="getObjects($event)"
		           (objectclick)="onObjectSelect($event)"
		           [filterUpdate]="filterUpdate"
		           [defaultFilters]="defaultFilters"
		           (loading)="setLoading($event)"
		           sortBy="created"
		           order="DESC"
		           size="10">

			<div class="col-12 text-white">
				<app-studio-filter [(form)]="filters.studio" (filterchange)="updateFilters()" [disabled]="loading"></app-studio-filter>

				<select class="mr-2 custom-select col-auto" style="width:auto;" [(ngModel)]="filters.touchup" (change)="updateFilters()"
				        name="touchup" [disabled]="loading">
					<option ngValue="any">Any type</option>
					<option value="true">Touchup</option>
					<option value="false">Tattoo</option>
				</select>

				<select class="custom-select mr-2" style="width:auto;" [(ngModel)]="filters.status" (change)="updateFilters()" name="status" [disabled]="loading">
					<option ngValue="any">Any status</option>
					<option value="open">Open/future</option>
					<option value="closed">Closed</option>
					<option value="no_show">No show</option>
					<option value="progress">In progress</option>
					<option value="finished">Finished & unpaid</option>
				</select>
				<app-date-filter [(min)]="filters.mindate" [(max)]="filters.maxdate" (filterchange)="updateFilters()" [disabled]="loading"></app-date-filter>

			</div>
		</app-table>
</app-page>
