import {Component, OnInit} from '@angular/core';
import {Listrequest} from '../../../model/listrequest';
import {StudioDayOverview} from '../../../model/studio-day-overview';
import {Transaction} from '../../../model/domain/transaction';
import {StudioPerformanceService} from '../../../core/service/studio-performance.service';
import {TransactionService} from '../../../core/service/transaction.service';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
	transactions: Transaction[];
	today: StudioDayOverview;
	yesterday: StudioDayOverview;

	constructor(
		private transactionService: TransactionService,
		private studioPerformanceService: StudioPerformanceService
	) { }

	ngOnInit(): void {
		const request = new Listrequest(0, 10, 'created', 'desc', []);
		request.addFilter('method', 'discount');
		this.transactionService.getAllTransactions(request).subscribe(value => this.transactions = value.results);
		this.studioPerformanceService.getToday().subscribe(value => this.today = value);
		this.studioPerformanceService.getYesterday().subscribe(value => this.yesterday = value);
	}

}
