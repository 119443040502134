import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {FormComponent} from '../form/form.component';

@Component({
	selector: 'app-input-dropdown',
	templateUrl: './input-dropdown.component.html',
	styleUrls: ['./input-dropdown.component.scss']
})
export class InputDropdownComponent implements OnInit {
	@Input()
	name: string;
	@Input()
	label: string;
	@Input()
	options: string[];
	@Input()
	ngModel: string;
	@Input()
	defaultOption: string;
	@Output()
	ngModelChange = new EventEmitter<string>();

	constructor(public form: FormComponent) { }

	ngOnInit(): void {
	}
}
