import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {InventoryService} from '../../../../core/service/inventory.service';
import {InventoryItemModel} from '../../../../model/inventory/inventory-item-model';

@Component({
	selector: 'app-inventory-item-limits',
	templateUrl: './inventory-item-limits.component.html',
	styleUrls: ['./inventory-item-limits.component.scss']
})
export class InventoryItemLimitsComponent implements OnInit {
	item: InventoryItemModel;
	saved = false;
	form = {
		desired: 0,
		warning: 0,
		minimum: 0
	};

	constructor(
		public activeModal: NgbActiveModal,
		private inventoryService: InventoryService
	) {}

	ngOnInit(): void {
	}

	setItem(item: InventoryItemModel): void {
		this.item = item;
		this.form.desired = item.quantityDesired;
		this.form.warning = item.quantityWarning;
		this.form.minimum = item.quantityMinimum;
	}

	canSave(): boolean {
		if (this.saved) {
			return false;
		}

		if (!this.form.desired || !this.form.minimum || !this.form.warning) {
			return false;
		}

		if (this.form.desired < 0 || this.form.warning < 0 || this.form.minimum < 0) {
			return false;
		}

		return true;
	}

	save(): void {
		this.saved = true;
		this.inventoryService.updateItem(this.item, this.form.desired, this.form.warning, this.form.minimum).subscribe(value =>
			this.activeModal.close(value)
		);
	}
}
