import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StudioDayOverview} from '../../model/studio-day-overview';

@Injectable({
	providedIn: 'root'
})
export class StudioPerformanceService {
	private url = 'api/performance/';

	constructor(
		private http: HttpClient
	) {}

	getToday(): Observable<StudioDayOverview> {
		return this.http.get<StudioDayOverview>(`${this.url}today`);
	}

	getYesterday(): Observable<StudioDayOverview> {
		return this.http.get<StudioDayOverview>(`${this.url}yesterday`);
	}

}
