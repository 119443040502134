import {Injectable} from '@angular/core';
import {ModalService} from '../../core/service/modalservice';
import {ConfirmationDialogComponent} from './confirmation-dialog.component';

@Injectable({
	providedIn: 'root'
})
export class ConfirmDialogService {

	constructor(private modalService: ModalService) {}

	public confirm(message: string): Promise<boolean> {
		const modalRef = this.modalService.open(ConfirmationDialogComponent);
		modalRef.componentInstance.message = message;
		return modalRef.result;
	}
}
