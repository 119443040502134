<div class="modal-header">
	<h4 class="modal-title"><span class="fa fa-location-arrow mr-2 text-center"></span> Modify studio</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" tabindex="-1">
		&times;
	</button>
</div>

<div class="modal-body text-right" *ngIf="studios">
	<div class="row">
		<div class="col-4 offset-2 mb-3">Current details</div>
		<div class="col-4 mb-3">
			{{ tattoo.artist.nickname }} @ {{ tattoo.studio.name }}
		</div>
	</div>

	<div class="row">
		<div class="col-4 offset-2 mb-3">Select new studio</div>
		<div class="col-4 mb-3">
			<div class="input-group">
				<select class="custom-select" [(ngModel)]="selectedStudio">
					<option *ngFor="let studio of studios" [value]="studio.id">{{ studio.name }}</option>
				</select>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-4 offset-2 mb-3">Select new artist</div>
		<div class="col-4 mb-3">
			<div class="input-group">
				<select class="custom-select" [(ngModel)]="selectedArtist">
					<option *ngFor="let artist of artists" [value]="artist.id">{{ artist | artistname }}</option>
				</select>
			</div>
		</div>
	</div>
</div>

<div class="modal-footer">
	<button type="button" class="btn btn-danger mr-auto" (click)="activeModal.close()">Cancel</button>
	<label class="mr-auto"></label>
	<button type="button" class="btn btn-success" [disabled]="!canSave()" (click)="save()">Save
	</button>
</div>
