import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'dynamicPipe'
})
export class DynamicPipe implements PipeTransform {

	constructor() {}

	transform(value: any, pipe: any, fullObject: any): any {
		if (pipe === undefined) {
			return value;
		}

		if ('fullObjectPipe' in pipe) {
			return pipe.transform(fullObject);
		}

		return pipe.transform(value);
	}
}
