<div class="col-8 offset-2">
	<app-form [fromSubmission]="formState" *ngIf="user">
		<h5>User details</h5>
		<div class="row">
			<div class="col-6 mb-3">
				<app-input-text name="name" label="Name" [(ngModel)]="user.name" ngDefaultControl></app-input-text>
			</div>
			<div class="col-6 mb-3">
				<app-input-text name="email" label="Email" [(ngModel)]="user.email" ngDefaultControl></app-input-text>
			</div>
		</div>
		<div>
			<div class="row">
				<div class="col-6 mb-3">
					<app-input-text name="password" label="New password" subLabel="(leave blank to remain unchanged)" [(ngModel)]="user.password"
									ngDefaultControl></app-input-text>
				</div>
			</div>
		</div>
		<br/>
		<h5>Access Settings</h5>
		<div>
			<div class="row">
				<div class="col-6 mb-3">
					<app-input-checkbox name="active" label="Active" desc="Account status" [(ngModel)]="user.active" ngDefaultControl>
					</app-input-checkbox>
				</div>
				<div class="col-6 mb-3">
					<app-input-radio name="access" label="User acces level" [options]="['Agenda', 'Admin']" [(ngModel)]="user.access" ngDefaultControl>
					</app-input-radio>
				</div>
			</div>
			<div class="row">
				<div class="col-6 mb-3">
					<label>Agenda access for studio:</label>
					<div *ngFor="let studio of studios; index as i">
						<app-input-checkbox [name]="'studio' + i" [label]="studio.name" [(ngModel)]="studioValue[i]" ngDefaultControl>
						</app-input-checkbox>
					</div>
				</div>

			</div>
		</div>

		<hr class="mb-4">

		<div class="row">
			<div class="col-4">
				<button class="btn btn-danger btn-lg btn-block" type="submit">Cancel</button>
			</div>
			<div class="offset-4 col-4">
				<button class="btn btn-success btn-lg btn-block" type="submit" (click)="save()">Save</button>
			</div>
		</div>
	</app-form>
</div>
