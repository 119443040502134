import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PiercingModel} from '../../../model/domain/piercing-model';
import {ModalService} from '../../../core/service/modalservice';
import {ModifyArtistPiercingComponent} from './modify-artist-piercing/modify-artist-piercing.component';
import {ModifySharePiercingComponent} from './modify-share-piercing/modify-share-piercing.component';

@Component({
	selector: 'app-piercing-view',
	templateUrl: './piercing-view.component.html',
	styleUrls: ['./piercing-view.component.scss']
})
export class PiercingViewComponent implements OnInit {
	piercing: PiercingModel;

	constructor(
		private route: ActivatedRoute,
		private modalService: ModalService
	) {}

	ngOnInit(): void {
		this.piercing = this.route.snapshot.data.piercing as PiercingModel;
	}

	changeArtist(): void {
		const modelRef = this.modalService.open(ModifyArtistPiercingComponent);
		modelRef.componentInstance.setPiercing(this.piercing);

		modelRef.result.then(value => {
			if (value !== undefined) {
				this.piercing = value;
			}
		}, reason => {});
	}

	changePercentage(): void {
		const modelRef = this.modalService.open(ModifySharePiercingComponent);
		modelRef.componentInstance.setPiercing(this.piercing);

		modelRef.result.then(value => {
			if (value !== undefined) {
				this.piercing = value;
			}
		}, reason => {});
	}
}
