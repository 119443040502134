<label [for]="name">{{label}}
	<span *ngIf="subLabel">
		- <small class="text-muted">{{subLabel}}</small>
	</span>
</label>

<input type="text"
       class="form-control"
       [ngClass]="{'is-invalid': form.hasErrors(name)}"
       [id]="name"
       [name]="name"
       ([ngModel])="ngModel"
       [value]="ngModel"
       (input)="form.clearErrors(name)"
       required/>

<div class="invalid-feedback">
	<span *ngFor="let error of form.getFieldErrors(name)">{{error}}<br/></span>
</div>
