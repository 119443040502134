<div class="modal-header">
	<h4 class="modal-title"><span class="fa fa-plus-circle mr-2 text-center"></span> Add piercing</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" tabindex="-1">
		&times;
	</button>
</div>


<div class="modal-body">
	<form>
		<div class="form-group">
			<label for="artist">Artist</label>
			<select id="artist" [(ngModel)]="selectedArtist" class="form-control" name="artist">
				<option *ngFor="let artist of artists" [value]="artist.id">
					{{artist.nickname}}
				</option>
			</select>
		</div>

		<div class="form-row align-items-center">
			<div class="col-8">
				<label for="piercing">Piercing</label>
				<select id="piercing" [(ngModel)]="selectedPiercing" class="form-control" name="piercing">
					<option *ngFor="let piercing of piercings" [value]="piercing.id">
						{{piercing.name}}
					</option>
				</select>
			</div>
			<div class="col-4">
				<label for="piercing">Quantity</label>
				<input type="text" class="form-control" id="quantity" placeholder="Price" name="quantity" [(ngModel)]="quantity">
			</div>
		</div>
	</form>


	<div class="row mt-3">
		<div class="col-4">
			<button class="btn btn-danger " (click)="activeModal.close()">Cancel</button>
		</div>
		<div class="offset-4 col-4 text-right">
			<button class="btn btn-success" type="submit" [disabled]="!canSave()" (click)="save()">Add piercing</button>
		</div>
	</div>
</div>
