import {Component, OnInit} from '@angular/core';
import {Tattoo} from '../../../../model/domain/tattoo';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TattooService} from '../../../../core/service/tattoo.service';
import {Studio} from '../../../../model/domain/studio';
import {StudioService} from "../../../../core/service/studio.service";
import {Artist} from "../../../../model/domain/artist";
import {ArtistService} from "../../../../core/service/artist.service";

@Component({
	selector: 'app-modify-studio-tattoo',
	templateUrl: './modify-studio-tattoo.component.html',
	styleUrls: ['./modify-studio-tattoo.component.scss']
})
export class ModifyStudioTattooComponent implements OnInit {

	tattoo: Tattoo;
	studios: Studio[];
	artists: Artist[];
	loading = false;
	selectedStudio = 0;
	selectedArtist = 0;

	constructor(
		public activeModal: NgbActiveModal,
		private artistService: ArtistService,
		private studioService: StudioService,
		private tattooService: TattooService
	) {
	}

	ngOnInit(): void {
		this.studioService.getAll().subscribe(value => this.studios = value);
		this.artistService.getAllTattooArtists().subscribe(value => this.artists = value);
	}

	setTattoo(tattoo: Tattoo): void {
		this.tattoo = tattoo;
		this.selectedStudio = tattoo.studio.id;
		this.selectedArtist = tattoo.artist.id;
	}

	canSave(): boolean {
		if (this.loading) {
			return false;
		}
		if (this.selectedStudio > 0 && this.selectedArtist > 0 && (this.selectedArtist !== this.tattoo.artist.id || this.selectedStudio !== this.tattoo.studio.id)) {
			return true;
		}
		return false;
	}

	save(): void {
		this.loading = true;
		this.tattooService.relocate(this.tattoo, this.selectedStudio, this.selectedArtist).subscribe(tattoo => this.activeModal.close(tattoo));
	}
}
