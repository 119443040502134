import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountTransactionService} from '../../../../core/service/account-transaction.service';
import {ArtistService} from '../../../../core/service/artist.service';
import {DocumentService} from '../../../../core/service/document.service';
import {ArtistViewService} from '../artist.view.service';
import {Artist} from '../../../../model/domain/artist';
import {Document} from '../../../../model/domain/document';
import {ArtistAccount} from '../../../../model/domain/artist-account';

@Component({
	selector: 'app-artist-payout',
	templateUrl: './artist-payout.component.html',
	styleUrls: ['./artist-payout.component.scss']
})
export class ArtistPayoutComponent implements OnInit {
	artist: Artist;
	selected = '';
	account: ArtistAccount;
	documents: Document[];
	loading = false;
	form = {
		amount: undefined,
		description: '',
		document: 0
	};

	constructor(
		private activeRoute: ActivatedRoute,
		private router: Router,
		private artistService: ArtistService,
		private artistViewService: ArtistViewService,
		private accountService: AccountTransactionService,
		private documentService: DocumentService
	) { }

	ngOnInit(): void {
		this.artistViewService.get().subscribe((data) => {
			this.artist = data;
			this.activeRoute.queryParams.subscribe(params => {
				if (params.account !== undefined) {
					const id = Number(params.account);
					if (id === undefined || isNaN(id)) {
						return;
					}

					this.artist.accounts.forEach(
						account => {
							if (account.id === id) {
								this.account = account;
								this.selected = account.name;
							}
						}
					);
				}
			});
			this.documentService.getRecentDocuments(this.artist).subscribe(docs => this.documents = docs);
		});


	}

	updateAcc(name: string): void {
		this.account = this.accountService.getAccount(this.artist, name);
		this.selected = name;
	}

	canSave(): boolean {
		return this.form.amount > 0 && this.form.description.length > 1 && !this.loading;
	}

	save(): void {
		this.loading = true;
		this.accountService.addPayout(this.artist, this.account.name, this.form.amount, this.form.document, this.form.description).subscribe(
			artist => {
				this.artistViewService.update(artist);
				this.router.navigateByUrl(`/artist/${this.artist.id}/status`);
			}
		);
	}
}
