<app-page name="Giftcards">
	<app-table [headers]="headers" [objects]="objects"
	           (request)="getObjects($event)"
	           (objectclick)="onObjectSelect($event)"
	           [filterUpdate]="filterUpdate"
	           [defaultFilters]="defaultFilters"
	           (loading)="setLoading($event)"
	           sortBy="created"
	           order="DESC"
	           size="10">

		<div class="col-12 text-left text-white">
			<select class="mr-2 custom-select col-auto"
			        style="width:auto;"
			        [(ngModel)]="filters.balance"
			        (change)="updateFilters()"
			        name="balance"
			        [disabled]="loading">
				<option ngValue="any">All giftcards</option>
				<option value="0.0">Only with balance</option>
			</select>

			<select class="mr-2 custom-select col-auto"
			        style="width:auto;" [(ngModel)]="filters.expired"
			        (change)="updateFilters()"
			        name="expiration"
			        [disabled]="loading">
				<option ngValue="any">Any expiration</option>
				<option value="nonexpired">Not expired</option>
				<option value="expired">Expired</option>
			</select>

			<app-studio-filter [(form)]="filters.studio" (filterchange)="updateFilters()" [disabled]="loading"></app-studio-filter>
			<app-date-filter [(min)]="filters.mindate" [(max)]="filters.maxdate" (filterchange)="updateFilters()" [disabled]="loading"></app-date-filter>
		</div>
	</app-table>
</app-page>
