<label [for]="name">{{label}}</label>
<!--<pre>{{options}}</pre>-->
<select class="form-control"
        [ngClass]="{'is-invalid': form.hasErrors(name)}"
        [id]="name"
        [name]="name"
        ([ngModel])="ngModel"
        [value]="ngModel"
        >

	<option [value]="defaultOption" *ngIf="defaultOption !== '' && defaultOption !== undefined">{{defaultOption}}</option>
	<option *ngFor="let option of options" [value]="option" [selected]="option === ngModel">
		{{option}}
	</option>
</select>

<div class="invalid-feedback">
	<span *ngFor="let error of form.getFieldErrors(name)">{{error}}<br/></span>
</div>
