import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductGroupService} from '../../../../core/service/productgroup.service';
import {Product} from '../../../../model/domain/product';
import {ProductGroup} from '../../../../model/domain/product-group';

@Component({
	selector: 'app-order-add-product',
	templateUrl: './order-add-product.component.html',
	styleUrls: ['./order-add-product.component.scss']
})
export class OrderAddProductComponent implements OnInit {
	products: ProductGroup[];

	constructor(
		public activeModal: NgbActiveModal,
		private productGroupService: ProductGroupService
	) { }

	ngOnInit(): void {
		this.productGroupService.getAll().subscribe(model => {
			this.products = model;
		});
	}

	select(product: Product): void {
		this.activeModal.close(product);
	}
}
