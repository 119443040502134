<div class="row justify-content-center">
	<div class="col-8">
		<div class="card">
			<div class="card-header text-center"
			     [class.bg-gradient-primary]="selected === 'BTW'"
			     [class.bg-gradient-success]="selected === 'Main'"
			     [class.bg-gradient-seconday]="selected==='Comp'"
			     [class.text-light]="selected === 'BTW' || selected === 'Main'">
				<h3>Payout <span *ngIf="selected !== ''">from {{selected}} account</span></h3>
			</div>

			<div class="card-body border-primary" style="background: none;">
				<div class="row justify-content-center">
					<div class="col-auto form-inline">
						<select name='account' class="form-control form-inline" [(ngModel)]="selected" (change)="updateAcc($event.target.value)">
							<option value="">Select account...</option>
							<option *ngFor="let account of artist.accounts" [value]="account.name">{{account.name}}
								: {{account.currentBalance | currency}}</option>
						</select>
					</div>
				</div>
			</div>

			<div class="card-header" style="font-size: 120%; background: none;" *ngIf="account">
				<div class="row">
					<div class="col-6 mb-3">Current Balance</div>
					<div class="col-6 mb-3 font-weight-bold text-right text-lg">{{account.currentBalance | currency}}</div>


					<div class="col-6 mb-3">Amount to payout</div>
					<div class="col-6 mb-3">
						<div class="input-group">
							<div class="input-group-prepend">
								<span class="input-group-text" id="basic-addon1">&euro;</span>
							</div>
							<input type="number" class="form-control form-control-lg text-right" id="inputCorrection" name="correction"
							       [(ngModel)]="form.amount" autofocus>
						</div>
					</div>

					<div class="col-6 mb-3 font-weight-bold">Balance after payout</div>
					<div class="col-6 mb-3 font-weight-bold text-right">{{account.currentBalance - (form.amount ? form.amount : 0) | currency}}</div>

					<div class="row">
						<hr>
					</div>

					<div class="col-6 mb-3">
						<label>Associated document</label>
					</div>
					<div class="col-6 mb-3">
						<select name='account' [(ngModel)]="form.document" class="form-control form-inline">
							<option value="0">Recent documents...</option>
							<option *ngFor="let document of documents" [value]="document.id">
								{{document.created | date:"dd-MM HH:mm"}}: {{document.name}}
							</option>
						</select>
					</div>

					<div class="col-6">Description</div>
					<div class="col-6">
						<div class="form-group">
							<input type="text" class="form-control form-control-lg text-right" id="inputDescription" name="description"
							       [(ngModel)]="form.description"
							       autofocus>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body text-right" *ngIf="account">
				<div class="row">
					<div class="offset-6 col-6">
						<button type="button" class="btn btn-success" [disabled]="!canSave()" (click)="save()">
							<i class="fa fa-arrow-circle-right"></i> Create payout
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
