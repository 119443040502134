import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Document} from '../../model/domain/document';
import {AuthenticationService} from './authentication.service';

@Injectable({
	providedIn: 'root'
})
export class DownloadService {
	private url = 'api/document/';
	htmlDocument = document;

	constructor(
		private http: HttpClient,
		private auth: AuthenticationService
	) {}

	getDocument(document: Document): Observable<any> {
		const token = this.auth.currentUserValue.token;
		const headers = new HttpHeaders({Authorization: `Bearer ${token}`});
		return this.http.get(`${this.url}${document.id}/download`, {responseType: 'blob'});
	}

	downloadDocument(document: Document): void {
		this.getDocument(document).subscribe((response: any) => {
				const file: any = new Blob([response], {type: `${document.fileType}; charset=utf-8`});
				const url = window.URL.createObjectURL(file);
				const a = this.htmlDocument.createElement('a');
				this.htmlDocument.body.appendChild(a);
				a.href = url;
				a.download = document.filename + '.' + document.extension;
				a.click();
			}, (error: any) => console.log('Error downloading the file'),

			() => console.log('File downloaded successfully')
		);
	}

	viewDocument(document: Document): void {
		this.getDocument(document).subscribe((response: any) => {
				const file: any = new Blob([response], {type: `${document.fileType}; charset=utf-8`});
				const url = window.URL.createObjectURL(file);
				window.open(url);
			}, (error: any) => console.log('Error downloading the file'),

			() => console.log('File downloaded successfully')
		);
	}
}
