import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ArtistService} from '../../../core/service/artist.service';
import {Artist} from '../../../model/domain/artist';


@Injectable()
export class ArtistResolver implements Resolve<Observable<Artist>> {
	constructor(
		private artistService: ArtistService
	) {}

	resolve(route: ActivatedRouteSnapshot): Observable<Artist> {
		const id: number = +route.paramMap.get('id');
		return this.artistService.getById(id);
	}
}
