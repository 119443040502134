<div class="row row-cols-2 row-cols-md-2" *ngIf="artist.active">
	<div class="col mb-4" *ngFor="let studio of studios">
		<div class="card">
			<div class="card-horizontal align-items-center">
				<div class="img-square-wrapper ml-2">
					<img class="p-2 studiologo" src="assets/logo/{{studio.logo}}.png">
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-10"><h4 class="card-title">{{studio.name}}</h4></div>
						<div class="col-2">
							<div class="colorselect float-right mr-2" [style]="'background-color: #' + studio.color"></div>
						</div>
					</div>

					<div class="card-text">
						<br/>
						Delivered {{studio.totalAppointments}} tattoos<br/>
						<span *ngIf="studio.nextAppointment; else noapp">
							Next appointment is at {{studio.nextAppointment.date | date}}
							(<a [routerLink]="['/tattoo' , studio.nextAppointment.id]"><small>view</small></a>)
							<br/>
						</span>
						<ng-template #noapp>No future appointments</ng-template>
					</div>
				</div>
			</div>
			<div class="card-footer">
				<div class="row">
					<div class="col-4 text-left text-muted">
						<button class="btn btn-outline-danger mr-2 p-0 buttonsize" (click)="removeStudio(studio.id)">
							<span class="fa fa-trash"></span>
						</button>
					</div>
					<div class="col-8 text-muted text-right small mt-auto">
						<span *ngIf="studio.lastAppointment">
							Last appointment was at <a [routerLink]="['/tattoo' , studio.lastAppointment.id]">{{studio.lastAppointment.date | date}}</a>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="text-center" *ngIf="studios && studios.length < 5 && artist.active">
	<button class="btn btn-primary" (click)="addStudio()"><span class="fa fa-plus"></span> Add studio</button>
</div>

<div class="card-body text-center" *ngIf="artist && !artist.active">
	<span class="text-muted">Artist is currently inactive</span>
</div>
