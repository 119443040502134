<div *ngIf="report">
	<div class="row">
		<div class="col-4 text-center offset-4">
			<h4>Monthly invoice</h4>
		</div>
		<div class="col-4">
			<div class="row justify-content-end">
				<div class="col-auto">
					<form [formGroup]="form" class="form-inline">
						<div class="form-group">
							<!--					<label for="monthselect" class="form-control-lg">Month</label>-->
							<select id="monthselect" class="form-control" formControlName="month">
								<option *ngFor="let month of months" [value]="month">{{month}}</option>
							</select>
						</div>
						<div class="form-group ml-3">
							<!--					<label for="monthselect" class="form-control-lg">Year</label>-->
							<select id="yearselect" class="form-control" formControlName="year">
								<option *ngFor="let year of years" [value]="year">{{year}}</option>
							</select>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	<hr/>

	<div class="text-center text-danger" *ngIf="report.workedDays.length === 0">
		No transactions found for this month.
	</div>
	<div *ngIf="report.workedDays.length > 0" class="col-10 offset-1">
		<h4 class="">{{report.startDate | formatdate}} - {{report.endDate | formatdate}}</h4>
		<br/>
		<table class="table table-borderless table-sm text-right">
			<tr>
				<th class="text-left" style="width: 25%">

				</th>
				<th style="width: 15%">
					Total
				</th>
				<th style="width: 15%">
					Studio
				</th>
				<th style="width: 15%">
					Artist total
				</th>
				<th style="width: 15%">
					Artist income
				</th>
				<th style="width: 15%">
					Artist tax
				</th>
			</tr>
			<tr>
				<td class="text-left">Total day</td>
				<td class="text-monospace">{{report.studioTotalDay | currency:"EUR"}}</td>
				<td class="text-monospace">{{report.studioTotalDay - (report.earnedMainDay + report.earnedBtwDay) | currency:"EUR"}}</td>
				<td class="text-monospace">{{report.earnedMainDay + report.earnedBtwDay | currency:"EUR"}}</td>
				<td class="text-monospace">{{report.earnedMainDay | currency:"EUR"}}</td>
				<td class="text-monospace">{{report.earnedBtwDay | currency:"EUR"}}</td>
			</tr>
			<tr style="border-bottom: thin solid #666666;">
				<td class="text-left">Total night</td>
				<td class="text-monospace">{{report.studioTotalNight | currency:"EUR"}}</td>
				<td class="text-monospace">{{report.studioTotalNight - (report.earnedMainNight + report.earnedBtwNight) | currency:"EUR"}}</td>
				<td class="text-monospace">{{report.earnedMainNight + report.earnedBtwNight | currency:"EUR"}}</td>
				<td class="text-monospace">{{report.earnedMainNight | currency:"EUR"}}</td>
				<td class="text-monospace">{{report.earnedBtwNight | currency:"EUR"}}</td>
			</tr>
			<tr *ngIf="report.earnedMainCustom + report.earnedBtwCustom> 0">
				<td class="text-left">Total custom</td>
				<td class="text-monospace">{{report.studioTotalCustom | currency:"EUR"}}</td>
				<td class="text-monospace">{{report.studioTotalCustom - (report.earnedMainCustom + report.earnedBtwCustom) | currency:"EUR"}}</td>
				<td class="text-monospace">{{report.earnedMainCustom + report.earnedBtwCustom | currency:"EUR"}}</td>
				<td class="text-monospace">{{report.earnedMainCustom | currency:"EUR"}}</td>
				<td class="text-monospace">{{report.earnedBtwCustom | currency:"EUR"}}</td>
			</tr>
			<tr class="font-weight-bold">
				<td class="text-left">Total income</td>
				<td class="text-monospace">
					{{report.studioTotalDay + report.studioTotalNight + report.studioTotalCustom | currency:"EUR"}}
				</td>
				<td class="text-monospace">
					{{report.studioTotalDay + report.studioTotalNight + report.studioTotalCustom - (
					report.earnedMainDay + report.earnedBtwDay +
					report.earnedMainNight + report.earnedBtwNight +
					report.earnedMainCustom + report.earnedBtwCustom
				) | currency:"EUR"}}
				</td>
				<td class="text-monospace">
					{{
					report.earnedMainDay + report.earnedBtwDay +
					report.earnedMainNight + report.earnedBtwNight +
					report.earnedMainCustom + report.earnedBtwCustom
						| currency:"EUR"
					}}
				</td>
				<td class="text-monospace">
					{{report.earnedMainDay + report.earnedMainNight + report.earnedMainCustom | currency:"EUR"}}
				</td>
				<td class="text-monospace">
					{{ report.earnedBtwDay + report.earnedBtwNight + report.earnedBtwCustom | currency:"EUR"}}
				</td>
			</tr>
			<tr>

			</tr>
			<ng-container *ngIf="report.corrections.length > 0">
				<tr style="border-bottom: thin solid #666666;">
					<td class="text-left">Corrections</td>
					<td class="text-monospace"></td>
					<td class="text-monospace"></td>
					<td class="text-monospace">{{report.correctionMain + report.correctionBtw | currency:"EUR"}}</td>
					<td class="text-monospace">{{report.correctionMain | currency:"EUR"}}</td>
					<td class="text-monospace">{{report.correctionBtw | currency:"EUR"}}</td>
				</tr>

				<tr class="font-weight-bold">
					<td class="text-left">Total corrected income</td>
					<td class="text-monospace">
					</td>
					<td class="text-monospace">

					</td>
					<td class="text-monospace">
						{{
						report.earnedMainDay + report.earnedBtwDay +
						report.earnedMainNight + report.earnedBtwNight +
						report.earnedMainCustom + report.earnedBtwCustom +
						report.correctionMain + report.correctionBtw
							| currency:"EUR"
						}}
					</td>
					<td class="text-monospace">
						{{report.earnedMainDay + report.earnedMainNight + report.earnedMainCustom + report.correctionMain | currency:"EUR"}}
					</td>
					<td class="text-monospace">
						{{ report.earnedBtwDay + report.earnedBtwNight + report.earnedBtwCustom + report.correctionBtw | currency:"EUR"}}
					</td>
				</tr>
			</ng-container>
		</table>
		<br/>

		<h5 class="m-1 mb-3 text-muted">Daily specification</h5>
		<table class="table table-borderless table-sm text-right">
			<tr>
				<th class="text-left" style="width: 25%">
					Date
				</th>
				<th style="width: 15%">
					Total
				</th>
				<th style="width: 15%">
					Studio
				</th>
				<th style="width: 15%">
					Artist total
				</th>
				<th style="width: 15%">
					Artist income
				</th>
				<th style="width: 15%">
					Artist tax
				</th>
			</tr>
			<ng-container *ngFor="let day of report.workedDays" class="">
				<tr *ngIf="day.mainEarningDay + day.btwEarningDay > 0">
					<td class="text-left"><span class="text-monospace">{{day.date | formatdate}}</span> Day</td>
					<td class="text-monospace">{{day.studioEarningDay | currency:"EUR"}}</td>
					<td class="text-monospace">{{day.studioEarningDay - (day.mainEarningDay + day.btwEarningDay) | currency:"EUR"}}</td>
					<td class="text-monospace">{{day.mainEarningDay + day.btwEarningDay | currency:"EUR"}}</td>
					<td class="text-monospace">{{day.mainEarningDay | currency:"EUR"}}</td>
					<td class="text-monospace">{{day.btwEarningDay | currency:"EUR"}}</td>
				</tr>

				<tr *ngIf="day.mainEarningNight + day.btwEarningNight > 0">
					<td class="text-left"><span class="text-monospace">{{day.date | formatdate}}</span> Night</td>
					<td class="text-monospace">{{day.studioEarningNight | currency:"EUR"}}</td>
					<td class="text-monospace">{{day.studioEarningNight - (day.mainEarningNight + day.btwEarningNight) | currency:"EUR"}}</td>
					<td class="text-monospace">{{day.mainEarningNight + day.btwEarningNight | currency:"EUR"}}</td>
					<td class="text-monospace">{{day.mainEarningNight | currency:"EUR"}}</td>
					<td class="text-monospace">{{day.btwEarningNight | currency:"EUR"}}</td>
				</tr>

				<tr *ngIf="day.mainEarningCustom + day.btwEarningCustom > 0">
					<td class="text-left text-monospace">{{day.date | formatdate}} Custom rate</td>
					<td class="text-monospace">{{day.studioEarningCustom | currency:"EUR"}}</td>
					<td class="text-monospace">{{day.mainEarningCustom + day.btwEarningCustom | currency:"EUR"}}</td>
					<td class="text-monospace">{{day.mainEarningCustom | currency:"EUR"}}</td>
					<td class="text-monospace">{{day.btwEarningCustom | currency:"EUR"}}</td>
				</tr>
			</ng-container>

			<tr>
				<td></td>
				<td></td>
				<td></td>
				<td></td>
				<td></td>
			</tr>
			<tr class="font-weight-bold">
				<td class="text-left"><strong>Total</strong></td>
				<td class="text-monospace">
					{{report.studioTotalDay + report.studioTotalNight + report.studioTotalCustom | currency:"EUR"}}
				</td>
				<td class="text-monospace">
					{{
					report.studioTotalDay + report.studioTotalNight + report.studioTotalCustom -
					(report.earnedMainDay + report.earnedBtwDay +
						report.earnedMainNight + report.earnedBtwNight +
						report.earnedMainCustom + report.earnedBtwCustom)
						| currency:"EUR"
					}}
				</td>
				<td class="text-monospace">
					{{
					report.earnedMainDay + report.earnedBtwDay +
					report.earnedMainNight + report.earnedBtwNight +
					report.earnedMainCustom + report.earnedBtwCustom
						| currency:"EUR"
					}}
				</td>
				<td class="text-monospace">
					{{report.earnedMainDay + report.earnedMainNight + report.earnedMainCustom | currency:"EUR"}}
				</td>
				<td class="text-monospace">
					{{ report.earnedBtwDay + report.earnedBtwNight + report.earnedBtwCustom | currency:"EUR"}}
				</td>
			</tr>
		</table>
		<br/>
		<ng-container *ngIf="report.corrections.length > 0">
			<h5 class="m-1 mb-3 text-muted">Corrections</h5>
			<table class="table table-borderless table-sm text-right">
				<tr>
					<th class="text-left" style="width: 25%">
						Date
					</th>
					<th style="width: 15%">
						Account
					</th>
					<th style="width: 15%">
						Amount
					</th>
					<th style="width: 45%">
						Description
					</th>
				</tr>
				<tr *ngFor="let correction of report.corrections">
					<td class="text-left text-monospace">
						{{correction.date | formatdate}}
					</td>
					<td [innerHTML]="correction.account | accountPipe">
					</td>
					<td class="text-monospace">
						{{correction.amount | currency:"EUR"}}
					</td>
					<td>
						<i>{{correction.description}}</i>
					</td>
				</tr>
			</table>
		</ng-container>
	</div>
</div>
