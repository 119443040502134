<div class="modal-header">
	<h4 class="modal-title"><span class="fa fa-edit mr-2"></span> Edit {{item.name}}</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" tabindex="-1">
		&times;
	</button>
</div>

<div class="modal-body d-flex justify-content-center text-lg-left">
	<div class="row text-lg" style="font-size: 110%">
		<div class="offset-2 col-6 mb-3">
			Desired quantity
			<br/>
			<sub class="text-muted">The preferred quantity when the item is fully stocked.</sub>
		</div>
		<div class="col-2 mb-2">
			<input type="text" class="form-control form-control text-right" [(ngModel)]="form.desired">
		</div>
		<div class="offset-2 col-6 mb-3 text-warning">
			Restock soon
			<br/>
			<sub class="text-muted">Below this quantity a warning will be shown.</sub>
		</div>
		<div class="col-2 mb-2">
			<input type="text" class="form-control form-control text-right" [(ngModel)]="form.warning">
		</div>
		<div class="offset-2 col-6 mb-3 text-danger">
			<i class="fa fa-warning"></i>&nbsp; Minimum
			<br/>
			<sub class="text-muted">The bare minimum quantity of this item.</sub>
		</div>
		<div class="col-2 mb-2">
			<input type="text" class="form-control form-control text-right" [(ngModel)]="form.minimum">
		</div>
	</div>
</div>


<div class="modal-footer">
	<button type="button" class="btn btn-danger mr-auto" (click)="activeModal.dismiss('Close click')">Cancel</button>
	<label class="mr-auto"></label>
	<button type="button" class="btn btn-success" [disabled]="!canSave()" (click)="save()">Save
	</button>
</div>
