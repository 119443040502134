import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Paginationmodel} from '../../model/paginationmodel';
import {Product} from '../../model/domain/product';

@Injectable({
	providedIn: 'root'
})
export class ProductService {
	private url = 'api/product/';
	private urlAll = this.url + 'all';

	constructor(
		private http: HttpClient
	) {}

	getAll(page: number): Observable<Paginationmodel> {
		const params = new HttpParams()
			.set('page', String(page));
		return this.http.get<Paginationmodel>(this.urlAll, {params});
	}

	getById(id: number): Observable<Product> {
		return this.http.get<Product>(this.url + id + '/');
	}

	editProduct(product: Product): Observable<Product> {
		return this.http.post<Product>(`${this.url}${product.id}/edit`, product);
	}
}
