import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'objectNamePipe'
})
export class ObjectNamePipe implements PipeTransform {

	constructor() {}

	transform(value: any): any {
		if (!value) {
			return value;
		}
		return value.name;
	}
}
