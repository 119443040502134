<app-page name="Inventory transfer">
	<div class="shadow mb-3">
		<div class="card-body p-0">
			<div class="row bg-gradient-primary m-0 p-2 text-light justify-content-center rounded-top" style="font-size: larger">
				<div class="col-auto text-center">
					Source inventory<br/>
					<select [(ngModel)]="sourceInventory" (change)="changeSource()" class="custom-select custom-select-lg">
						<option [ngValue]="undefined">
							Supplier
						</option>
						<option *ngFor="let inventory of inventories" [ngValue]="inventory">
							{{inventory.name}}
						</option>
					</select>
				</div>
				<div class="col-auto">
					<br/>
					<i class="fa fa-arrow-right"></i>
				</div>
				<div class="col-auto text-center">
					Target inventory<br/>
					<select [(ngModel)]="targetInventory" class="custom-select custom-select-lg">
						<option [ngValue]="undefined">
							None
						</option>
						<option *ngFor="let inventory of inventories" [ngValue]="inventory">
							{{inventory.name}}
						</option>
					</select>
				</div>
			</div>


			<table class="table table-borderless table-striped table-hover table-lg table-responsive-lg card-body rounded-bottom" *ngIf="modelGroups && modelGroups.length == 0">
				<div class="alert alert-warning text-center mb-0 rounded-0" role="alert">
					<i class="fa fa-warning"></i> This inventory does not contain any items
				</div>
			</table>

			<div class="row p-2" *ngIf="modelGroups && modelGroups.length > 0">
				<div class="col-4 pl-3 pr-3" *ngFor="let group of modelGroups">
					<div class="card rounded table-responsive mb-4">
						<div class="card-header p-2">
							<h4 class="mb-0">{{group.name}}</h4>
						</div>
						<table class="table table-striped table-hover table-borderless table-sm rounded card-body mb-0 shadow-sm">
							<tbody>
							<tr *ngFor="let item of group.items">
								<td class="align-middle" style="width: 1%">
									<input type="checkbox" [id]="'chkbx' + item.id" [checked]="item.selected" (change)="selectCheckbox(item)">
								</td>
								<td class="align-middle" [class.text-muted]="!item.selected" (click)="selectCheckbox(item)">
									{{item.name}}
								</td>
								<td class="text-right" style="width: 25%">
									<input type="number" class="form-control"
									       [(ngModel)]="item.transfer"
									       (change)="inputChanged(item)"
									       (focusin)="focusin(item)"
									       (focusout)="focusout(item)"
									       placeholder="{{!sourceInventory || item.selected ? '' : item.quantity}}">
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div class="row mt-2 p-3" *ngIf="modelGroups && modelGroups.length > 0">
				<div class="col-6 input-group-lg">
					<input type="text" class="form-control" placeholder="Transfer description..." [(ngModel)]="description">
				</div>
				<div class="col-6 text-right">
					<form class="form-inline pull-right">
						<button class="btn btn-lg btn-success ml-2" (click)="submitRequest()" [disabled]="!canSubmit()">
							<i class="fa fa-arrow-circle-right"></i> Perform transfer
						</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</app-page>
