import {Injectable} from '@angular/core';
import {StudioMonthReport} from '../../model/report/studio-month-report';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class MonthReportService {
	private url = 'api/studio/';

	constructor(
		private http: HttpClient
	) { }

	getReport(studio: number, date: number): Observable<StudioMonthReport> {
		const params = new HttpParams().set('date', String(date));
		return this.http.get<StudioMonthReport>(this.url + studio + '/report', {params});
	}
}
