import {Pipe, PipeTransform, Injector, Type} from '@angular/core';

@Pipe({
	name: 'orderPipe'
})
export class OrderPipe implements PipeTransform {

	constructor() {}

	transform(value: any): any {
		if (!value) {
			return '';
		}

		let id = value;
		if (value.id) {
			id = value.id;
		}


		return `<a href="order/${id}/">#${id}</a>`;
	}

}
