import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {AuthenticationService} from '../../../core/service/authentication.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	returnUrl: string;
	loginForm: FormGroup;
	loginControls: { [p: string]: AbstractControl };
	error: string;
	loading: boolean;

	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private authenticationService: AuthenticationService
	) {}

	ngOnInit(): void {
		this.loginForm = this.formBuilder.group({
			username: ['', Validators.required],
			password: ['', Validators.required]
		});
		this.loginControls = this.loginForm.controls;

		this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
		if (this.returnUrl === '/logout') {
			this.returnUrl = '/';
		}
	}

	onSubmit(): void {
		if (this.loginForm.invalid) {
			return;
		}

		this.loading = true;
		this.error = undefined;

		this.authenticationService.login(this.loginControls.username.value, this.loginControls.password.value).pipe(first()).subscribe(
			data => {
				this.router.navigate([this.returnUrl]);
			},
			error => {
				this.error = error;
				this.loading = false;
			});
	}
}
