import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PiercingModel} from '../../../../model/domain/piercing-model';
import {PiercingTransactionService} from '../../../../core/service/piercing-transaction.service';

@Component({
	selector: 'app-modify-share-piercing',
	templateUrl: './modify-share-piercing.component.html',
	styleUrls: ['./modify-share-piercing.component.scss']
})
export class ModifySharePiercingComponent {
	piercing: PiercingModel;
	loading = false;
	form = {
		percentage: 0
	};

	constructor(
		public activeModal: NgbActiveModal,
		private piercingService: PiercingTransactionService
	) {}

	setPiercing(piercing: PiercingModel): void {
		this.piercing = piercing;
		this.form = {
			percentage: piercing.artistSharePerc
		};
	}

	canSave(): boolean {
		if (this.loading) {
			return false;
		}
		if (this.form.percentage >= 0 && this.form.percentage <= 100 && this.form.percentage !== this.piercing.artistSharePerc) {
			return true;
		}
		return false;
	}

	save(): void {
		this.loading = true;
		this.piercingService.changeSharePercentage(this.piercing, this.form.percentage).subscribe(tattoo => this.activeModal.close(tattoo));
	}
}
