import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {User} from '../../../model/domain/user';
import {UserService} from '../../../core/service/user.service';
import {UserViewService} from './user.view.service';

@Component({
	selector: 'app-user-view',
	templateUrl: './user-view.component.html',
	styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent implements OnInit {
	user: User;

	constructor(
		private route: ActivatedRoute,
		private userService: UserService,
		private userViewService: UserViewService
	) {}


	ngOnInit(): void {
		this.userViewService.get().subscribe(user => {
			this.user = user;
		});

		this.route.paramMap.subscribe(
			value => this.userService.getById(Number(value.get('id'))).subscribe(
				user => this.userViewService.update(user)
			)
		);
	}
}
