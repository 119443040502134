import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {Inventory} from '../../../model/inventory/inventory';
import {AbstractTableViewComponent} from '../../../component/table-view/abstract-table-view-component';
import {Router} from '@angular/router';
import {InventoryService} from '../../../core/service/inventory.service';
import {TableColumn} from '../../../component/table-view/table-column-model';
import {IdPipe} from '../../../core/pipe/id.pipe';
import {FormatDateTimePipe} from '../../../core/pipe/formatdatetime.pipe';
import {TableProjection} from '../../../component/table-view/table-projection';
import {Observable} from 'rxjs';
import {InventoryTransaction} from '../../../model/inventory/inventory-transaction';
import {ObjectNamePipe} from '../../../core/pipe/object-name.pipe';
import {UsernamePipe} from '../../../core/pipe/username.pipe';
import {EnumTypePipe} from '../../../core/pipe/enum-type.pipe';

@Component({
	selector: 'app-inventory-view-transactions',
	templateUrl: './inventory-view-transactions.component.html',
	styleUrls: ['./inventory-view-transactions.component.scss']
})
export class InventoryViewTransactionsComponent extends AbstractTableViewComponent<InventoryTransaction> implements OnInit {
	@Input()
	inventory: Inventory;
	@Input()
	update: Observable<any>;
	reloadTransactions: EventEmitter<any>;

	constructor(
		private route: Router,
		private inventoryService: InventoryService,
	) {
		super();
		this.reloadTransactions = new EventEmitter<any>();
		// this.defaultFilters.studio = 'any';
		// this.defaultFilters.status = 'closed';
	}

	ngOnInit(): void {
		if (this.update) {
			this.update.subscribe(result => {
					this.reloadTransactions.emit();
				}
			);
		}

		this.headers.push(new TableColumn('ID', 'id', 5, new IdPipe()));
		this.headers.push(new TableColumn('Date', 'created', 15, new FormatDateTimePipe()));
		this.headers.push(new TableColumn('Quantity', 'quantity', 10));
		this.headers.push(new TableColumn('Item', 'object', 15, new ObjectNamePipe()));
		this.headers.push(new TableColumn('Type', 'type', 10, new EnumTypePipe()));
		this.headers.push(new TableColumn('Source', 'source', 10, new ObjectNamePipe()));
		this.headers.push(new TableColumn('Target', 'target', 10, new ObjectNamePipe()));
		this.headers.push(new TableColumn('User', 'user', 10, new UsernamePipe()));
		this.headers.push(new TableColumn('Description', 'description', 15));
	}

	getObjects(projection: TableProjection): void {
		if (!this.inventory) {
			return;
		}

		this.filters = projection.filterObj;
		const request = projection.getAsListRequest();
		this.inventoryService.getInventoryTransactions(this.inventory.id, request).subscribe(
			value => {
				this.objects.next(value);
			}
		);
	}

	onObjectSelect(transaction: InventoryTransaction): void {
	}
}
