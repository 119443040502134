<app-page name="Artists">
	<app-table [headers]="headers" [objects]="objects"
	           (request)="getObjects($event)"
	           (objectclick)="onObjectSelect($event)"
	           [filterUpdate]="filterUpdate"
	           [defaultFilters]="defaultFilters"
	           (loading)="setLoading($event)"
	           sortBy="nickname"
	           order="ASC"
	           size="10">

		<div class="col-6 text-white">
			<select class="mr-2 custom-select col-auto" style="width:auto;" [(ngModel)]="filters.active" (change)="updateFilters()" [disabled]="loading">
				<option ngValue="any">All artists</option>
				<option value="true">Active only</option>
				<option value="false">Inactive only</option>
			</select>

			<select class="custom-select" style="width:auto;" [(ngModel)]="filters.type" (change)="updateFilters()" [disabled]="loading">
				<option ngValue="any">Any artist type</option>
				<option value="tattoo">Only Tattoo artists</option>
				<option value="piercer">Only Piercers</option>
				<option value="both">Only Tattoo & Piercers (Both)</option>
			</select>
		</div>
		<div class="col-6 text-right">
			<button class="btn btn-success" [routerLink]="'/artist/new'"><i class="fa fa-plus-circle"></i> Add new artist</button>
		</div>
	</app-table>
</app-page>
