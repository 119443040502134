import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {AbstractTableViewComponent} from '../../../component/table-view/abstract-table-view-component';
import {InventoryTransaction} from '../../../model/inventory/inventory-transaction';
import {Inventory} from '../../../model/inventory/inventory';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {InventoryService} from '../../../core/service/inventory.service';
import {TableColumn} from '../../../component/table-view/table-column-model';
import {IdPipe} from '../../../core/pipe/id.pipe';
import {FormatDateTimePipe} from '../../../core/pipe/formatdatetime.pipe';
import {ObjectNamePipe} from '../../../core/pipe/object-name.pipe';
import {EnumTypePipe} from '../../../core/pipe/enum-type.pipe';
import {UsernamePipe} from '../../../core/pipe/username.pipe';
import {TableProjection} from '../../../component/table-view/table-projection';

@Component({
	selector: 'app-inventory-transactions',
	templateUrl: './inventory-transactions.component.html',
	styleUrls: ['./inventory-transactions.component.scss']
})
export class InventoryTransactionsComponent extends AbstractTableViewComponent<InventoryTransaction> implements OnInit {
	constructor(
		private route: Router,
		private inventoryService: InventoryService,
	) {
		super();
		// this.defaultFilters.studio = 'any';
		// this.defaultFilters.status = 'closed';
	}

	ngOnInit(): void {
		this.headers.push(new TableColumn('ID', 'id', 5, new IdPipe()));
		this.headers.push(new TableColumn('Date', 'created', 15, new FormatDateTimePipe()));
		this.headers.push(new TableColumn('Quantity', 'quantity', 10));
		this.headers.push(new TableColumn('Item', 'object', 10, new ObjectNamePipe()));
		this.headers.push(new TableColumn('Type', 'type', 10, new EnumTypePipe()));
		this.headers.push(new TableColumn('Source', 'source', 10, new ObjectNamePipe()));
		this.headers.push(new TableColumn('Target', 'target', 10, new ObjectNamePipe()));
		this.headers.push(new TableColumn('User', 'user', 10, new UsernamePipe()));
		this.headers.push(new TableColumn('Description', 'description', 20));
	}

	getObjects(projection: TableProjection): void {

		this.filters = projection.filterObj;
		const request = projection.getAsListRequest();
		this.inventoryService.getTransactions(request).subscribe(
			value => {
				this.objects.next(value);
			}
		);
	}

	onObjectSelect(transaction: InventoryTransaction): void {
	}
}
