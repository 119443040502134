<ng-container *ngIf="report !== undefined && report !== null">
	<div class="card-header bg-gradient-primary text-white">
		<h3><i class="fa fa-line-chart"></i> {{title}}</h3>
	</div>
	<table class="card-body table table-striped table-borderless mb-0">
		<thead>
		<tr class="text-center">
			<td>Customers</td>
			<td>Tattoos earnings</td>
			<td>Piercing earnings</td>
			<td>Products earnings</td>
			<td>Total earnings</td>
		</tr>
		</thead>
		<tbody>
		<tr class="text-center">
			<td><i class="fa fa-user"></i> {{report.customers}}</td>
			<td>{{report.earningTattoos | currency:"EUR"}}</td>
			<td>{{report.earningPiercings | currency:"EUR"}}</td>
			<td>{{report.earningProducts | currency:"EUR"}}</td>
			<td><strong>{{report.earningTotal | currency:"EUR"}}</strong></td>
		</tr>
		</tbody>
	</table>
</ng-container>
