<div class="modal-header">
	<h4 class="modal-title"><span class="fa fa-wrench mr-2 text-center"></span> Editing Piercing <i>{{piercing.name}}</i></h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" tabindex="-1">
		&times;
	</button>
</div>

<div class="modal-body text-lg-right">
	<div class="row">
		<div class="col-4 mb-3">Name</div>
		<div class="col-8">
			<div class="form-group">
				<input type="text" class="form-control form-control-sm" id="inputDescription" [(ngModel)]="form.name"
				       autofocus>
			</div>
		</div>
		<div class="col-4 mb-3">Price</div>
		<div class="col-4 mb-3">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text" id="basic-addon1">&euro;</span>
				</div>
				<input type="number" class="form-control" id="inputCorrection"  [(ngModel)]="form.price" autofocus>
			</div>
		</div>
		<div class="w-100"></div>
		<div class="col-4 mb-3">Enabled</div>
		<div class="col-4 mb-3">
			<div class="form-check text-left">
				<input class="form-check-input" type="checkbox" [(ngModel)]="form.available">
			</div>
		</div>
	</div>
</div>

<div class="modal-footer">
	<button type="button" class="btn btn-danger mr-auto" (click)="activeModal.close('Close click')">Cancel</button>
	<label class="mr-auto"></label>
	<button type="button" class="btn btn-success" [disabled]="!canSave()" (click)="save()">Save
	</button>
</div>
