import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ArtistService} from '../../../../core/service/artist.service';
import {Artist} from '../../../../model/domain/artist';

@Component({
	selector: 'app-order-add-tattoo',
	templateUrl: './order-add-tattoo.component.html',
	styleUrls: ['./order-add-tattoo.component.scss']
})
export class OrderAddTattooComponent implements OnInit {
	artists: Artist[];
	selectedArtist: number;
	price: number;

	constructor(
		public activeModal: NgbActiveModal,
		private artistService: ArtistService
	) { }

	ngOnInit(): void {
		this.artistService.getAllTattooArtists().subscribe(results => this.artists = results);
	}

	canSave(): boolean {
		if (this.price <= 0 || this.price === undefined || isNaN(this.price)) {
			return false;
		} else if (this.selectedArtist === undefined) {
			return false;
		}

		return true;
	}

	save(): void {
		this.activeModal.close({
			artist: this.getSelectedArtist(),
			price: this.price
		});
	}

	getSelectedArtist(): Artist {
		for (let i = 0; i < this.artists.length; i++) {
			if (this.artists[i].id == this.selectedArtist) {
				return this.artists[i];
			}
		}
		return undefined;
	}
}
