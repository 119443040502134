import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {User} from '../../../model/domain/user';
import {BooleanLightPipe} from '../../../core/pipe/boolean-light.pipe';
import {EnumTypePipe} from '../../../core/pipe/enum-type.pipe';
import {FormatDatePipe} from '../../../core/pipe/formatdate.pipe';
import {FormatDateTimePipe} from '../../../core/pipe/formatdatetime.pipe';
import {UserService} from '../../../core/service/user.service';
import {AbstractTableViewComponent} from '../../../component/table-view/abstract-table-view-component';
import {TableColumn} from '../../../component/table-view/table-column-model';
import {TableProjection} from '../../../component/table-view/table-projection';

@Component({
	selector: 'app-user-list',
	templateUrl: './user-list.component.html',
	styleUrls: ['./user-list.component.scss']
})
export class UserListComponent extends AbstractTableViewComponent<User> implements OnInit {
	constructor(
		private router: Router,
		private userService: UserService
	) {
		super();
		this.defaultFilters.active = true;
		this.defaultFilters.access = 'any';
	}

	ngOnInit(): void {
		this.headers.push(new TableColumn('Active', 'active', 5, new BooleanLightPipe()));
		this.headers.push(new TableColumn('Added', 'created', 10, new FormatDatePipe()));
		this.headers.push(new TableColumn('Access Level', 'access', 10, new EnumTypePipe()));
		this.headers.push(new TableColumn('Name', 'name', 15));
		this.headers.push(new TableColumn('Email', 'email', 15));
		this.headers.push(new TableColumn('Last login', 'lastLogin', 15, new FormatDateTimePipe()));
	}

	getObjects(projection: TableProjection): void {
		this.filters = projection.filterObj;

		this.userService.getAllUsers(projection.getAsListRequest()).subscribe(
			value => {
				this.objects.next(value);
			}
		);
	}

	onObjectSelect(user: User): void {
		this.router.navigate([`/user/${user.id}`]);
	}
}
