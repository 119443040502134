<app-table [headers]="headers" [objects]="objects"
           (request)="getObjects($event)"
           (objectclick)="onObjectSelect($event)"
           [filterUpdate]="filterUpdate"
           [defaultFilters]="defaultFilters"
           (loading)="setLoading($event)"
           sortBy="created"
           order="DESC"
           size="10">

	<div class="col-12 text-white">
		<app-studio-filter [(form)]="filters.studio" (filterchange)="updateFilters()" [disabled]="loading"></app-studio-filter>
		<app-date-filter [(min)]="filters.mindate" [(max)]="filters.maxdate" (filterchange)="updateFilters()" [disabled]="loading"></app-date-filter>
	</div>

</app-table>
