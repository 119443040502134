import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {User} from '../../../model/domain/user';

@Injectable()
export class UserViewService {
	subject: BehaviorSubject<User> = new BehaviorSubject<User>(null);

	constructor() { }

	update(value: User): void {
		this.subject.next(value);
	}

	get(): BehaviorSubject<User> {
		return (this.subject as BehaviorSubject<User>);
	}
}
