<div class="container d-flex h-100">
	<div class="row align-self-center text-center w-100">
		<div class="col-6 mx-auto">
			<div col="6" class="pt-5 mb-5">
				<img class="img-fluid mt-5" src="assets/logo/main.png">
			</div>
			<form class="form-signin mt-5 pt-5" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
				<h3 class="h3 mb-3 font-weight-normal">Please sign in</h3>
				<label for="inputEmail" class="sr-only">Email address</label>
				<input type="email" formControlName="username" id="inputEmail" class="form-control" placeholder="Email address" required autofocus>
				<label for="inputPassword" class="sr-only">Password</label>
				<input type="password" formControlName="password" id="inputPassword" class="form-control" placeholder="Password" required>
				<span *ngIf="error" class="text-danger"><b>Error:</b> {{error}}</span>
				<button class="btn btn-lg btn-primary btn-block mt-3" type="submit" [disabled]="loading || loginForm.invalid">Sign in</button>
			</form>
		</div>
	</div>
</div>
