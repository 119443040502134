import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {Field, FormSubmission} from '../../../model/form-submission';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
	constructor() { }

	@Input()
	private fromSubmission: Observable<FormSubmission>;

	form: FormSubmission;
	message: string;
	messageType: string;
	timeout: any;

	ngOnInit(): void {
		this.fromSubmission.subscribe(result => this.updateForm(result));
	}

	updateForm(formSubmission: FormSubmission): void {
		this.form = formSubmission;
		if (this.form.success) {
			this.showMessage('Updated successfully', 'success', true);
		} else {
			this.showMessage('Failed to update', 'warning', true);
		}
	}

	getFieldErrors(name: string): string[] {
		const field = this.getField(name);
		if (field === undefined || field.errors === undefined) {
			return [];
		}

		return field.errors;
	}

	hasErrors(name: string): boolean {
		if (this.form === undefined) {
			return false;
		}

		return this.getField(name) !== undefined;
	}

	clearErrors(name: string): void {
		if (this.form === undefined || this.form.fields === undefined) {
			return;
		}

		this.form.fields = this.form.fields.filter(object => object.name !== name);
	}

	getField(name: string): Field {
		if (this.form === undefined || this.form.fields === undefined) {
			return undefined;
		}

		for (const field of this.form.fields) {
			if (field.name === name) {
				return field;
			}
		}
		return undefined;
	}

	private showMessage(message: string, type: string, autoDismiss: boolean): void {
		this.messageType = type;
		this.message = '<strong>' + moment().format('HH:mm:ss') + ' Server message:  <strong> ' + message;

		if (autoDismiss !== undefined && autoDismiss) {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => this.message = '', 5000);
		}

		window.scrollTo(0, 0);
	}

}
