import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {DateUtil} from '../../../../core/util/date.util';

@Component({
	selector: 'app-date-filter',
	templateUrl: './table-date-filter.component.html',
	styleUrls: ['./table-date-filter.component.scss']
})
export class TableDateFilterComponent implements OnInit {
	@Input() disabled = false;
	@Output() minChange = new EventEmitter<any>();
	@Output() maxChange = new EventEmitter<any>();
	@Output() filterchange = new EventEmitter<any>();

	minDate: NgbDate;
	maxDate: NgbDate;

	constructor() { }

	ngOnInit(): void {
	}

	@Input()
	set min(value: string) {
		this.minDate = DateUtil.getNgbDateFromString(value);
	}

	@Input()
	set max(value: string) {
		this.maxDate = DateUtil.getNgbDateFromString(value);
	}

	selectMinDate(): void {
		if (this.minDate) {
			this.minChange.emit(DateUtil.getMomentFromNgbDate(this.minDate).valueOf());
		} else {
			this.minChange.emit(undefined);
		}
		this.filterchange.emit();
	}

	selectMaxDate(): void {
		if (this.maxDate) {
			this.maxChange.emit(DateUtil.getMomentFromNgbDate(this.maxDate).valueOf());
		} else {
			this.maxChange.emit(undefined);
		}
		this.filterchange.emit();
	}
}
