<div class="modal-header">
	<h4 class="modal-title"><span class="fa fa-check mr-2 text-center"></span> Confirm</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" tabindex="-1">
		&times;
	</button>
</div>

<div class="modal-body">
	{{message}}
</div>

<div class="modal-footer">
	<button type="button" class="btn btn-danger mr-auto" (click)="cancel()">Cancel</button>
	<label class="mr-auto"></label>
	<button type="button" class="btn btn-success" (click)="confirm()">Confirm
	</button>
</div>
