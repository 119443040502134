import {Component, DEFAULT_CURRENCY_CODE} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'appointer-admin-web';
}
