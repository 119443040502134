<app-page name="Orders">
	<app-table [headers]="headers" [objects]="objects"
	           (request)="getObjects($event)"
	           (objectclick)="onObjectSelect($event)"
	           [filterUpdate]="filterUpdate"
	           [defaultFilters]="defaultFilters"
	           (loading)="setLoading($event)"
	           sortBy="created"
	           order="DESC"
	           size="10">

		<div class="col-12 text-white">
			<button class="btn btn-success mr-2" [routerLink]="'/order/new'"><i class="fa fa-plus-circle"></i> Add order</button>

			<app-studio-filter [(form)]="filters.studio" (filterchange)="updateFilters()" [disabled]="loading"></app-studio-filter>

			<select class="custom-select mr-2" style="width:auto;" name="status" [(ngModel)]="filters.status" (change)="updateFilters()"
			        [disabled]="loading">
				<option value="any">All orders</option>
				<option value="open">Open orders</option>
				<option value="closed">Closed orders</option>
				<option value="cancelled">Cancelled orders</option>
			</select>

			<app-date-filter [(min)]="filters.mindate" [(max)]="filters.maxdate" (filterchange)="updateFilters()" [disabled]="loading"></app-date-filter>
		</div>
	</app-table>
</app-page>
