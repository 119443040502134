import {Component, OnInit} from '@angular/core';
import {User} from '../../../../model/domain/user';
import {UserViewService} from '../user.view.service';

@Component({
	selector: 'app-user-overview',
	templateUrl: './user-overview.component.html',
	styleUrls: ['./user-overview.component.scss']
})
export class UserOverviewComponent implements OnInit {
	user: User;

	constructor(
		private userViewService: UserViewService
	) {}

	ngOnInit(): void {
		this.userViewService.get().subscribe(user => this.user = user);
	}
}
