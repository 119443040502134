<div class="modal-header">
	<h4 class="modal-title"><span class="fa fa-times-circle mr-2 text-center"></span> Cancel / refund order</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" tabindex="-1">
		&times;
	</button>
</div>

<table class="modal-body border-bottom-dark table table-striped mb-0" *ngIf="items">
	<thead>
	<tr>
		<th></th>
		<th>Item</th>
		<th class="text-center">Order quantity</th>
		<th>Price</th>
		<th class="text-center">Refund quantity</th>
		<th>Refund value</th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let refund of refundItems">
		<td>
			<input type="checkbox" class="custom-checkbox" [(ngModel)]="refund.refund" [name]="'check' + refund.item.id">
		</td>

		<td (click)="refund.refund = !refund.refund" [class.text-danger]="refund.value > refund.item.quantity * refund.item.price" [innerHTML]="refund.item | orderItemName">
		</td>

		<td class="text-center" [class.text-danger]="refund.value > refund.item.quantity * refund.item.price">
			{{refund.item.quantity}}
		</td>

		<td [class.text-danger]="refund.value > refund.item.quantity * refund.item.price">
			{{refund.item.price * refund.item.quantity | currency:"EUR"}}
		</td>

		<td *ngIf="refund.item.quantity > 1 && refund.refund">
			<input type="number" class="form-control text-center" min="1"
			       [(ngModel)]="refund.quantity"
			       [max]="refund.item.quantity"
			       [disabled]="!refund.refund"
			       (blur)="refund.value = refund.quantity * refund.item.price"
			>
		</td>
		<td *ngIf="refund.item.quantity === 1 || !refund.refund" class="text-center">{{refund.item.quantity}}</td>
		<td>
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text" id="basic-addon1">&euro;</span>
				</div>
				<input type="number" class="form-control" min="0"
				       [max]="refund.quantity * refund.item.price"
				       [(ngModel)]="refund.value"
				       [disabled]="!refund.refund">
			</div>
		</td>
	</tr>
	</tbody>
</table>
<hr class="m-0 p-0">
<div class="modal-body text-right" style="font-size: larger">
	<b>Total cancel / refund value is {{refundValue() | currency:"EUR"}}</b>
	<br/><br/>

	<div class="text-warning text-center" *ngIf="payments.length > 1 && refundValue() > 0">
		<i class="fa fa-warning"></i> Found multiple payments for this order <i class="fa fa-warning"></i> <br/>
		Please choose how to process the cancellation / refund
	</div>
</div>

<table class="modal-body border-bottom-dark table table-striped table-sm mb-0" *ngIf="payments && payments.length >1 && refundValue() > 0">
	<thead>
	<tr>
		<th>Transaction</th>
		<th>Value</th>
		<th>Refund</th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let payment of payments">
		<td [class.text-danger]="payment.refund > payment.transaction.amount">{{payment.transaction.comments}}</td>
		<td [class.text-danger]="payment.refund > payment.transaction.amount">{{payment.transaction.amount | currency:"EUR"}}</td>
		<td>
			<div class="input-group" *ngIf="payment.transaction.method !== 'DISCOUNT'">
				<div class="input-group-prepend">
					<span class="input-group-text" id="basic-addon1">&euro;</span>
				</div>
				<input type="number" class="form-control" min="0"
				       [max]="payment.transaction.amount"
				       [(ngModel)]="payment.refund"
				       [disabled]="payment.transaction.method === 'DISCOUNT'"
				>
			</div>
		</td>
	</tr>
	</tbody>
</table>
<hr class="m-0 p-0">


<div class="modal-body text-right" style="font-size: larger" *ngIf="payments && payments.length >1 && refundValue() > 0">
	<div class="row" *ngIf="refundValue() - paymentRefundTotal() > 0">
		<div class="col-9">
			Remaining
		</div>
		<div class="col-3 ">
			{{refundValue() - paymentRefundTotal() | currency:"EUR"}}
		</div>
	</div>

	<div class="row">
		<div class="col-9 font-weight-bold"
		     [class.text-danger]="paymentRefundTotal() > refundValue()"
		     [class.text-success]="paymentRefundTotal() ==		refundValue()"
		>
			Total
		</div>
		<div class="col-3 font-weight-bold"
		     [class.text-danger]="paymentRefundTotal() > refundValue()"
		     [class.text-success]="paymentRefundTotal() ==		refundValue()"
		>
			{{paymentRefundTotal() | currency:"EUR"}}
		</div>
	</div>
</div>


<div class="modal-footer">
	<button type="button" class="btn btn-danger mr-auto" (click)="activeModal.close()">Cancel</button>
	<label class="mr-auto"></label>
	<button type="button" class="btn btn-success" [disabled]="!canSave()" (click)="save()">Save
	</button>
</div>
