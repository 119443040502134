import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-order-add-payment',
	templateUrl: './order-add-payment.component.html',
	styleUrls: ['./order-add-payment.component.scss']
})
export class OrderAddPaymentComponent implements OnInit {
	amount: number;
	method: string;

	constructor(
		public activeModal: NgbActiveModal
	) { }

	ngOnInit(): void {
	}

	canSave(): boolean {
		return this.method !== undefined && this.amount > 0 && !isNaN(this.amount);
	}

	save(): void {
		let title = '';
		if (this.method === 'cash') {
			title = 'Cash payment';
		} else if (this.method === 'card') {
			title = 'PIN / Credit card payment';
		}
		else{
			return;
		}
		this.activeModal.close({
			method: this.method,
			amount: Number(this.amount),
			title
		});
	}
}
