import {Pipe, PipeTransform} from '@angular/core';
import {FilterModel} from '../../model/filter-model';
import {Listrequest} from '../../model/listrequest';

export class TableProjection {
	page: number;
	total: number;
	size: number;
	sort: string;
	order: string;
	filters: string[];
	filterObj: FilterModel;

	constructor(page: number, size: number, sort: string, order: 'ASC' | 'DESC', filters: string[]) {
		this.page = page;
		this.total = 0;
		this.size = size;
		this.sort = sort;
		this.order = order;
		this.filters = filters;

		if (this.filters === undefined) {
			this.filters = new Array();
		}
	}

	addFilter(field: string, value: any): void {
		//Remove current filter if already exists
		let i = this.filters.length;
		while (i--) {
			if (this.filters[i] === undefined) {
				continue;
			}
			if (this.filters[i].startsWith(field)) {
				this.filters.splice(i, 1);
			}
		}
		if (value !== '') {
			this.filters.push(field + ':' + value);
		}
	}

	getAsListRequest(): Listrequest {
		return new Listrequest(Math.max(0, this.page - 1), this.size, this.sort, this.order, this.filters);
	}
}

