<div class="card-deck mt-3 ">
	<div class="card shadow-sm" *ngFor="let account of artist.accounts">
		<div class="card-header"
		     [class.bg-gradient-primary]="account.name === 'BTW'"
		     [class.bg-gradient-success]="account.name==='Main'"
		     [class.bg-gradient-seconday]="account.name==='Comp'"
		     [class.text-light]="account.name === 'BTW' || account.name === 'Main'">
			<i class="fa fa-list-alt"></i> {{account.name}} account
		</div>
		<div class="card-body">
			Balance: <strong>{{account.currentBalance | currency}}</strong> <br/>
			<!--			Last payout: 22 October, 2020 <br/>-->
			<!--			Transactions since last payout: 38 <br/>-->
			<a [routerLink]="['/artist' , artist.id, 'transactions']" [queryParams]=" {filters: 'account:' + account.id}"><i class="fa fa-arrow-right"></i>
				See all transactions</a><br/>
			<br/>

			<div class="row">
				<div class="col-6">
					<button class="btn btn-light btn-outline-success rounded btn" (click)="addPayout(account)"><span
						class="fa fa-arrow-circle-right mr-2"></span>
						New
						payout
					</button>
				</div>

				<div class="col-6 text-right">
					<button class="btn btn-light btn-outline-warning rounded btn" (click)="addCorrection(account)"><span class="fa fa-wrench mr-2"></span> Add
						correction
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
