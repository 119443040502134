<label *ngIf="desc">{{desc}}</label>
<div class="custom-control custom-checkbox">
	<input type="checkbox"
	       ngDefaultControl
	       class="custom-control-input"
	       [ngClass]="{'is-invalid': form.hasErrors(name)}"
	       [id]="name"
	       [name]="name"
	       (input)="form.clearErrors(name)"
	       [(ngModel)]="value"
	       (change)="change()"

	       required/>
	<label class="custom-control-label" [for]="name">{{label}}</label>

	<div class="invalid-feedback">
		<span *ngFor="let error of form.getFieldErrors(name)">{{error}}<br/></span>
	</div>
</div>
