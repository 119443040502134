import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MultiPayoutModel} from '../../module/multi-artist-payout/multi-payout.model';

@Injectable({
	providedIn: 'root'
})
export class MultiPayoutService {
	private url = 'api/multipayout';

	constructor(
		private http: HttpClient
	) {}

	getPayoutModel(startDate: number, endDate: number): Observable<MultiPayoutModel> {
		const params = new HttpParams()
			.set('start', String(startDate))
			.set('end', String(endDate));
		return this.http.get<MultiPayoutModel>(this.url, {params});
	}

	processPayoutModel(model: MultiPayoutModel): Observable<MultiPayoutModel> {
		return this.http.post<MultiPayoutModel>(this.url, model);
	}
}
