<app-page name="Transactions">
		<app-table [headers]="headers" [objects]="objects"
		           (request)="getObjects($event)"
		           (objectclick)="onObjectSelect($event)"
		           [filterUpdate]="filterUpdate"
		           [defaultFilters]="defaultFilters"
		           (loading)="setLoading($event)"
		           sortBy="created"
		           order="DESC"
		           size="10">

			<div class="col-12 text-white">
				<app-studio-filter [(form)]="filters.studio" (filterchange)="updateFilters()" [disabled]="loading"></app-studio-filter>

				<select class="custom-select mr-2" style="width:auto;" name="status" [(ngModel)]="filters.method" (change)="updateFilters()" [disabled]="loading">
					<option value="any">All transactions</option>
					<option value="cash">Cash</option>
					<option value="card">Card</option>
					<option value="giftcard">Giftcard</option>
					<option value="discount">Discount</option>
					<option value="refund">Refund</option>
				</select>
				<app-date-filter [(min)]="filters.mindate" [(max)]="filters.maxdate" (filterchange)="updateFilters()" [disabled]="loading"></app-date-filter>
			</div>
		</app-table>
</app-page>
