<app-page name="Artist payout">
	<div class="shadow">
		<div class="card-body p-0">
			<div class="row bg-gradient-primary m-0 p-3 text-light justify-content-center rounded-top" style="font-size: larger">
				<div class="col-auto text-center" *ngIf="!result">
					<h5 class="text-white">Date range</h5>
					<form class="form-inline">
						<div class="form-group hidden">
							<div class="input-group">
								<input name="datepicker"
								       class="form-control"
								       ngbDatepicker
								       #datepicker="ngbDatepicker"
								       [autoClose]="'outside'"
								       (dateSelect)="onDateSelection($event, datepicker)"
								       [displayMonths]="2"
								       [dayTemplate]="t"
								       outsideDays="hidden"
								       [startDate]="start!"
								       tabindex="-1">
								<ng-template #t let-date let-focused="focused">
									        <span class="custom-day"
									              [class.focused]="focused"
									              [class.range]="isRange(date)"
									              [class.faded]="isHovered(date) || isInside(date)"
									              (mouseenter)="hoveredDate = date"
									              (mouseleave)="hoveredDate = null">
									          {{ date.day }}
									        </span>
								</ng-template>
							</div>
						</div>
						<div class="form-group">
							<div class="input-group">
								<input #dpFromDate
								       class="form-control" placeholder="dd/mm/yyyy"
								       name="dpFromDate"
								       [value]="formatter.format(start)"
								       (input)="start = validateInput(start, dpFromDate.value)"
								       [disabled]="saving">
								<div class="input-group-append">
									<button class="btn btn-light calendar fa fa-calendar" (click)="datepicker.toggle()" type="button"
									        [attr.disabled]="saving ? 'true' : null"></button>
								</div>
							</div>
						</div>

						<span class="fa fa-arrow-right text-white ml-2"></span>

						<div class="form-group ml-2">
							<div class="input-group">
								<input #dpToDate
								       class="form-control" placeholder="dd/mm/yyyy"
								       name="dpToDate"
								       [value]="formatter.format(end)"
								       (input)="end = validateInput(end, dpToDate.value)"
								       [disabled]="saving">
								<div class="input-group-append">
									<button class="btn btn-light calendar fa fa-calendar" (click)="datepicker.toggle()" type="button"
									        [attr.disabled]="saving ? 'true' : null"></button>
								</div>
							</div>
						</div>
					</form>
				</div>

				<div class="row p-0" *ngIf="result">
					<div class="col-12">
						<h4 class="text-center text-white p-0 m-0">
							Payouts for {{payout.startDate | formatdate}}
							<span class="fa fa-arrow-right"></span>
							{{payout.endDate | formatdate}}
							<br/>
							<i>{{payout.description}}</i>
						</h4>
					</div>
				</div>
			</div>
		</div>
		<table class="table card-body table-striped table-borderless table-hover table-lg table-responsive-lg shadow-sm" *ngIf="payout && !result && !loading">
			<thead>
			<tr class="font-weight-bold table-responsive-lg" style="border-top: hidden;">
				<td class="p-2">Pay</td>
				<td class="p-2">Artist</td>
				<td class="p-2 text-right">Earned on selected dates</td>
				<td class="p-2 text-right">Current <span class="text-success">Main account</span> balance</td>
				<td class="p-2 text-right">Balance after payout</td>
				<td class="p-2 text-right">Payout</td>
			</tr>
			</thead>
			<tbody>
			<tr *ngFor="let artist of payout.artists">
				<td class="p-2 align-middle">
					<input type="checkbox" [(ngModel)]="artist.enabled" [disabled]="saving">
				</td>
				<td class="p-2 align-middle" [class.text-muted]="!artist.enabled" (click)="artist.enabled = !artist.enabled">
					{{artist.artist | artistname}}
				</td>
				<td class="p-2 text-right align-middle" [class.text-muted]="!artist.enabled">
					{{artist.earned | currency}}
				</td>
				<td class="p-2 text-right align-middle" [class.text-muted]="!artist.enabled">
					{{artist.inAcc | currency}}
				</td>

				<td class="text-right p-2 align-middle" [class.text-danger]="artist.inAcc - artist.payout < 0">
					{{artist.inAcc - artist.payout | currency}}
				</td>
				<td class="p-2" style="width: 20%">
					<div class="input-group">
						<div class="input-group-prepend">
							<span class="input-group-text" id="basic-addon1">&euro;</span>
						</div>
						<input type="number" class="form-control text-right" [(ngModel)]="artist.payout" autofocus [disabled]="!artist.enabled || saving">
					</div>
				</td>

			</tr>
			</tbody>
		</table>

		<table class="table table-borderless table-striped table-hover table-lg table-responsive-lg card-body mb-0 shadow-sm"
		       style="font-size: larger"
		       *ngIf="payout && result && !loading">
			<thead>
			<tr class="font-weight-bold">
				<td class="p-2">Artist</td>
				<td class="p-2">Full name</td>
				<td class="p-2">Company name</td>
				<td class="p-2">Bank account</td>
				<td class="p-2 text-right">Payout</td>
			</tr>
			</thead>
			<tbody>
			<ng-container *ngFor="let artist of payout.artists">
				<tr *ngIf="artist.enabled && artist.payout > 0">
					<td class="p-2">{{artist.artist | artistname}}</td>
					<td class="p-2">{{artist.artist.fullname}}</td>
					<td class="p-2">{{artist.artist.commerceName}}</td>
					<td class="p-2">{{artist.artist.iban}}</td>
					<td class="p-2 text-right">{{artist.payout | currency}}</td>
				</tr>
			</ng-container>
			</tbody>
		</table>

		<div class="row mt-2" *ngIf="loading">
			<div class="col-12 text-center mt-0 pt-0 mb-3 mt-3">
				<div class="spinner-border text-primary" role="status">
					<span class="sr-only">Loading...</span>
				</div>
			</div>
		</div>
	</div>

	<div class="row mt-2 mb-4" *ngIf="!loading && payout && !result">
		<div class="col-6 input-group-lg">
			<input type="text" class="form-control" placeholder="Payout description..." [(ngModel)]="payout.description">
		</div>
		<div class="col-6 text-right">
			<form class="form-inline pull-right">
				<button class="btn btn-lg btn-success ml-2" [disabled]="!canSave()" (click)="save()"><i class="fa fa-arrow-circle-right"></i> Perform
					payouts
				</button>
			</form>
		</div>
	</div>

</app-page>
