import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalService} from '../../../../core/service/modalservice';
import {ArtistAccountCorrectionComponent} from '../artist-account-correction/artist-account-correction.component';
import {ArtistViewService} from '../artist.view.service';
import {ArtistAccount} from '../../../../model/domain/artist-account';
import {Artist} from '../../../../model/domain/artist';
import {AccountTransaction} from '../../../../model/domain/account-transaction';

@Component({
	selector: 'app-artist-accounts',
	templateUrl: './artist-account.component.html',
	styleUrls: ['./artist-account.component.scss']
})
export class ArtistAccountComponent implements OnInit {
	artist: Artist;
	transactions: AccountTransaction[];

	constructor(
		private activeRoute: ActivatedRoute,
		private router: Router,
		private artistViewService: ArtistViewService,
		private modalService: ModalService
	) {}

	ngOnInit(): void {
		this.artistViewService.get().subscribe((artist) => {
			this.artist = artist;
		});
	}

	addCorrection(account: ArtistAccount): void {
		const modelRef = this.modalService.open(ArtistAccountCorrectionComponent);
		modelRef.componentInstance.artist = this.artist;
		modelRef.componentInstance.account = account;

		modelRef.result.then(value => {
			for (let i = 0; i < this.artist.accounts.length; i++) {
				if (this.artist.accounts[i].id === value.id) {
					this.artist.accounts[i] = value;
				}
			}
		}).catch(value => value);
	}

	addPayout(account: ArtistAccount): void {
		this.router.navigateByUrl(`artist/${this.artist.id}/payout?account=${account.id}`);
	}
}
