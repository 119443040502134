import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Piercing} from '../../model/domain/piercing';
import {Paginationmodel} from '../../model/paginationmodel';

@Injectable({
	providedIn: 'root'
})
export class PiercingService {

	private url = 'api/pospiercing/';
	private urlAll = this.url + 'list';

	constructor(
		private http: HttpClient
	) {}

	getAll(): Observable<Piercing[]> {
		return this.http.get<Piercing[]>(this.url + 'all');
	}

	getPage(page: number): Observable<Paginationmodel> {
		const params = new HttpParams()
			.set('page', String(page));
		return this.http.get<Paginationmodel>(this.urlAll, {params});
	}

	getById(id: number): Observable<Piercing> {
		return this.http.get<Piercing>(this.url + id + '/');
	}

	editPiercing(piercing: Piercing): Observable<Piercing> {
		return this.http.post<Piercing>(`${this.url}${piercing.id}/edit`, piercing);
	}
}
