import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {AuthUser} from '../../model/authuser';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
	private currentUserSubject: BehaviorSubject<AuthUser>;
	public currentUser: Observable<AuthUser>;

	constructor(private http: HttpClient) {
		this.currentUserSubject = new BehaviorSubject<AuthUser>(JSON.parse(localStorage.getItem('currentUser')));
		this.currentUser = this.currentUserSubject.asObservable();
	}

	public get currentUserValue(): AuthUser {
		return this.currentUserSubject.value;
	}

	login(username: string, password: string): Observable<AuthUser> {
		return this.http.post<any>(`/api/login`, {username, password})
			.pipe(map(user => {
				localStorage.setItem('currentUser', JSON.stringify(user));
				this.currentUserSubject.next(user);
				return user;
			}));
	}

	logout(): void {
		localStorage.removeItem('currentUser');
		this.currentUserSubject.next(null);
	}
}
