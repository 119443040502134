import {BehaviorSubject} from 'rxjs';
import {FilterModel} from '../../model/filter-model';
import {Paginationmodel} from '../../model/paginationmodel';
import {TableColumn} from './table-column-model';
import {TableProjection} from './table-projection';

export abstract class AbstractTableViewComponent<T> {
	public headers: TableColumn[] = new Array();
	public objects: BehaviorSubject<Paginationmodel>;
	public filters: FilterModel;
	public defaultFilters: FilterModel;
	public filterUpdate: BehaviorSubject<object>;
	public loading: boolean;

	abstract getObjects(projection: TableProjection): void;

	abstract onObjectSelect(object: T): void;

	constructor() {
		this.filters = new FilterModel();
		this.defaultFilters = new FilterModel();
		this.objects = new BehaviorSubject<Paginationmodel>(new Paginationmodel());
		this.filterUpdate = new BehaviorSubject<object>(null);
	}

	setLoading(loading: boolean): void {
		this.loading = loading;
	}

	updateFilters(): void {
		this.filterUpdate.next(this.filters);
	}

}
