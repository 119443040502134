import {Component, EventEmitter, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormSubmission} from '../../../model/form-submission';
import {ArtistService} from '../../../core/service/artist.service';
import * as countries from '../../../conf/countries';
import {Artist} from '../../../model/domain/artist';

@Component({
	selector: 'app-artist-new',
	templateUrl: './artist-new.component.html',
	styleUrls: ['./artist-new.component.scss']
})
export class ArtistNewComponent implements OnInit {
	countries = countries.countries;
	artist: Artist;
	loading = false;
	result: FormSubmission;
	formState = new EventEmitter<FormSubmission>();

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private artistService: ArtistService
	) {}

	save(): void {
		this.loading = true;
		this.artistService.newArtist(this.artist).subscribe(
			form => {
				if (form.success) {
					this.router.navigateByUrl(`/artist/${form.object.id}`);
				} else {
					this.loading = false;
					this.formState.next(form);
					this.result = form;
					this.result.object.artistType = this.capitalizeFirstLetter(this.result.object.artistType);
				}
			}
		);
	}

	ngOnInit(): void {
		this.artist = {
			accounts: [],
			active: false,
			address: {
				id: 0,
				created: 0,
				modified: 0,
				city: '',
				country: '',
				number: '',
				street: '',
				zip: ''
			},
			artistType: 'Tattoo',
			bsn: '',
			commerceId: '',
			commerceName: '',
			iban: '',
			created: 0,
			email: '',
			firstname: '',
			id: 0,
			lastname: '',
			modified: 0,
			nationality: '',
			fullname: '',
			nickname: '',
			phone: '',
			studios: []
		};
	}

	capitalizeFirstLetter(text: string): string {
		return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
	}
}
