<div class="card mt-3 shadow" *ngIf="order">
	<div class="card-header text-white bg-gradient-primary">
		<h2 class="card-title text-center">Order #{{order.id}} <span *ngIf="order.status === 'CANCELLED'">(Cancelled)</span></h2>
	</div>
	<div class="card-body">
		<div class="row">
			<div class="col-8 offset-2 text-left">
				<div class="row">
					<div class="col-8">
						Order fulfilled on <b>{{order.date | formatdatetime}}</b> by
						<i><a [routerLink]="['/user' , order.user.id]">{{order.user.name}}</a></i>
					</div>
					<div class="col-4 text-right">
						{{order.studio.name}}
					</div>
				</div>
				<br/>
				<div class="card">
					<div class="card-header">
						Order items
					</div>
					<table class="table table-hover table-md rounded card-body">
						<tbody>
						<tr *ngFor="let item of items" [class.text-danger]="item.refunded">
							<td *ngIf="item.type === 'PRODUCT' || item.type === 'GIFTCARD'" [innerHTML]="item | orderItemName"></td>
							<td *ngIf="item.type === 'PIERCING' && item.piercingTransaction">
								<a [routerLink]="['/piercing', item.piercingTransaction.id]" [innerHTML]="item | orderItemName"></a>
							</td>
							<td *ngIf="item.type === 'PIERCING' && !item.piercingTransaction" [innerHTML]="item | orderItemName">
							</td>
							<td *ngIf="item.type === 'TATTOO'">
								<a [routerLink]="['/tattoo' , item.tattoo.id]" [innerHTML]="item | orderItemName"></a>
							</td>
							<td *ngIf="item.type === 'TATTOO_DOWNPAYMENT'">
								<a [routerLink]="['/tattoo' , item.tattoo.id]" [innerHTML]="item | orderItemName"></a>
							</td>

							<!-- Quantity -->
							<td>
								<span *ngIf="item.quantity > 1">
									{{item.quantity}}x
								</span>
							</td>

							<!-- Price -->
							<td>
								<span *ngIf="item.quantity > 1">
									{{item.price | currency:"EUR"}}
								</span>
							</td>

							<td class="text-right">
								{{item.price * item.quantity | currency:"EUR"}}
							</td>
						</tr>
						</tbody>
						<tfoot>
						<tr>
							<td></td>
							<td></td>
							<td class="text-right"><strong>Total</strong></td>
							<td class="text-right"><strong>{{order.price | currency:"EUR"}}</strong></td>
						</tr>
						</tfoot>
					</table>
				</div>
				<div class="text-right mt-3 mb-3" *ngIf="order.status === 'CLOSED'">
					<button class="btn btn-warning ml-3" (click)="refundOrder()">
						<i class="fa fa-undo"></i> Partial cancel / refund
					</button>

					<button class="btn btn-danger ml-3" (click)="cancelOrder()">
						<i class="fa fa-times-circle"></i> Full cancel / refund
					</button>
				</div>

				<div class="card" *ngIf="transactions && transactions.length > 0">
					<div class="card-header">
						Transactions
					</div>
					<table class="table table-hover table-md rounded card-body">
						<tr class="d-flex">
							<!--							<th class="col-2"></th>-->
							<th class="col-3">Date</th>
							<th class="col-2">User</th>
							<th class="col-2">Method</th>
							<th class="col-2">Description</th>
							<th class="col-3 text-right">Amount</th>
						</tr>
						<tbody>
						<tr class="d-flex" *ngFor="let transaction of transactions" [class.text-warning]="transaction.method === 'DISCOUNT'">
							<!--							<td class="col-2 text-success" *ngIf="transaction.status === 'SUCCESS'">{{transaction.status | titlecase}}</td>-->
							<!--							<td class="col-2 text-danger" *ngIf="transaction.status !== 'SUCCESS'">{{transaction.status | titlecase}}</td>-->
							<td class="col-3">{{transaction.paydate | formatdatetime}}</td>
							<td class="col-2">
								<a [routerLink]="['/user', order.user.id]">
									{{transaction.user.name}}
								</a>
							</td>
							<td class="col-2">{{transaction.method | titlecase}}</td>
							<td class="col-2">{{transaction.userComments}}</td>
							<td class="col-3 text-right">{{transaction.amount | currency:"EUR"}}</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>
