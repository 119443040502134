import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Product} from '../../../../model/domain/product';
import {ProductService} from '../../../../core/service/product.service';

@Component({
	selector: 'app-product-edit',
	templateUrl: './product-edit.component.html',
	styleUrls: ['./product-edit.component.scss']
})
export class ProductEditComponent implements OnInit {
	loading: boolean;
	product: Product;
	form: Product;

	constructor(
		public activeModal: NgbActiveModal,
		private productService: ProductService
	) {}

	ngOnInit(): void {
	}

	canSave(): boolean {
		if (this.loading) {
			return false;
		}

		return true;
	}

	save(): void {
		this.loading = true;
		this.productService.editProduct(this.form).subscribe(
			result => {
				this.activeModal.close(result);
			}
		);
	}

	setProduct(product: Product): void {
		this.product = product;
		this.form = JSON.parse(JSON.stringify(product));
	}
}
