<div class="modal-header">
	<h4 class="modal-title"><span class="fa fa-plus"></span> Add studio</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" tabindex="-1">
		&times;
	</button>
</div>

<div class="modal-body" *ngIf="studios">
	<div class="row">
		<div class="col-6">
			<label for="studios">Studio</label>
			<select id="studios" class="form-control" [(ngModel)]="form.studio" (change)="onChange(form.studio)">
				<option *ngFor="let studio of studios" [ngValue]="studio">{{studio.name}}</option>
			</select>
		</div>

		<div class="col-6">
			<label>Available agenda colors</label>

			<div *ngIf="colors.length == 0">
				<span class="text-danger">No more colors left, remove other artists from this studio first</span>
			</div>

			<div class="row">
				<div class="col-12" *ngFor="let color of colors">
					<div class="custom-control custom-radio mt-1 mb-1">
						<input type="radio" [(ngModel)]="form.color" class="custom-control-input" name="color" [id]="color" [value]="color">

						<label class="custom-control-label" [for]="color">
							<div class="col-2">
								<div class="colorselect" [style]="'background-color: #' + color"></div>
							</div>
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>


	<div class="modal-footer">
		<button type="button" class="btn btn-danger mr-auto" (click)="activeModal.close('Close click')">Cancel</button>
		<label class="mr-auto"></label>
		<button type="button" class="btn btn-success" [disabled]="!canSave()" (click)="save()">Save</button>
	</div>
</div>
