<div class="mt-3 shadow">
	<div *ngIf="piercing" class="card">
		<div class="card-header bg-gradient-primary text-white">
			<h2 class="card-title text-center">Details for piercing #{{piercing.id}}</h2>
		</div>
		<div class="card-body">
			<br/>
			<div class="row row-cols-2 row-cols-md-2">
				<div class="col">
					<div class="card ">
						<div class="card-header">
							<h5><i class="fa fa-info-circle"></i> Piercing information</h5>
						</div>
						<div class="card-body text-left">
							<a [routerLink]="['/artist', piercing.artist.id]">{{piercing.artist | artistname}}</a> pierced

							<strong>
								<span *ngIf="piercing.quantity <= 1">a</span>
								<span *ngIf="piercing.quantity > 1">{{piercing.quantity}}</span>

								{{piercing.piercing.name}}
							</strong>
							for
							{{piercing.price | currency}}<br/>
							<br/>
							<span *ngIf="piercing.order">
								<a [routerLink]="['/order', piercing.order.id]">Order #{{piercing.order.id}}</a>
							</span>
						</div>
						<div class="card-footer">
							<span class="text-muted">
								Booked by
							<a [routerLink]="['/user', piercing.owner.id]">{{piercing.owner.name}}</a> on {{piercing.created | formatdatetime}}
							</span>
						</div>
					</div>
				</div>

				<div class="col">
					<div class="card">
						<div class="card-header">
							<h5><i class="fa fa-exchange"></i> Transactions to
								<a [routerLink]="['/artist', piercing.artist.id, 'status']">{{piercing.artist | artistname}}'s</a> accounts
							</h5>
						</div>

						<div class="card-body">
							Artist share for this piercing is calculated at <strong>{{piercing.artistSharePerc}}%</strong> <br/><br/>

							<table class="col-6 table table-sm table-borderless">
								<tr *ngFor="let transaction of piercing.transactions">
									<td>{{transaction.account.name}} account</td>
									<td>{{transaction.amount | currency}}</td>
								</tr>
								<tr class="font-weight-bold">
									<td>Total</td>
									<td>{{piercing.totalArtistTransaction | currency}}</td>
								</tr>
							</table>
						</div>
						<div class="card-footer">
							<div class="row">
								<div class="col-6">
									<button class="btn btn-primary" (click)="changeArtist()"><span class="fa fa-user mr-2"></span> Modify Artist</button>
								</div>
								<div class="col-6 text-right">
									<button class="btn btn-primary" (click)="changePercentage()"><span class="fa fa-percent mr-2"></span> Modify percentage
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
