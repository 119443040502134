<div class="modal-header">
	<h4 class="modal-title"><span class="fa fa-plus-circle mr-2 text-center"></span> Add tattoo</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" tabindex="-1">
		&times;
	</button>
</div>

<div class="modal-body">
	<form>
		<div class="form-group">
			<label for="artist">Artist</label>
			<select id="artist" [(ngModel)]="selectedArtist" class="form-control" name="artist">
				<option *ngFor="let artist of artists" [value]="artist.id">
					{{artist.nickname}}
				</option>
			</select>
		</div>

		<div class="form-group">
			<label for="price">Price</label>
			<div class="input-group mb-2">
				<div class="input-group-prepend">
					<div class="input-group-text">&euro;</div>
				</div>
				<input type="text" class="form-control" id="price" placeholder="Price" name="price" [(ngModel)]="price">
			</div>
		</div>
	</form>


	<div class="row">
		<div class="col-4">
			<button class="btn btn-danger " (click)="activeModal.close()">Cancel</button>
		</div>
		<div class="offset-4 col-4 text-right">
			<button class="btn btn-success" type="submit" [disabled]="!canSave()" (click)="save()">Add tattoo</button>
		</div>
	</div>
</div>
