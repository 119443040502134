import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Artist} from '../../../../model/domain/artist';
import {PiercingModel} from '../../../../model/domain/piercing-model';
import {ArtistService} from '../../../../core/service/artist.service';
import {PiercingTransactionService} from '../../../../core/service/piercing-transaction.service';

@Component({
	selector: 'app-modify-artist-piercing',
	templateUrl: './modify-artist-piercing.component.html',
	styleUrls: ['./modify-artist-piercing.component.scss']
})
export class ModifyArtistPiercingComponent implements OnInit {
	piercing: PiercingModel;
	artists: Artist[];
	loading = false;
	selected = 0;

	constructor(
		public activeModal: NgbActiveModal,
		private artistService: ArtistService,
		private piercingService: PiercingTransactionService
	) {}

	ngOnInit(): void {
		this.artistService.getAllPiercingArtists().subscribe(value => this.artists = value);
	}

	setPiercing(piercing: PiercingModel): void {
		this.piercing = piercing;
		this.selected = piercing.artist.id;
	}

	canSave(): boolean {
		if (this.loading) {
			return false;
		}
		if (this.selected > 0 && this.selected !== this.piercing.artist.id) {
			return true;
		}
		return false;
	}

	save(): void {
		this.loading = true;
		this.piercingService.changeArtist(this.piercing, this.selected).subscribe(tattoo => this.activeModal.close(tattoo));
	}
}
