<app-page name="Users">
	<app-table [headers]="headers" [objects]="objects"
	           (request)="getObjects($event)"
	           (objectclick)="onObjectSelect($event)"
	           [filterUpdate]="filterUpdate"
	           [defaultFilters]="defaultFilters"
	           (loading)="setLoading($event)"
	           sortBy="name"
	           order="ASC"
	           size="10">


		<div class="col-6 text-white">
			<select class="custom-select mr-2" style="width:auto;" [(ngModel)]="filters.access" (change)="updateFilters()" [disabled]="loading">
				<option ngValue="any">All access levels</option>
				<option value="agenda">Agenda only</option>
				<option value="admin">Admin & agenda</option>
			</select>

			<select class="custom-select col-auto" style="width:auto;" [(ngModel)]="filters.active" (change)="updateFilters()" [disabled]="loading">
				<option ngValue="any">All users</option>
				<option value="true">Active only</option>
				<option value="false">Inactive only</option>
			</select>
		</div>
		<div class="col-6 text-right">
			<button class="btn btn-success" [routerLink]="'/user/new'"><i class="fa fa-plus-circle"></i> Add new user</button>
		</div>
	</app-table>
</app-page>
