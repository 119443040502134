<div class="row p-0 m-0">
	<div class="container">
		<app-table [headers]="headers" [objects]="objects"
		           (request)="getObjects($event)"
		           (objectclick)="onObjectSelect($event)"
		           [filterUpdate]="filterUpdate"
		           [defaultFilters]="defaultFilters"
		           (loading)="setLoading($event)"
		           sortBy="created"
		           order="DESC"
		           size="10">

			<div class="col-12 text-white">
				<select class="mr-2 custom-select col-auto" style="width:auto;" [(ngModel)]="filters.account" (change)="updateFilters()"
				        *ngIf="artist.accounts.length > 0" [disabled]="loading">
					<option value="any">All accounts</option>
					<option *ngFor="let account of artist.accounts" [value]="account.id">{{account.name}}</option>
				</select>

				<select class="custom-select mr-2" style="width:auto;" [(ngModel)]="filters.type" (change)="updateFilters()" [disabled]="loading">
					<option value="any">All transactions</option>
					<option value="earning">Earnings</option>
					<option value="payout">Payouts</option>
					<option value="correction">Corrections</option>
				</select>

				<app-date-filter [(min)]="filters.mindate" [(max)]="filters.maxdate" (filterchange)="updateFilters()" [disabled]="loading"></app-date-filter>
			</div>

		</app-table>
	</div>
</div>
