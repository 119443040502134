import {Pipe, PipeTransform} from '@angular/core';
import {ArtistAccountTransaction} from '../../model/domain/artist-account-transaction';
import {FullObjectPipe} from './full-object-pipe';


@Pipe({
	name: 'transaction-desc'
})
export class TransactionDescPipe implements PipeTransform, FullObjectPipe{
	fullObjectPipe = true;

	constructor() {}

	transform(transaction: ArtistAccountTransaction): any {
		if (transaction.document !== undefined && transaction.document > 0) {
			return '<i class="fa fa-file-o"></i> ' + transaction.description;
		}

		return transaction.description;
	}
}
