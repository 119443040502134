import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Artist} from '../../../../model/domain/artist';
import {StudioColor} from '../../../../model/studioColor';
import {ArtistService} from '../../../../core/service/artist.service';
import {ArtistViewService} from '../artist.view.service';

@Component({
	selector: 'app-artist-studio-new',
	templateUrl: './artist-studio-new.component.html',
	styleUrls: ['./artist-studio-new.component.scss']
})
export class ArtistStudioNewComponent implements OnInit {
	studios: StudioColor[];
	colors: string[];
	artist: Artist;
	loading = false;
	form = {
		studio: undefined,
		color: ''
	};

	constructor(
		public activeModal: NgbActiveModal,
		private artistViewService: ArtistViewService,
		private artistService: ArtistService,
	) {}

	ngOnInit(): void {
		this.artistViewService.get().subscribe(artist => {
				this.artist = artist;
				this.artistService.getAvailableStudiosForArtist(this.artist).subscribe(
					studios => {
						this.studios = studios;
						if (studios.length > 0) {
							this.form.studio = this.studios[0];
							this.onChange(this.form.studio);
						}
					});
			}
		);
	}

	canSave(): boolean {
		if (this.loading) {
			return false;
		}

		if (this.form.studio === undefined) {
			return false;
		}

		if (this.form.color === undefined || this.form.color === '') {
			return false;
		}

		return true;
	}

	save(): void {
		this.loading = true;
		this.artistService.addStudioToArtist(this.artist, this.form.studio.id, this.form.color).subscribe(
			result => {
				if (result !== undefined) {
					this.artistViewService.update(result);
				}
				this.activeModal.close();
			}
		);
	}

	onChange(studio: StudioColor): void {
		this.form.color = undefined;
		this.colors = studio.colors;
	}
}
