<div class="modal-header">
	<h4 class="modal-title"><span class="fa fa-upload"></span> Upload File</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body d-flex justify-content-center" appFiledrop (onDragFile)="addFile($event)">
	<div *ngIf="!complete" class="align-content-center upload text-white text-center shadow rounded p-3 text-primary text-center" [ngClass]="{'bigupload':
	!file}">

		<div class="col-12">
			<h2 *ngIf="!file">Drag file here</h2>
			<h3 *ngIf="file" class=""><span class="fa fa-file-o"></span> {{file.name}}</h3>
		</div>
	</div>
</div>

<div *ngIf="file" class="modal-body d-flex justify-content-center">
	<div class="col-12">
		<form>
			<div class="form-group row">
				<label for="inputName" class="col-sm-2 col-form-label">Name</label>
				<div class="col-sm-10">
					<input type="text" class="form-control" id="inputName" name="name" [(ngModel)]="name">
				</div>
			</div>

			<div class="form-group row">
				<label for="inputComments" class="col-sm-2 col-form-label">Comments</label>
				<div class="col-sm-10">
					<input type="text" class="form-control" id="inputComments" name="comments" [(ngModel)]="comments">
				</div>
			</div>

		</form>
	</div>
</div>

<div class="modal-footer">
	<button type="button" class="btn btn-danger mr-auto" (click)="activeModal.close('Close click')">Cancel</button>
	<label class="mr-auto">{{status}}</label>
	<button type="button" [disabled]="!file || name.length < 3" class="btn btn-success" (click)="startUpload()">Upload</button>
</div>
