<app-page name="Monthly studio report">
	<div class="shadow-lg mb-4">
		<div class="row bg-gradient-primary m-0 p-3 text-light justify-content-center rounded-top" style="font-size: larger">
			<div class="col-6 align-self-end">
				<h4 class="mb-0" *ngIf="report">
					{{report.startDate | date:"MMMM YYYY" | EnumType}} - {{report.studio.name}}
				</h4>
			</div>
			<div class="col-6 text-right d-print-none">
				<form class="form-inline pull-right float-right">
					<select class="custom-select mr-2" [(ngModel)]="form.month" name="month" (change)="changeForm()" [attr.disabled]="loading ? 'true' : null">
						<option *ngFor="let m of months; let x = index" [value]="x">{{m}}</option>
					</select>
					<select class="custom-select mr-4" [(ngModel)]="form.year" name="year" (change)="changeForm()" [attr.disabled]="loading ? 'true' : null">
						<option *ngFor="let y of years; let i = index" [value]="y">{{y}}</option>
					</select>

					<app-studio-filter [(form)]="studio" allowAll="false" (filterchange)="changeForm()" [disabled]="loading"></app-studio-filter>
				</form>
			</div>
		</div>

		<div class="row mt-0 pt-0" *ngIf="loading">
			<div class="col-12 text-center mt-0 pt-0 mb-3 mt-3">
				<div class="spinner-border text-primary" role="status">
					<span class="sr-only">Loading...</span>
				</div>
			</div>
		</div>

		<table class="table table-striped table-borderless table-sm card-body" *ngIf="!loading && report">
			<thead>
			<tr class="text-right">
				<th>&nbsp;</th>
				<th>Tattoo (excl tax)</th>
				<th>Piercing (excl tax)</th>
				<th>Products (excl tax)</th>
				<th>
					Costs
					<i class="fa fa-info-circle d-print-none"
					   placement="bottom"
					   ngbTooltip="Costs are the sum of daily artist compensations, discounts & refunds, excluding tax.">
					</i>
				</th>
				<th>Total excl tax</th>
				<th>Tax</th>
				<th>Total income incl tax</th>
			</tr>
			</thead>
			<tbody>
			<tr *ngFor="let day of report.days" class="text-right text-monospace">
				<td class="text-left pl-3"><strong>{{day.day | date:"dd-MM"}}</strong></td>
				<td>{{day.tattoo | currency}}</td>
				<td>{{day.piercing | currency}}</td>
				<td>{{day.products | currency}}</td>
				<td>{{day.costs | currency}}</td>
				<td>{{day.total | currency}}</td>
				<td>{{day.btw | currency}}</td>
				<td class="pr-3">{{day.income | currency}}</td>
			</tr>

			<tr class="font-weight-bold" class="text-right text-monospace">
				<td class="text-left pl-3"><strong>Total</strong></td>
				<td>{{report.totals.tattoo | currency}}</td>
				<td>{{report.totals.piercing | currency}}</td>
				<td>{{report.totals.products | currency}}</td>
				<td>{{report.totals.costs | currency}}</td>
				<td>{{report.totals.total | currency}}</td>
				<td>{{report.totals.btw | currency}}</td>
				<td class="pr-3">{{report.totals.income | currency}}</td>
			</tr>
			</tbody>
		</table>

		<div class="card-body" *ngIf="!loading && report">
			<div class="row">

				<div class="col-6">
					<div class="card">
						<div class="card-header">
							Cash register
						</div>
						<div class="card-body">
							<div class="row ">
								<div class="col-12">
									Total cash removed from register<br/>
									<strong>{{report.registerToSafe | currency}}</strong>
								</div>
							</div>
							<div class="row mt-3">
								<div class="col-4">Cash in register at <i>{{report.startDate | date: "dd-MM"}}</i> <br/>
									<strong>{{report.registerStart | currency}}</strong></div>
								<div class="col-4">Cash in register at <i>{{report.endDate | date: "dd-MM"}}</i> <br/>
									<strong>{{report.registerEnd | currency}}</strong></div>
								<div class="col-4">Register balance<br/>
									<strong>{{report.registerEnd - report.registerStart | currency}}</strong>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</app-page>
