import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {InventoryService} from '../../../../core/service/inventory.service';
import {InventoryItemModel} from '../../../../model/inventory/inventory-item-model';

@Component({
	selector: 'app-inventory-item-correction',
	templateUrl: './inventory-item-correction.component.html',
	styleUrls: ['./inventory-item-correction.component.scss']
})
export class InventoryItemCorrectionComponent implements OnInit {
	item: InventoryItemModel;
	saving = false;
	form = {
		newValue: undefined,
		description: ''
	};

	constructor(
		public activeModal: NgbActiveModal,
		private inventoryService: InventoryService
	) {}

	ngOnInit(): void {
	}

	setItem(item: InventoryItemModel): void {
		this.item = item;
	}

	canSave(): boolean {
		if (this.saving) {
			return false;
		}

		if (!this.form.description || this.form.description.length < 3) {
			return false;
		}

		if (!this.form.newValue || this.form.newValue === this.item.quantity) {
			return false;
		}

		return true;
	}

	save(): void {
		this.saving = true;
		this.inventoryService.correctItemQuantity(this.item, this.form.newValue, this.form.description).subscribe(value =>
			this.activeModal.close(value)
		);
	}

}
