import {CurrencyPipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormatDateTimePipe} from '../../../../core/pipe/formatdatetime.pipe';
import {PiercingNamePipe} from '../../../../core/pipe/piercing-name.pipe';
import {StudioPipe} from '../../../../core/pipe/studio.pipe';
import {UsernamePipe} from '../../../../core/pipe/username.pipe';
import {PiercingTransactionService} from '../../../../core/service/piercing-transaction.service';
import {AbstractTableViewComponent} from '../../../../component/table-view/abstract-table-view-component';
import {TableColumn} from '../../../../component/table-view/table-column-model';
import {TableProjection} from '../../../../component/table-view/table-projection';
import {ArtistViewService} from '../artist.view.service';
import {PiercingTransaction} from '../../../../model/domain/piercing-transaction';

@Component({
	selector: 'app-artist-piercings',
	templateUrl: './artist-piercings.component.html',
	styleUrls: ['./artist-piercings.component.scss']
})
export class ArtistPiercingsComponent extends AbstractTableViewComponent<PiercingTransaction> implements OnInit {

	constructor(
		private route: Router,
		private artistViewService: ArtistViewService,
		private piercingService: PiercingTransactionService
	) {
		super();
		this.defaultFilters.studio = 'any';
		this.artistViewService.get().subscribe(artist => {
			this.defaultFilters.artist = artist.id;
		});
	}

	ngOnInit(): void {
		this.headers.push(new TableColumn('Date', 'created', 15, new FormatDateTimePipe()));
		this.headers.push(new TableColumn('Studio', 'studio', 20, new StudioPipe()));
		this.headers.push(new TableColumn('Sold by', 'owner', 20, new UsernamePipe()));
		this.headers.push(new TableColumn('Piercing', 'piercing', 20, new PiercingNamePipe()));
		this.headers.push(new TableColumn('Price', 'price', 15, new CurrencyPipe('nl', 'EUR')));
	}

	getObjects(projection: TableProjection): void {
		this.filters = projection.filterObj;
		const request = projection.getAsListRequest();
		this.piercingService.getAllPiercings(request).subscribe(
			value => {
				this.objects.next(value);
			}
		);
	}

	onObjectSelect(piercing: PiercingTransaction): void {
		this.route.navigate([`/piercing/${piercing.id}`]);
	}

}
