<app-page name="Sales report">
	<div class="shadow mb-3">
		<div class="row bg-gradient-primary m-0 p-2 text-light rounded-top" style="font-size: larger">
			<ng-container *ngIf="groups && groups.length > 0">
				<div class="col-2">
					<ng-container *ngFor="let group of groups | slice:0:-5">
						<input type="checkbox" [(ngModel)]="group.checked" [id]="group.id" (change)="updateReport()">
						<label [for]="group.id">{{group.name}}</label>
						<br/>
					</ng-container>
				</div>

				<div class="col-2">
					<ng-container *ngFor="let group of groups | slice:-5">

						<input type="checkbox" [(ngModel)]="group.checked" [id]="group.id" (change)="updateReport()">
						<label [for]="group.id">{{group.name}}</label>
						<br/>
					</ng-container>
				</div>
			</ng-container>

			<div class="col-6">
				<div class="row justify-content-center">
					<div class="col-auto text-center">
						Date range <br/>
						<form class="form-inline">
							<div class="form-group text-center">
								<div class="input-group">
									<input class="form-control" name="dpmin"
									       ngbDatepicker
									       #dMin="ngbDatepicker"
									       placeholder="dd/mm/yyyy"
									       [(ngModel)]="startDate"
									       [maxDate]="endDate ? endDate : today"
									       (dateSelect)="updateReport()"
									       (blur)="updateReport()"
									       (keydown.enter)="updateReport()"
									>
									<div class="input-group-append">
										<button class="btn btn-light fa fa-calendar" (click)="dMin.toggle()" type="button"></button>
									</div>
								</div>

								<span class="fa fa-arrow-right text-white ml-2 mr-2"></span>

								<div class="input-group">
									<input class="form-control" name="dpmax"
									       ngbDatepicker
									       #dMax="ngbDatepicker"
									       placeholder="dd/mm/yyyy"
									       [(ngModel)]="endDate"
									       [minDate]="startDate"
									       [maxDate]="today"
									       (dateSelect)="updateReport()"
									       (blur)="updateReport()"
									       (keydown.enter)="updateReport()"
									>
									<div class="input-group-append">
										<button class="btn btn-light fa fa-calendar" (click)="dMax.toggle()" type="button"></button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				<br/>
				<br/>
				<br/>
				<div class="row justify-content-center">
					<div class="col-auto text-center">
						<input type="checkbox" id="toggleQp" class="" [(ngModel)]="showQuantity">
						<label for="toggleQp" class="mr-3">Display sales volume</label>
						<input type="checkbox" id="toggleZero" class="ml-3" [(ngModel)]="hideZero">
						<label for="toggleZero">Hide zero values</label>
					</div>
				</div>
			</div>

			<div class="col-2">
				<ng-container *ngIf="studios && studios.length > 0">
					<ng-container *ngFor="let studio of studios">
						<input type="checkbox" [(ngModel)]="studio.checked" [id]="studio.id" (change)="updateReport()">
						<label [for]="studio.id">{{studio.name}}</label>
						<br/>
					</ng-container>
				</ng-container>
			</div>

		</div>
		<div class="row p-3" *ngIf="getStudioList().length === 0">
			<div class="col-12 text-lg-center text-warning"><i class="fa fa-warning fa-fw"></i> Select at least one studio</div>
		</div>
		<div class="row p-3" *ngIf="(salesReport === undefined || salesReport.groups.length === 0) && getStudioList().length > 0">
			<div class="col-12 text-lg-center"><i class="fa fa-info-circle fa-fw"></i> Select one or more groups to compare sales</div>
		</div>
		<div class="row p-2" *ngIf="salesReport !== undefined && salesReport.groups.length > 0">
			<div class="mb-4" *ngFor="let group of salesReport.groups" [class.col-6]="salesReport.groups.length > 2" [class.col-12]="salesReport.groups.length <= 2">
				<div class="card m-0">
					<div class="card-header">
						<h3 class="mb-0">{{group.name}}</h3>
					</div>
					<table class="table table-borderless mb-0 table-hover table-striped table-sm card-body text-right" style="table-layout:fixed">
						<thead class="font-weight-bold">
						<tr class="nowrap">
							<td class="text-left" style="width: 20%;"></td>
							<td *ngFor="let studio of salesReport.studios" [style]="getCellWidthStyling()">
								{{studio.name.replace('Bodycult ', '')}}
							</td>
						</tr>
						</thead>
						<tbody>
						<tr *ngFor="let item of group.items" class="nowrap">
							<td class="text-left nowrap">
								{{item.name}}
							</td>

							<ng-container *ngIf="showQuantity">
								<td *ngFor="let q of item.quantity">
									<span *ngIf="!hideZero || q > 0">{{q}}</span>
								</td>
							</ng-container>

							<ng-container *ngIf="!showQuantity">
								<td *ngFor="let q of item.price">
									<span *ngIf="!hideZero || q > 0">{{q | currency:'EUR'}}</span>
								</td>
							</ng-container>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</app-page>
