import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {Moment} from 'moment';
import {combineLatest, Observable} from 'rxjs';
import {Studio} from '../../../model/domain/studio';
import {StudioDayOverview} from '../../../model/studio-day-overview';
import {StudioOverviewService} from '../../../core/service/studio-overview.service';
import {StudioService} from '../../../core/service/studio.service';
import {DateUtil} from '../../../core/util/date.util';


@Component({
	selector: 'app-studio-day-review',
	templateUrl: './studio-day-review.component.html',
	styleUrls: ['./studio-day-review.component.scss']
})
export class StudioDayReviewComponent implements OnInit {
	studio = 1;
	overview: StudioDayOverview[];

	studiosObs: Observable<Studio[]>;
	queryParams: Observable<Params>;

	studios: Studio[];
	inputDate: NgbDate;
	startDate: Moment;
	endDate: Moment;
	maxDate: NgbDate;
	loading: boolean;

	constructor(
		private studioService: StudioService,
		private studioOverviewService: StudioOverviewService,
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {
		this.maxDate = DateUtil.getNgbDateFromMoment(moment().endOf('isoWeek'));
		this.loading = true;
	}

	changeForm(): void {
		this.startDate = DateUtil.getMomentFromNgbDate(this.inputDate).startOf('day').startOf('isoWeek');
		this.loading = true;
		this.router.navigate(
			[],
			{
				relativeTo: this.activatedRoute,
				queryParams: {
					studio: this.studio,
					date: this.startDate.valueOf(),
				},
				queryParamsHandling: 'merge'
			});
	}

	ngOnInit(): void {
		this.queryParams = this.activatedRoute.queryParams;
		this.studiosObs = this.studioService.getAll();

		combineLatest([this.studiosObs, this.queryParams]).subscribe(
			results => {
				this.studios = results[0];
				const params = results[1];
				this.studio = params.studio;

				if (this.studio === undefined) {
					this.studio = this.studios[0].id;
				}

				if (params.date !== undefined) {
					this.startDate = moment(Number(params.date)).startOf('isoWeek');
				} else {
					this.startDate = moment().startOf('isoWeek');
				}

				this.endDate = this.startDate.clone().endOf('isoWeek');
				this.inputDate = new NgbDate(this.startDate.get('year'), this.startDate.get('month') + 1, this.startDate.get('date'));

				this.studioOverviewService.getReport(this.studio, this.startDate.utc(true).valueOf()).subscribe(
					model => {
						this.overview = model;
						this.loading = false;
					}
				);
			}
		);
	}

	inCurrentWeek(date): boolean {
		return DateUtil.getMomentFromNgbDate(date).startOf('day').isSameOrBefore(this.endDate.startOf('day'))
			&& DateUtil.getMomentFromNgbDate(date).isSameOrAfter(this.startDate);
	}

	isFirstDay(date): boolean {
		return DateUtil.getMomentFromNgbDate(date).startOf('day').isSame(this.startDate.startOf('day'));
	}

	isLastDay(date): boolean {
		return DateUtil.getMomentFromNgbDate(date).startOf('day').isSame(this.endDate.startOf('day'));
	}
}
