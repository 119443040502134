import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Artist} from '../../../model/domain/artist';

@Injectable()
export class ArtistViewService {
	emitChange$: BehaviorSubject<Artist> = new BehaviorSubject<Artist>(null);

	constructor() { }

	update(value: Artist): void {
		this.emitChange$.next(value);
	}

	get(): BehaviorSubject<Artist> {
		return (this.emitChange$ as BehaviorSubject<Artist>);
	}
}
