import {Component, Input, OnInit} from '@angular/core';
import {StudioDayOverview} from '../../../../model/studio-day-overview';

@Component({
	selector: 'app-studio-performance',
	templateUrl: './studio-performance.component.html',
	styleUrls: ['./studio-performance.component.scss']
})
export class StudioPerformanceComponent implements OnInit {
	@Input() report: StudioDayOverview;
	@Input() title: string;

	constructor() { }

	ngOnInit(): void {
	}
}
