<div class="modal-header">
	<h4 class="modal-title"><span class="fa fa-plus-circle mr-2 text-center"></span> Add discount</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" tabindex="-1">
		&times;
	</button>
</div>

<div class="modal-body">
	<div class="row">
		<div class="col-12">
			<div class="input-group mb-2 input-group-lg">
				<div class="input-group-prepend">
					<div class="input-group-text">&euro;</div>
				</div>
				<input type="text" class="form-control" id="price" placeholder="Amount" name="amount" [(ngModel)]="amount">
			</div>
		</div>
	</div>


	<div class="row mt-3">
		<div class="col-4">
			<button class="btn btn-danger " (click)="activeModal.close()">Cancel</button>
		</div>
		<div class="offset-4 col-4 text-right">
			<button class="btn btn-success" type="submit" [disabled]="!canSave()" (click)="save()">Add discount</button>
		</div>
	</div>
</div>
