import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Inventory} from '../../model/inventory/inventory';
import {InventoryItemModel} from '../../model/inventory/inventory-item-model';
import {Listrequest} from '../../model/listrequest';
import {Paginationmodel} from '../../model/paginationmodel';
import {InventoryGroup} from '../../model/inventory/inventory-group';
import {InventoryTransaction} from '../../model/inventory/inventory-transaction';
import {InventoryTransferRequest} from '../../module/inventory/inventory-transfer/inventory-transfer-item-model';

@Injectable({
	providedIn: 'root'
})
export class InventoryService {
	private url = 'api/inventory/';
	private urlAll = this.url + 'all';

	constructor(
		private http: HttpClient
	) {}

	getById(id: number): Observable<Inventory> {
		return this.http.get<Inventory>(this.url + id + '/');
	}

	getAll(): Observable<Inventory[]> {
		return this.http.get<Inventory[]>(this.urlAll);
	}

	getInventoryTransactions(inventoryId: number, request: Listrequest): Observable<Paginationmodel> {
		return this.http.post<Paginationmodel>(`${this.url}${inventoryId}/transactions`, request);
	}

	getTransactions(request: Listrequest): Observable<Paginationmodel> {
		return this.http.post<Paginationmodel>(`${this.url}transactions`, request);
	}

	getAllObjects(): Observable<InventoryGroup[]> {
		return this.http.get<InventoryGroup[]>(`${this.url}objects`);
	}

	transferItems(request: InventoryTransferRequest): Observable<InventoryTransaction[]> {
		return this.http.post<InventoryTransaction[]>(`${this.url}transfer`, request);
	}

	correctItemQuantity(item: InventoryItemModel, newValue: number, description: string): Observable<InventoryGroup[]> {
		return this.http.post<InventoryGroup[]>(`${this.url}item/correction`, {
			itemId: item.id,
			newValue,
			description
		});
	}

	updateItem(item: InventoryItemModel, desired: number, warning: number, minimum: number): Observable<InventoryGroup[]> {
		return this.http.post<InventoryGroup[]>(`${this.url}item/update`, {
			itemId: item.id,
			desired,
			warning,
			minimum
		});
	}
}
