import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PiercingGroup} from '../../model/domain/piercing-group';

@Injectable({
	providedIn: 'root'
})
export class PiercingGroupService {

	private url = 'api/piercing/groups/';
	private urlAll = this.url + 'all';

	constructor(
		private http: HttpClient
	) {}

	getAll(): Observable<PiercingGroup[]> {
		return this.http.get<PiercingGroup[]>(this.urlAll);
	}

	getById(id: number): Observable<PiercingGroup> {
		return this.http.get<PiercingGroup>(this.url + id + '/');
	}
}
