<form class="form-inline pull-right">
	<div class="form-group">
		<div class="input-group">
			<input class="form-control" name="dpmin"
			       ngbDatepicker
			       #dMin="ngbDatepicker"
			       placeholder="dd/mm/yyyy"
			       [(ngModel)]="minDate"
			       [maxDate]="maxDate"
			       (dateSelect)="selectMinDate()"
			       (blur)="selectMinDate()"
			       (keydown.enter)="selectMinDate()"
			       [attr.disabled]="disabled ? 'true' : null"
			>
			<div class="input-group-append">
				<button class="btn btn-light fa fa-calendar" (click)="dMin.toggle()" type="button" [attr.disabled]="disabled ? 'true' : null"></button>
			</div>
		</div>

		<span class="fa fa-arrow-right text-white ml-2 mr-2"></span>

		<div class="input-group">
			<input class="form-control" name="dpmax"
			       ngbDatepicker
			       #dMax="ngbDatepicker"
			       placeholder="dd/mm/yyyy"
			       [(ngModel)]="maxDate"
			       [minDate]="minDate"
			       (dateSelect)="selectMaxDate()"
			       (blur)="selectMaxDate()"
			       (keydown.enter)="selectMaxDate()"
			       [attr.disabled]="disabled ? 'true' : null"
			>
			<div class="input-group-append">
				<button class="btn btn-light fa fa-calendar" (click)="dMax.toggle()" type="button" [attr.disabled]="disabled ? 'true' : null"></button>
			</div>
		</div>
	</div>
</form>
