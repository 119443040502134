import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Artist} from '../../../../model/domain/artist';
import {Document} from '../../../../model/domain/document';
import {DocumentService} from '../../../../core/service/document.service';
import {DownloadService} from '../../../../core/service/download.service';
import {ModalService} from '../../../../core/service/modalservice';
import {ArtistDocumentAddComponent} from '../artist-document-add/artist-document-add.component';
import {ImageViewModalComponent} from './image-view-modal/image-view-modal.component';

@Component({
	selector: 'app-artist-documents',
	templateUrl: './artist-documents.component.html',
	styleUrls: ['./artist-documents.component.scss']
})
export class ArtistDocumentsComponent implements OnInit {
	artist: Artist;
	documents: Document[];

	constructor(
		private route: ActivatedRoute,
		private documentService: DocumentService,
		private modalService: ModalService,
		private downloadService: DownloadService
	) {}

	openAddModal(): void {
		const modelRef = this.modalService.open(ArtistDocumentAddComponent);
		modelRef.componentInstance.artist = this.artist;
		modelRef.result.then(value => {
			if (value !== undefined && value !== 'Close click') {
				this.documents.unshift(value);
			}
		}).catch(value => {});
	}

	ngOnInit(): void {
		this.route.parent.data.subscribe((data) => {
			this.artist = data.artist;

			if (this.artist.id !== undefined) {
				this.documentService.getDocuments(this.artist).subscribe(results => this.documents = results);
			}
		});
	}

	getIcon(extension: string): string {
		return this.documentService.getFAIconForFiletype(extension);
	}

	getFileType(extension: string): string {
		return this.documentService.getFileType(extension);
	}


	viewDocument(document: Document): void {
		if (this.getFileType(document.extension) !== 'image') {
			this.downloadService.viewDocument(document);
			return;
		}
		const modelRef = this.modalService.open(ImageViewModalComponent, {size: 'lg'});
		(modelRef.componentInstance as ImageViewModalComponent).setDocument(document);
		modelRef.result.then(val => {}).catch(val => {});
	}

	downloadDocument(document: Document): void {
		this.downloadService.downloadDocument(document);
	}
}
