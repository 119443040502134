import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';


@Pipe({
	name: 'formattime'
})
export class FormatTimePipe implements PipeTransform {
	constructor() {}

	transform(value: any): any {
		if (value === 0) {
			return '';
		}
		return moment(value).format('HH:mm');
	}
}
