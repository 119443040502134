import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {FormComponent} from '../form/form.component';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel} from '@angular/forms';

@Component({
	selector: 'app-input-text',
	templateUrl: './input-text.component.html',
	styleUrls: ['./input-text.component.scss'],
})
export class InputTextComponent implements OnInit {
	@Input()
	name: string;
	@Input()
	label: string;
	@Input()
	subLabel: string;
	@Input()
	ngModel: string;
	@Output()
	ngModelChange = new EventEmitter<string>();

	constructor(public form: FormComponent) { }

	ngOnInit(): void {
	}

}
