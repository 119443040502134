import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ArtistPerDayModel} from '../../../../model/report/artist-per-day-model';
import {ArtistService} from '../../../../core/service/artist.service';
import {ArtistReportFilterOptions} from '../../../../model/report/artist-report-filter-options';
import * as moment from 'moment';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Artist} from '../../../../model/domain/artist';

@Component({
	selector: 'app-artist-report',
	templateUrl: './artist-report.component.html',
	styleUrls: ['./artist-report.component.scss']
})
export class ArtistReportComponent implements OnInit {
	artist: Artist;
	report: ArtistPerDayModel;

	reportOptions: ArtistReportFilterOptions;

	form = new FormGroup({
		year: new FormControl([moment().format('YYYY')]),
		month: new FormControl([moment().format('MMMM')])
	});

	months: string[] = [];
	years: string[] = [];

	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private artistService: ArtistService
	) {}

	ngOnInit(): void {
		this.route.parent.data.subscribe((data) => {
			this.artist = data.artist;
			this.artistService.getReportOptions(this.artist.id).subscribe(options => this.setReportOptions(options));

			this.artistService.getEarningReport(this.artist.id, new Date().getTime()).subscribe(
				result => this.report = result
			);
		});

		this.form.valueChanges.subscribe(formValues => this.updateForm(formValues));
	}

	updateForm(form: any): void {
		this.updateDateSelectors();
		this.artistService.getEarningReport(this.artist.id, this.getSelectedMoment().valueOf()).subscribe(
			result => this.report = result
		);
	}

	getSelectedMoment(): moment.Moment {
		return moment().set('year', this.getSelectedYear()).set('month', this.getSelectedMonth()).startOf('month');
	}

	getSelectedMonth(): number {
		if (Array.isArray(this.form.value.month)) {
			return this.form.value.month[0];
		} else {
			return this.form.value.month;
		}
	}

	getSelectedYear(): number {
		if (Array.isArray(this.form.value.year)) {
			return this.form.value.year[0];
		} else {
			return this.form.value.year;
		}
	}

	updateDateSelectors(): void {
		let selectedMoment = this.getSelectedMoment();

		this.months = [];
		this.years = [];
		const firstDay = moment(this.reportOptions.firstAvailableDate).startOf('month').startOf('day');
		const now = moment();

		let test = now.clone();

		while (firstDay.isSameOrBefore(test.endOf('year'))) {
			this.years.push(test.format('YYYY'));
			test = test.subtract(1, 'year');
		}

		this.years.reverse();

		// Set to currently selected year
		let monthCounter = moment().set('year', Number(this.form.value.year)).startOf('year');

		for (let i = 0; i < 12; i++) {
			if (monthCounter.isSameOrAfter(firstDay) && monthCounter.isBefore(moment())) {
				this.months.push(monthCounter.format('MMMM'));
			}

			monthCounter = monthCounter.add(1, 'month');
		}

		selectedMoment = this.getSelectedMoment();

		if (firstDay.get('year') === selectedMoment.get('year')) {
			if (firstDay.get('month') > selectedMoment.get('month')) {
				this.form.controls.month.setValue(firstDay.format('MMMM'));
			}
		}
		if (moment().get('year') === selectedMoment.get('year')) {
			if (selectedMoment.get('month') > moment().get('month')) {
				this.form.controls.month.setValue(moment().format('MMMM'));
			}
		}
	}

	setReportOptions(options: ArtistReportFilterOptions): void {
		this.reportOptions = options;

		if (this.reportOptions === undefined) {
			return;
		}
		this.updateDateSelectors();
	}
}
