import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SalesReportRequest} from '../../model/report/sales/sales-report-request';
import {SalesReport} from '../../model/report/sales/sales-report';

@Injectable({
	providedIn: 'root'
})
export class SalesReportService {
	private url = 'api/report';

	constructor(
		private http: HttpClient
	) { }

	getReport(request: SalesReportRequest): Observable<SalesReport> {
		return this.http.post<SalesReport>(`${this.url}/sales`, request);
	}
}
