import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Artist} from '../../model/domain/artist';
import {AccountTransaction} from '../../model/domain/account-transaction';
import {Listrequest} from '../../model/listrequest';
import {Paginationmodel} from '../../model/paginationmodel';
import {ArtistAccount} from '../../model/domain/artist-account';

@Injectable({
	providedIn: 'root'
})
export class AccountTransactionService {
	private url = 'api/artist/';

	constructor(
		private http: HttpClient
	) {}

	getAccount(artist: Artist, name: string): ArtistAccount {
		for (const account of artist.accounts) {
			if (account.name === name) {
				return account;
			}
		}

		return undefined;
	}

	getRecentTransactions(artist: Artist): Observable<AccountTransaction[]> {
		return this.http.get<AccountTransaction[]>(this.url + artist.id + '/transactions');
	}

	getTransactions(artist: Artist, request: Listrequest): Observable<Paginationmodel> {
		return this.http.post<Paginationmodel>(this.url + artist.id + '/transactions/get', request);
	}

	addPayout(artist: Artist, account: string, amount: number, document: number, description: string): Observable<Artist> {
		const model = {
			account,
			amount,
			document,
			description
		};

		return this.http.post<Artist>(`${this.url}${artist.id}/payout`, model);
	}

	addCorrection(artist: Artist, account: string, amount: number, description: string): Observable<AccountTransaction> {
		const model = {
			account,
			amount,
			description
		};

		return this.http.post<AccountTransaction>(this.url + artist.id + '/transactions/correction', model);
	}
}
