<div class="card mt-3 shadow">
	<div class="card-header bg-gradient-primary text-white">
		<h2 class="card-title text-center">New order</h2>
	</div>

	<div class="card-body">
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-header">
						Order details
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-3">
								<label for="date">
									Date & time of order
								</label>
								<form class="form-inline">
									<div class="form-group">
										<div class="input-group">
											<input class="form-control" placeholder="dd/mm/yyyy"
											       name="dp" [(ngModel)]="date" ngbDatepicker #d="ngbDatepicker" id="date">
											<div class="input-group-append">
												<button class="btn btn-light btn-outline-dark fa fa-calendar" (click)="d.toggle()" type="button"></button>
											</div>
										</div>
									</div>
								</form>
							</div>
							<div class="col-4">
								<ngb-timepicker [(ngModel)]="time" id="time"></ngb-timepicker>
							</div>
							<div class="col-5">
								<label for="studio">
									Studio
								</label>
								<select id="studio" class="custom-select" [(ngModel)]="studio">
									<option value=""></option>
									<option *ngFor="let studio of studios" [value]="studio.id">{{studio.name}}</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- Items -->
	<div class="card-body">
		<div class="row">
			<div class="col-10 pr-1">
				<div class="card">
					<div class="card-header">
						Items
					</div>
					<div class="col-12 text-center p-4 mb-2 mt-2" *ngIf="items.length == 0">
						<strong class="">Add an item to this order to get started</strong>
					</div>
					<table class="table table-hover table-md rounded mb-0" *ngIf="items.length > 0">
						<tbody>
						<tr *ngFor="let item of items" class="itemrow">
							<td style="width: 5%" class="text-center">
								<a [routerLink]="[]" class="mouseover">
									<i class="fa fa-trash" (click)="removeItem(item)"></i>
								</a>
							</td>
							<td style="width: 50%">
								{{item.name}}
							</td>
							<td style="width: 20%">
								<span *ngIf="item.type !== 'tattoo'">
									<a [routerLink]="[]" class="mouseover"><i class="fa fa-minus-circle pr-2" (click)="decreaseQuantity(item)"></i></a>
									{{item.quantity}}
									<a [routerLink]="[]" class="mouseover"><i class="fa fa-plus-circle pl-2" (click)="increaseQuantity(item)"></i></a>
								</span>
							</td>
							<td class="text-right" style="width: 10%">
								<span *ngIf="item.quantity > 1">{{item.price | currency}}</span>
							</td>
							<td class="text-right" style="width: 15%">
								{{item.price * item.quantity | currency}}
							</td>
						</tr>
						<tr class="font-weight-bold">
							<td></td>
							<td></td>
							<td></td>
							<td class="text-right">Total</td>
							<td class="text-right">{{getOrderTotal() | currency}}</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div class="col-2">
				<div class="btn-group-vertical btn-group-lg btn-block">
					<button (click)="addProduct()" class="btn btn-primary text-left">
						<i class="fa fa-plus-circle"></i> Add Product
					</button>
					<button (click)="addTattoo()" class="btn btn-primary text-left">
						<i class="fa fa-plus-circle"></i> Add Tattoo
					</button>
					<button (click)="addPiercing()" class="btn btn-primary text-left">
						<i class="fa fa-plus-circle"></i> Add Piercing
					</button>
				</div>
			</div>
		</div>
	</div>

	<!-- Transactions -->
	<div class="card-body" *ngIf="items.length > 0 || transactions.length > 0">
		<div class="row">
			<div class="col-10 pr-1">
				<div class="card">
					<div class="card-header">
						Transactions
					</div>
					<div class="col-12 text-center p-4" *ngIf="transactions.length == 0">
						<strong class="">No transactions yet, add at least one</strong>
					</div>

					<table class="table table-hover table-md rounded mb-0" *ngIf="transactions.length > 0">
						<tbody>
						<tr *ngFor="let transaction of transactions" class="itemrow">
							<td style="width: 5%" class="text-center">
								<a [routerLink]="[]" class="mouseover">
									<i class="fa fa-trash" (click)="removeTransaction(transaction)"></i>
								</a>
							</td>

							<td style="width: 80%">
								{{transaction.title}}
							</td>
							<td class="text-right" style="width: 15%">
								{{transaction.amount | currency}}
							</td>
						</tr>
						<tr>
							<td></td>
							<td class="text-right">
								<strong>Total</strong>
							</td>
							<td class="text-right" style="width: 15%" [class.text-danger]="getTransactionTotal() !== getOrderTotal()">
								<strong>{{getTransactionTotal() | currency }}</strong>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div class="col-2">
				<div class="btn-group-vertical btn-group-lg btn-block">
					<button class="btn btn-primary text-left" (click)="addPayment()"><i class="fa fa-plus-circle"></i> Add cash/card</button>
					<button class="btn btn-primary text-left" (click)="addDiscount()"><i class="fa fa-plus-circle"></i> Add discount</button>
					<!--					<button class="btn btn-primary text-left"><i class="fa fa-plus-circle"></i> Add giftcard</button>-->
				</div>
			</div>
		</div>
	</div>

	<div class="card-body">
		<div class="row">
			<div class="col-12">
				<button class="btn btn-success btn-lg btn-block" [disabled]="!canSave()" (click)="save()">Save order</button>
			</div>
		</div>
	</div>
</div>
