import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
	providedIn: 'root'
})
export class ModalService {
	constructor(private modalService: NgbModal) {}

	open(content, options = {}): NgbModalRef {
		return this.modalService.open(content, {...options, animation: true, windowClass: 'offset-1'});
	}
}
