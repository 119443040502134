import {Directive, EventEmitter, Output, HostListener} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

export interface FileHandle {
	file: File;
	url: SafeUrl;
}

@Directive({
	selector: '[appFiledrop]'
})
export class FiledropDirective {
	@Output() onDragFile: EventEmitter<FileHandle> = new EventEmitter();

	constructor(private sanitizer: DomSanitizer) { }

	@HostListener("dragover", ["$event"])
	public onDragOver(evt: DragEvent) {
		evt.preventDefault();
		evt.stopPropagation();
	}

	@HostListener("dragleave", ["$event"])
	public onDragLeave(evt: DragEvent) {
		evt.preventDefault();
		evt.stopPropagation();
	}

	@HostListener('drop', ['$event'])
	public onDrop(evt: DragEvent) {
		evt.preventDefault();
		evt.stopPropagation();

		if (evt.dataTransfer.files.length > 0) {
			const file = evt.dataTransfer.files[0];
			const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
			this.onDragFile.emit({file, url});
		}
	}
}
