import {CurrencyPipe, TitleCasePipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Artist} from '../../../../model/domain/artist';
import {ArtistAccountTransaction} from '../../../../model/domain/artist-account-transaction';
import {AccountPipe} from '../../../../core/pipe/account.pipe';
import {FormatDateTimePipe} from '../../../../core/pipe/formatdatetime.pipe';
import {TransactionDescPipe} from '../../../../core/pipe/transaction-desc.pipe';
import {UsernamePipe} from '../../../../core/pipe/username.pipe';
import {AccountTransactionService} from '../../../../core/service/account-transaction.service';
import {AbstractTableViewComponent} from '../../../../component/table-view/abstract-table-view-component';
import {TableColumn} from '../../../../component/table-view/table-column-model';
import {TableProjection} from '../../../../component/table-view/table-projection';

@Component({
	selector: 'app-artist-transactions',
	templateUrl: './artist-transactions.component.html',
	styleUrls: ['./artist-transactions.component.scss']
})
export class ArtistTransactionsComponent extends AbstractTableViewComponent<ArtistAccountTransaction> implements OnInit {
	artist: Artist;

	constructor(
		private route: Router,
		private activeRoute: ActivatedRoute,
		private accountTransactionService: AccountTransactionService
	) {
		super();
		this.defaultFilters.account = 'any';
		this.defaultFilters.type = 'any';

		this.activeRoute.parent.data.subscribe((data) => {
			this.artist = data.artist;
		});
	}

	ngOnInit(): void {
		this.headers.push(new TableColumn('Date', 'created', 15, new FormatDateTimePipe()));
		this.headers.push(new TableColumn('Perfomed by', 'user', 15, new UsernamePipe()));
		this.headers.push(new TableColumn('Type', 'type', 10, new TitleCasePipe()));
		this.headers.push(new TableColumn('Account', 'account', 10, new AccountPipe()));
		this.headers.push(new TableColumn('Amount', 'amount', 10, new CurrencyPipe('NL', 'EUR')));
		this.headers.push(new TableColumn('Description', 'description', 50, new TransactionDescPipe()));
	}

	getObjects(projection: TableProjection): void {
		this.filters = projection.filterObj;
		const request = projection.getAsListRequest();
		this.accountTransactionService.getTransactions(this.artist, request).subscribe(
			value => {
				this.objects.next(value);
			}
		);
	}

	onObjectSelect(transaction: ArtistAccountTransaction): void {
		if (transaction.piercing !== undefined && transaction.piercing !== null) {
			this.route.navigate([`/piercing/${transaction.piercing}`]);
		} else if (transaction.appointment !== undefined && transaction.appointment !== null) {
			this.route.navigate([`/tattoo/${transaction.appointment}`]);
		}
	}
}
