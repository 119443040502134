import {CurrencyPipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Giftcard} from '../../model/domain/giftcard';
import {FormatDatePipe} from '../../core/pipe/formatdate.pipe';
import {FormatDateTimePipe} from '../../core/pipe/formatdatetime.pipe';
import {StudioPipe} from '../../core/pipe/studio.pipe';
import {UsernamePipe} from '../../core/pipe/username.pipe';
import {GiftcardService} from '../../core/service/giftcard.service';
import {AbstractTableViewComponent} from '../../component/table-view/abstract-table-view-component';
import {TableColumn} from '../../component/table-view/table-column-model';
import {TableProjection} from '../../component/table-view/table-projection';

@Component({
	selector: 'app-giftcard-list',
	templateUrl: './giftcard-list.component.html',
	styleUrls: ['./giftcard-list.component.scss']
})
export class GiftcardListComponent extends AbstractTableViewComponent<Giftcard> implements OnInit {

	constructor(
		private route: Router,
		private giftcardService: GiftcardService
	) {
		super();
		this.defaultFilters.studio = 'any';
		this.defaultFilters.balance = '0.0';
		this.defaultFilters.expired = 'nonexpired';
	}

	ngOnInit(): void {
		this.headers.push(new TableColumn('Issue date', 'created', 10, new FormatDateTimePipe()));
		this.headers.push(new TableColumn('Sold at studio', 'soldAt', 10, new StudioPipe()));
		this.headers.push(new TableColumn('Sold by', 'soldBy', 10, new UsernamePipe()));
		this.headers.push(new TableColumn('Expiration date', 'expirationDate', 10, new FormatDatePipe()));
		this.headers.push(new TableColumn('Code', 'code', 10));
		this.headers.push(new TableColumn('Balance', 'balance', 10, new CurrencyPipe('NL', 'EUR')));
	}

	getObjects(projection: TableProjection): void {
		this.filters = projection.filterObj;
		const request = projection.getAsListRequest();
		this.giftcardService.getAllGiftcards(request).subscribe(
			value => {
				this.objects.next(value);
			}
		);
	}

	onObjectSelect(giftcard: Giftcard): void {
	}
}
