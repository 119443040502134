import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AccountTransactionListViewComponent} from '../../module/artist/account-transaction-list-view/account-transaction-list-view.component';
import {ArtistListComponent} from '../../module/artist/artist-list/artist-list.component';
import {ArtistNewComponent} from '../../module/artist/artist-new/artist-new.component';
import {ArtistAccountComponent} from '../../module/artist/artist-view/artist-account/artist-account.component';
import {ArtistDocumentsComponent} from '../../module/artist/artist-view/artist-documents/artist-documents.component';
import {ArtistEditComponent} from '../../module/artist/artist-view/artist-edit/artist-edit.component';
import {ArtistInvoiceComponent} from '../../module/artist/artist-view/artist-invoice/artist-invoice.component';
import {ArtistPayoutComponent} from '../../module/artist/artist-view/artist-payout/artist-payout.component';
import {ArtistPiercingsComponent} from '../../module/artist/artist-view/artist-piercings/artist-piercings.component';
import {ArtistReportComponent} from '../../module/artist/artist-view/artist-report/artist-report.component';
import {ArtistStatusComponent} from '../../module/artist/artist-view/artist-status/artist-status.component';
import {ArtistStudiosComponent} from '../../module/artist/artist-view/artist-studios/artist-studios.component';
import {ArtistTattoosComponent} from '../../module/artist/artist-view/artist-tattoos/artist-tattoos.component';
import {ArtistTransactionsComponent} from '../../module/artist/artist-view/artist-transactions/artist-transactions.component';
import {ArtistViewComponent} from '../../module/artist/artist-view/artist-view.component';
import {ArtistResolver} from '../../module/artist/artist-view/artist.resolver';
import {DashboardComponent} from '../../module/dashboard/dashboard/dashboard.component';
import {GiftcardListComponent} from '../../module/giftcard-list/giftcard-list.component';
import {LoginComponent} from '../../module/auth/login/login.component';
import {LogoutComponent} from '../../module/auth/logout/logout.component';
import {MultiArtistPayoutComponent} from '../../module/multi-artist-payout/multi-artist-payout.component';
import {NavigatorComponent} from '../navigator/navigator.component';
import {OrderListComponent} from '../../module/order/order-list/order-list.component';
import {OrderTransactionListComponent} from '../../module/transaction/order-transaction-list/order-transaction-list.component';
import {OrderViewComponent} from '../../module/order/order-view/order-view.component';
import {OrderResolver} from '../../module/order/order-view/order.resolver';
import {OrderNewComponent} from '../../module/order/order-new/order-new.component';
import {PiercingListComponent} from '../../module/piercing/piercing-list/piercing-list.component';
import {PiercingViewComponent} from '../../module/piercing/piercing-view/piercing-view.component';
import {PiercingResolver} from '../../module/piercing/piercing-view/piercing.resolver';
import {PosPiercingListComponent} from '../../module/pos/pos-piercing-list/pos-piercing-list.component';
import {ProductListComponent} from '../../module/pos/product-list/product-list.component';
import {ReportMonthComponent} from '../../module/report/report-month/report-month.component';
import {ReportStudioPerfomanceComponent} from '../../module/report/report-studio-perfomance/report-studio-perfomance.component';
import {StudioDayReviewComponent} from '../../module/report/studio-overview/studio-day-review.component';
import {TattooListComponent} from '../../module/tattoo/tattoo-list/tattoo-list.component';
import {TattooViewComponent} from '../../module/tattoo/tattoo-view/tattoo-view.component';
import {TattooResolver} from '../../module/tattoo/tattoo-view/tattoo.resolver';
import {UserListComponent} from '../../module/user/user-list/user-list.component';
import {UserNewComponent} from '../../module/user/user-new/user-new.component';
import {UserEditComponent} from '../../module/user/user-view/user-edit/user-edit.component';
import {UserOverviewComponent} from '../../module/user/user-view/user-overview/user-overview.component';
import {UserViewComponent} from '../../module/user/user-view/user-view.component';
import {AuthGuard} from './authguard';
import {ReportSalesComponent} from '../../module/report/report-sales/report-sales.component';
import {InventoryDashboardComponent} from '../../module/inventory/inventory-dashboard/inventory-dashboard.component';
import {InventoryTransferComponent} from '../../module/inventory/inventory-transfer/inventory-transfer.component';
import {InventoryTransactionsComponent} from '../../module/inventory/inventory-transactions/inventory-transactions.component';


const routes: Routes = [
	{path: 'login', component: LoginComponent},
	{
		path: '', component: NavigatorComponent, canActivate: [AuthGuard], children: [
			{path: 'logout', component: LogoutComponent},
			{path: '', pathMatch: 'full', redirectTo: 'dashboard'},
			{path: 'dashboard', component: DashboardComponent},
			{path: 'products', component: ProductListComponent},
			{path: 'pospiercings', component: PosPiercingListComponent},
			{path: 'report/month', component: ReportMonthComponent},
			{path: 'report/dayreview', component: StudioDayReviewComponent},
			{path: 'report/dayperformance', component: ReportStudioPerfomanceComponent},
			{path: 'report/productsales', component: ReportSalesComponent},
			{path: 'user', component: UserListComponent},
			{path: 'user/new', component: UserNewComponent},
			{
				path: 'user/:id',
				component: UserViewComponent,
				children: [
					{path: '', pathMatch: 'full', redirectTo: 'overview'},
					{path: 'overview', component: UserOverviewComponent},
					{path: 'edit', component: UserEditComponent}
				]
			},
			{
				path: 'inventory',
				children: [
					{path: '', component: InventoryDashboardComponent},
					{path: 'view', component: InventoryDashboardComponent},
					{path: 'transactions', component: InventoryTransactionsComponent},
					{path: 'transfer', component: InventoryTransferComponent},
					{path: 'view/:id', component: InventoryDashboardComponent}
				]
			},
			{path: 'orders', component: OrderListComponent},
			{path: 'order/new', component: OrderNewComponent},
			{
				path: 'order/:id',
				component: OrderViewComponent,
				resolve: {order: OrderResolver}
			},
			{path: 'piercings', component: PiercingListComponent},
			{
				path: 'piercing/:id',
				component: PiercingViewComponent,
				resolve: {piercing: PiercingResolver}
			},
			{path: 'tattoos', component: TattooListComponent},
			{
				path: 'tattoo/:id',
				component: TattooViewComponent,
				resolve: {tattoo: TattooResolver}
			},
			{path: 'giftcards', component: GiftcardListComponent},
			{path: 'transactions', component: OrderTransactionListComponent},
			{path: 'multipayout', component: MultiArtistPayoutComponent},
			{path: 'artist/list', component: ArtistListComponent},
			{path: 'artist/new', component: ArtistNewComponent},
			{
				path: 'artist/:id',
				component: ArtistViewComponent,
				resolve: {artist: ArtistResolver},
				children: [
					{path: '', pathMatch: 'full', redirectTo: 'status'},
					{path: 'status', component: ArtistStatusComponent},
					{path: 'accounts', component: ArtistAccountComponent},
					{path: 'payout', component: ArtistPayoutComponent},
					{path: 'reports', component: ArtistReportComponent},
					{path: 'invoice', component: ArtistInvoiceComponent},
					{path: 'tattoos', component: ArtistTattoosComponent},
					{path: 'piercings', component: ArtistPiercingsComponent},
					{path: 'studios', component: ArtistStudiosComponent},
					{path: 'documents', component: ArtistDocumentsComponent},
					{path: 'transactions', component: ArtistTransactionsComponent},
					{path: 'edit', component: ArtistEditComponent},
				]
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
