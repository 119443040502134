import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Artist} from '../../model/domain/artist';
import {ArtistPerDayInvoiceModel} from '../../model/report/artist-per-day-invoice-model';
import {ArtistStudioModel} from '../../module/artist/artist-view/model/artist-studio-model';
import {ArtistPerDayModel} from '../../model/report/artist-per-day-model';
import {ArtistReportFilterOptions} from '../../model/report/artist-report-filter-options';
import {FormSubmission} from '../../model/form-submission';
import {Listrequest} from '../../model/listrequest';
import {Paginationmodel} from '../../model/paginationmodel';
import {StudioColor} from '../../model/studioColor';

@Injectable({
	providedIn: 'root'
})
export class ArtistService {
	private url = 'api/artist/';
	private urlAll = this.url + 'all';

	constructor(
		private http: HttpClient
	) {}

	getAll(): Observable<Artist[]> {
		return this.http.get<Artist[]>(this.urlAll);
	}

	getAllTattooArtists(): Observable<Artist[]> {
		return this.http.get<Artist[]>(`${this.url}alltattoo`);
	}

	getAllPiercingArtists(): Observable<Artist[]> {
		return this.http.get<Artist[]>(`${this.url}allpiercing`);
	}

	getAllArtists(request: Listrequest): Observable<Paginationmodel> {
		return this.http.post<Paginationmodel>(`${this.url}list`, request);
	}

	getById(id: number): Observable<Artist> {
		return this.http.get<Artist>(this.url + id + '/');
	}

	getStudios(id: number): Observable<ArtistStudioModel[]> {
		return this.http.get<ArtistStudioModel[]>(`${this.url}${id}/studios`);
	}

	updateArtist(artist: Artist): Observable<FormSubmission> {
		return this.http.post<FormSubmission>(`${this.url}update`, artist);
	}

	newArtist(artist: Artist): Observable<FormSubmission> {
		return this.http.post<FormSubmission>(`${this.url}new`, artist);
	}

	disableArtist(artist: Artist): Observable<Artist> {
		return this.http.post<Artist>(`${this.url}${artist.id}/disable`, artist);
	}

	enableArtist(artist: Artist): Observable<Artist> {
		return this.http.post<Artist>(`${this.url}${artist.id}/enable`, artist);
	}

	isValid(artist: Artist): Observable<string[]> {
		return this.http.get<string[]>(`${this.url}${artist.id}/valid`);
	}

	getReportOptions(id: number): Observable<ArtistReportFilterOptions> {
		return this.http.get<ArtistReportFilterOptions>(`${this.url}${id}/report/options`);
	}

	getEarningReport(id: number, month: number): Observable<ArtistPerDayModel> {
		let params = new HttpParams();
		params = params.set('month', String(month));
		return this.http.get<ArtistPerDayModel>(`${this.url}${id}/report/month`, {params});
	}

	getInvoiceReport(id: number, month: number): Observable<ArtistPerDayInvoiceModel> {
		let params = new HttpParams();
		params = params.set('month', String(month));
		return this.http.get<ArtistPerDayInvoiceModel>(`${this.url}${id}/invoice/month`, {params});
	}

	getAvailableStudiosForArtist(artist: Artist): Observable<StudioColor[]> {
		return this.http.get<StudioColor[]>(`${this.url}${artist.id}/availablestudios`);
	}

	removeStudioFromArtist(artist: Artist, studio: number): Observable<Artist> {
		return this.http.get<Artist>(`${this.url}${artist.id}/removefromstudio/${studio}`);
	}

	addStudioToArtist(artist: Artist, studio: number, color: string): Observable<Artist> {
		const model = {
			studio,
			color
		};

		return this.http.post<Artist>(`${this.url}${artist.id}/addtostudio`, model);
	}
}
