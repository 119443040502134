import {Component, OnInit} from '@angular/core';
import {NgbCalendar, NgbDate, NgbDateParserFormatter, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {MultiPayoutService} from '../../core/service/multi-payout.service';
import {DateUtil} from '../../core/util/date.util';
import {MultiPayoutModel} from './multi-payout.model';

@Component({
	selector: 'app-multi-artist-payout',
	templateUrl: './multi-artist-payout.component.html',
	styleUrls: ['./multi-artist-payout.component.scss']
})
export class MultiArtistPayoutComponent implements OnInit {
	payout: MultiPayoutModel;
	result = false;

	hoveredDate: NgbDate | null = null;
	start: NgbDate;
	end: NgbDate;

	loading = true;
	saving: boolean;

	constructor(
		private multiPayoutService: MultiPayoutService,
		private calendar: NgbCalendar,
		public formatter: NgbDateParserFormatter
	) { }

	ngOnInit(): void {
		this.multiPayoutService.getPayoutModel(0, 0).subscribe(model => {
			this.loading = false;
			this.payout = model;
			this.start = DateUtil.getNgbDateFromMoment(moment(model.startDate));
			this.end = DateUtil.getNgbDateFromMoment(moment(model.endDate));
		});
	}


	onDateSelection(date: NgbDate, picker: NgbInputDatepicker): void {
		if (!this.start && !this.end) {
			this.start = date;
		} else if (this.start && !this.end && date && date.after(this.start)) {
			this.end = date;
			this.getPayoutModel();
			picker.toggle();
		} else {
			this.end = null;
			this.start = date;
		}
	}

	canSave(): boolean {
		if (this.loading || this.saving) {
			return false;
		}
		if (this.payout && this.payout.description && this.payout.description.length > 2) {
			for (let i = 0; i < this.payout.artists.length; i++) {
				if (this.payout.artists[i].enabled) {
					return true;
				}
			}
		}
		return false;
	}

	save(): void {
		this.saving = true;
		this.multiPayoutService.processPayoutModel(this.payout).subscribe(value => {
			if (value === null) {
				this.result = true;
			}
		});
	}


	private getPayoutModel(): void {
		this.multiPayoutService.getPayoutModel(
			DateUtil.getMomentFromNgbDate(this.start).valueOf(),
			DateUtil.getMomentFromNgbDate(this.end).valueOf()
		).subscribe(
			model => {
				this.payout = model;
				this.start = DateUtil.getNgbDateFromMoment(moment(model.startDate));
				this.end = DateUtil.getNgbDateFromMoment(moment(model.endDate));
			}
		);
	}

	isHovered(date: NgbDate): boolean {
		return this.start && !this.end && this.hoveredDate && date.after(this.start) && date.before(this.hoveredDate);
	}

	isInside(date: NgbDate): boolean {
		return this.end && date.after(this.start) && date.before(this.end);
	}

	isRange(date: NgbDate): boolean {
		return date.equals(this.start) || (this.end && date.equals(this.end)) || this.isInside(date) || this.isHovered(date);
	}

	validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input);
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
	}
}
