import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';


@Pipe({
	name: 'formatdate'
})
export class FormatDatePipe implements PipeTransform {
	constructor() {}

	transform(value: any, format?: string): any {
		if (format !== undefined) {
			return moment(value).format(format);
		}
		return moment(value).format('DD/MM/YYYY');
	}
}
